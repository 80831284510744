import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import { CSVLink } from "react-csv";
import csvtojson from "csvtojson";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
//import { Toast } from "primereact/toast";

// react icons
import { BsArrowRightCircle } from 'react-icons/bs'
import { MdOutlineCancel } from 'react-icons/md';
import { HiPlusSm } from 'react-icons/hi';

// react bootstrap
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { Button as PButton } from "primereact/button";

// React Autocomplete
import { AutoComplete } from 'primereact/autocomplete';

// components
import DateTimePicker from "react-datetime-picker";
import { BsCardText } from "react-icons/bs";
import styles from "./Form.module.scss";
import DeleteModal from "../shared/DeleteModal"
import Preview from "./scouting-overview/Preview";

// firebase
import { db, storage } from "../../firebase";
//import { StatsTable } from "./report/statsTable.form";
//import log_activity from "../activity/logger";
import { useAuth } from "../../Context/AuthContext";

// forms
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
//  toast
import { toast } from "react-toastify";

// header
import Header from "../header/header";
import log_activity from "../activity/logger";

export default function ScoutingOverviewForm({ formData, scoutingFormID, closeEditModal }) {

  const [loading, setLoading] = useState(false);
  const [playerProfiles, setPlayerProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState(null);
  const playerProfileNames = useRef([]);
  const [rolesDropdownData, setRolesDropdownData] = useState(null);
  // const [roleCount, setRoleCount] = useState(1);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [collegeLevels, setCollegeLevels] = useState([]);
  const [collegeCeilings, setCollegeCeilings] = useState([]);
  const [nbaDrafts, setNbaDrafts] = useState([]);
  const [nbaCeilings, setNbaCeilings] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [csvReport, setCsvReport] = useState(null);
  const { isSuperAdmin } = useAuth();
  const selectCSV = useRef(null);
  //const toast = useRef(null);

  const { userInfo } = useAuth();
  

  const Add = (index, fieldName) => {
    if (formik.values[fieldName].length === 8) return;
    formik.setFieldValue(fieldName,
      [
        ...formik.values[fieldName].slice(0, index + 1),
        "",
        ...formik.values[fieldName].slice(index + 1),
      ]
    );
  }
  const ConfirmDelete = (index, fieldName) => {
    formik.setFieldValue(fieldName, [
      ...formik.values[fieldName].filter((_, ind) => ind !== index),
    ])
  }
  const Delete = (index, fieldName) => {
    if (formik.values[fieldName].length === 1) return;
    setDeleteConfirm(true);
    setDeleteIndex({ index: index, fieldName: fieldName });
  }
  const getExportScoutingOverviewData = async () => {
    try {
      const response = await db.collection("scouting-overview").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      console.log(data, "scouting-overview data");
      const csvReport = {
        data: data,
        headers: [
          { label: "Player Profile", key: "title" },
          { label: "Player Profile ID", key: "playerProfile" },
          { label: "NBA Celing", key: "nbaCeiling" },
          { label: "NBA Draft", key: "nbaDraft" },
          { label: "College Level", key: "collegeLevel" },
          { label: "College Ceiling", key: "collegeCeiling" },
          { label: "questions1", key: "questions[0]" },
          { label: "questions2", key: "questions[1]" },
          { label: "questions3", key: "questions[2]" },
          { label: "questions4", key: "questions[3]" },
          { label: "questions5", key: "questions[4]" },
          { label: "questions6", key: "questions[5]" },
          { label: "roles1", key: "roles[0]" },
          { label: "roles2", key: "roles[1]" },
          { label: "roles3", key: "roles[2]" },
          { label: "roles4", key: "roles[3]" },
          { label: "roles5", key: "roles[4]" },
          { label: "roles6", key: "roles[5]" },
          { label: "roles7", key: "roles[6]" },
          { label: "roles8", key: "roles[7]" },
          { label: "roles9", key: "roles[8]" },
          { label: "roles10", key: "roles[9]" },
          { label: "strengths1", key: "strengths[0]"},
          { label: "strengths2", key: "strengths[1]"},
          { label: "strengths3", key: "strengths[2]"},
          { label: "strengths4", key: "strengths[3]"},
          { label: "strengths5", key: "strengths[4]"},
          { label: "strengths6", key: "strengths[5]"},
          { label: "strengths7", key: "strengths[6]"},
          { label: "strengths8", key: "strengths[7]"},
          { label: "strengths9", key: "strengths[8]"},
          { label: "strengths10", key: "strengths[9]"},
          { label: "weaknesses1", key: "weaknesses[0]" },
          { label: "weaknesses2", key: "weaknesses[1]" },
          { label: "weaknesses3", key: "weaknesses[2]" },
          { label: "weaknesses4", key: "weaknesses[3]" },
          { label: "weaknesses5", key: "weaknesses[4]" },
          { label: "weaknesses6", key: "weaknesses[5]" },
          { label: "weaknesses7", key: "weaknesses[6]" },
          { label: "weaknesses8", key: "weaknesses[7]" },
          { label: "weaknesses9", key: "weaknesses[8]" },
          { label: "weaknesses10", key: "weaknesses[9]" },
          { label: "Bottom Line", key: "bottomLine" },
          
        ],
        filename: `scouting-overview-${new Date().toDateString()}-${new Date().toTimeString()}.csv`,
      };

      setCsvReport(csvReport);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getExportScoutingOverviewData();
    db.collection("player-profile").get().then((allDocs) => {
      const allPlayerProfiles = [];
      allDocs.forEach(doc => {
        allPlayerProfiles.push({ fullName: `${doc.data().FirstName} ${doc.data().LastName}`, id: doc.id, dob: doc.data().DOB });
        playerProfileNames.current.push(`${doc.data().FirstName} ${doc.data().LastName}`);
      });
      setPlayerProfiles(allPlayerProfiles);
      getRolesData();
      getCollegeLevels();
      getCollegeCeiling();
      getNbaDraft();
      getNbaCeiling();
    });
  }, [])

  const getCollegeLevels = async () => {
    try {
      const response = await db.collection("college-level-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => a.order - b.order);
      setCollegeLevels(data);
    } catch (error) {
      console.log(error, "error");
    }
  }

  const getCollegeCeiling = async () => {
    try {
      const response = await db.collection("college-ceiling-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => a.order - b.order);
      setCollegeCeilings(data);
    } catch (error) {
      console.log(error, "error");
    }
  }

  const getNbaDraft = async () => {
    try {
      const response = await db.collection("nba-draft-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => a.order - b.order);
      setNbaDrafts(data);
    } catch (error) {
      console.log(error, "error");
    }
  }



  const getNbaCeiling = async () => {
    try {
      const response = await db.collection("nba-ceiling-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => a.order - b.order);
      setNbaCeilings(data);
    } catch (error) {
      console.log(error, "error");
    }
  }




  const getRolesData = async () => {
    try {
      const response = await db.collection("roles-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      const sortedRolesData = data.sort((a, b) => a.name.localeCompare(b.name));

      setRolesDropdownData(sortedRolesData);
    } catch (error) {
      console.log(error, "error");
    }
  };
  const UpdateDoc = (values, resetForm) => {
    db.collection("scouting-overview").doc(update)
      .update({
        ...values,
        playerProfile: values.playerProfile.id
      })
      .then((docRef) => {
        console.log("Document successfully written!");
        if (formData) closeEditModal();
        resetForm({});
        setUpdate(false);
        toast.info("Scouting Overview Updated Successfully", {
          theme: "colored",
        });
        log_activity(
        values.playerProfile.fullName, 
        "Scouting Overview Was Edited",
        userInfo.firstName + " " + userInfo.lastName)
      })
      .catch((error) => {
        toast.error("Error Updating Scouting Overview", {
          theme: "colored",
        });
        console.error("Error writing document: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const CreateDoc = (values, resetForm) => {

    db.collection("scouting-overview")
      .add({
        ...values,
        playerProfile: values.playerProfile.id
      })
      .then((docRef) => {
        console.log("Document successfully written!");
        resetForm({});
        // setRoleCount(1);
        toast.info("Scouting Overview Created Successfully", {
          theme: "colored",
        });
        
        log_activity(
          values.playerProfile.fullName,
          "Scouting Overview Added",
          userInfo.firstName + " " + userInfo.lastName,
          
        );
        
      })
      .catch((error) => {
        toast.error("Error Creating Scouting Overview", {
          theme: "colored",
        });
        console.error("Error writing document: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const formik = useFormik({
    initialValues: {
      playerProfile: "",
      collegeLevel: "",
      collegeCeiling: "",
      nbaDraft: "",
      nbaCeiling: "",
      roles: [""],
      strengths: [""],
      weaknesses: [""],
      bottomLine: "",
      questions: [""],
      drafted: false,
      createdAt: moment().unix(),
    },
    validationSchema: validationSchema(playerProfileNames),
    onSubmit: (values, { resetForm }) => {
      let _values = removeEmptyFields(values);
      // console.log(values, "values");
      // return;
      setLoading(true);
      update ? UpdateDoc(_values, resetForm) : CreateDoc(_values, resetForm);
    },
  });

  const removeEmptyFields = (values) => {
    let _values = cloneDeep(values)
    _values.roles = _values.roles.filter(r => r != undefined && r !== "")
    _values.strengths = _values.strengths.filter(s => s != undefined && s !== "")
    _values.weaknesses = _values.weaknesses.filter(w => w != undefined && w !== "")
    _values.questions = _values.questions.filter(q => q != undefined && q !== "")
    return _values;
  }
  useEffect(() => {
    formik.values.roles.length === 0 && formik.setFieldValue("roles", [""]);
    formik.values.strengths.length === 0 && formik.setFieldValue("strengths", [""]);
    formik.values.weaknesses.length === 0 && formik.setFieldValue("weaknesses", [""]);
    formik.values.questions.length === 0 && formik.setFieldValue("questions", [""]);
  }, [formik.values.roles.length, formik.values.strengths.length, formik.values.weaknesses.length, formik.values.questions.length])
  
  const searchProfile = (event) => {
    let _filteredProfiles = [...playerProfiles];
    if (event.query.trim().length > 0) {
      _filteredProfiles = _filteredProfiles.filter((p) => {
        return p.fullName.toLowerCase().includes(event.query.toLowerCase());
      });
    }
    setFilteredProfiles(_filteredProfiles);
  }
  const itemTemplate = (item) => {
    return (
      <div>
        <b>{item.fullName}</b><br />
        <code>DOB: {moment(item.dob).format('MM/DD/YYYY')}</code>
      </div>
    );
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const csv = e.target.result;
        const jsonArray = await csvtojson().fromString(csv);
        console.log(jsonArray, "jsonArray");
        
        confirm1(jsonArray);
      };
      reader.readAsText(file);
    }
  };
  const confirm1 = (payload) => {
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      // handle acccept
      accept: () => {
        handleAcceptFileUpload(payload);
      },

      reject,
    });
  };
  const confirm2 = () => {
    console.log("Confirmation dialog opened");
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        handleDeleteAllData();
      },
      reject,
    });
  };
  const reject = () => {
    toast.current && toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const handleDeleteAllData = async () => {
    console.log("try to delete");
    let response = await db.collection("scouting-overview").get();
    console.log(response.docs);
    let data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    let response2 = data.map(async (item) => {
      let response = await db.collection("scouting-overview").doc(item.id).delete();
      return response;
    });
    response2 = await Promise.all(response2);
    if (response2.length > 0) {
      toast.current && toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Reports Deleted Successfully",
        life: 3000,
      });
      // getPlayerProfiles();
      getExportScoutingOverviewData();
    } else {
      toast.current && toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong",
        life: 3000,
      });
    }
  };
  const handleAcceptFileUpload = async (payload) => {
    // upload all the data Array multiple objects to firebase
    let response = payload.map(async (item) => {
      let scoutingRef = db.collection("scouting-overview").doc();

      let scoutingOBJ = {
        title: item.title,
        playerProfile: item.playerProfile,
        collegeLevel: item.collegeLevel,
        collegeCeiling: item.collegeCeiling,
        nbaCeiling: item.nbaCeiling,
        nbaDraft: item.nbaDraft,
        lastEdit: item.lastEdit,
        roles: [
          item.role1,
          item.role2,
          item.role3,
          item.role4,
          item.role5,
          item.role6,
          item.role7,
          item.role8,
        ].filter((r) => !!r), // Filter out any falsy values (empty strings) from the array
        strengths: [
          item.strength1,
          item.strength2,
          item.strength3,
          item.strength4,
          item.strength5,
          item.strength6,
        ].filter((s) => !!s),
        weaknesses: [
          item.weakness1,
          item.weakness2,
          item.weakness3,
          item.weakness4,
          item.weakness5,
          item.weakness6,
        ].filter((w) => !!w),
        questions: [
          item.question1,
          item.question2,
          item.question3,
          item.question4,
          item.question5,
          item.question6,
        ].filter((q) => !!q),
        bottomLine: item.bottomline,
      };
      let response = await scoutingRef.set(scoutingOBJ);
      return response;
    });

    response = await Promise.all(response);

    if (response.length > 0) {
      toast.current && toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Scouting Overviews Added Successfully",
        life: 3000,
        
      });
      console.log("File import successful!");
      // getPlayerProfiles();
      getExportScoutingOverviewData();
    } else {
      toast.current && toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong",
        life: 3000,
      });
    }
  };

  // for editing on preview page
  useEffect(() => {
    if (formData) {
      formik.setValues({ ...formData, playerProfile: playerProfiles.find((p) => p.id === formData.playerProfile) });
      setUpdate(scoutingFormID);
    }
  }, [playerProfiles])

  return (
    <div>
      
      <ConfirmDialog />
      <DeleteModal
        show={deleteConfirm}
        handleConfirm={() => { ConfirmDelete(deleteIndex.index, deleteIndex.fieldName) }}
        handleClose={() => setDeleteConfirm(false)}
      />
      {previewOpen && <Preview open={previewOpen} setOpen={setPreviewOpen} scoutingOverviewData={removeEmptyFields({ ...formik.values })} />}

      {(formData === null || formData === undefined) && <Header />}
      {/* ------------- SCOUTING OVERVIEW FORM BEGINS -------------------*/}

      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Scouting Overview</h4>
            <subtitle>
              This section is used to create a scouting overview. Please fill as many sections as possible.{" "}
            </subtitle>
            {isSuperAdmin() && (
            <div>
              {/* <Button
              label="Update Age from DOB"
              onClick={handleUpdateAgefromDOB}
            ></Button> */}
              <input
                type="file"
                ref={selectCSV}
                // only accept .csv files
                accept=".csv"
                style={{
                  display: "none",
                }}
                onChange={handleFileUpload}
              />
              <PButton
                onClick={() => selectCSV.current.click()}
                icon="pi pi-check"
                label="Import"
                severity="success"
                // style={{
                //   backgroundColor: "green",
                //   borderColor: "green",
                // }}
                size="small"
              ></PButton>
              {csvReport && (
                <CSVLink {...csvReport}>
                  <PButton
                    size="small"
                    icon="pi pi-times"
                    label="Export"
                    severity="danger"
                    style={{
                      marginLeft: "10px",
                    }}
                  ></PButton>
                </CSVLink>
              )}{" "}
              <PButton
                onClick={confirm2}
                icon="pi pi-times"
                label="Delete"
                severity="danger"
                style={{
                  marginLeft: "10px",
                  backgroundColor: "red",
                  borderColor: "red",
                }}
                size="small"
              ></PButton>
            </div>
          )}
          </div>
        </div>
        <Row>
          <Col md={6}>
            <div className={styles.field}>
              <p>Player Profile</p>
              <AutoComplete
                disabled={formData !== undefined && formData !== null}
                className={styles.fullWidth}
                value={formik.values.playerProfile}
                suggestions={filteredProfiles}
                completeMethod={searchProfile}
                field="fullName"
                itemTemplate={itemTemplate}
                name="playerProfile"
                dropdown
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onSelect={(e) => {
                  db.collection("scouting-overview")
                    .where("playerProfile", "==", e.value.id).get()
                    .then(querySnap => {
                      if (querySnap.docs.length === 0) {
                        setUpdate(false);
                        formik.setValues({ ...formik.initialValues, playerProfile: e.value })
                        return;
                      };
                      // setRoleCount(querySnap.docs[0].data().roles.length)
                      formik.setValues({ ...querySnap.docs[0].data(), playerProfile: e.value });
                      setUpdate(querySnap.docs[0].id);
                    })
                }}
              />

              {formik.errors.playerProfile
                && formik.touched.playerProfile
                ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.playerProfile + " "}
                    {formik.errors.playerProfile === "This player profile doesn't exist" &&
                      <Link className={styles.createProfLink} to="/player-profile/create">
                        Create Player Profile <BsArrowRightCircle />
                      </Link>}
                  </div>
                ) : null}
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Outlook</h4>
            <subtitle>
              This section is used to note player projects. Please fill as many sections as possible.
            </subtitle>
          </div>
        </div>
        <Row>
          <Col md={6}>
            <div className={styles.field}>
              <p>College Level</p>
              <select className={styles.dropdownStyle} name="collegeLevel" value={formik.values.collegeLevel} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                <option value="" >Select College Level</option>
                {
                  collegeLevels.map((c, ind) =>
                    <option key={ind} value={c.value}>
                      {c.name}
                    </option>)
                }
              </select>
              {
                formik.errors.collegeLevel && formik.touched.collegeLevel &&
                <div className={styles.errorMessage} >{formik.errors.collegeLevel}</div>
              }
            </div>
          </Col>

          <Col md={6}>
          <div className={styles.field}>
              <p>College Ceiling</p>
              <select className={styles.dropdownStyle} name="collegeCeiling" value={formik.values.collegeCeiling} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                <option value="" >Select College Ceiling</option>
                {
                  collegeCeilings.map((c, ind) =>
                    <option key={ind} value={c.value}>
                      {c.name}
                    </option>)
                }
              </select>
              {
                formik.errors.collegeCeiling && formik.touched.collegeCeiling &&
                <div className={styles.errorMessage} >{formik.errors.collegeCeiling}</div>
              }
            </div>
          </Col>
        </Row>

        <Row> 
          <Col md={6}>
            

            <div className={styles.field}>
              <p>NBA Draft</p>
              <select className={styles.dropdownStyle} name="nbaDraft" value={formik.values.nbaDraft} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                <option value="" >Select NBA Draft</option>
                {
                  nbaDrafts.map((c, ind) =>
                    <option key={ind} value={c.value}>
                      {c.name}
                    </option>)
                }
              </select>
              {
                formik.errors.nbaCeiling && formik.touched.nbaCeiling &&
                <div className={styles.errorMessage} >{formik.errors.nbaCeiling}</div>
              }
            </div>


          </Col>

          <Col md={6}>
            <div className={styles.field}>
              <p>NBA Ceiling</p>
              <select className={styles.dropdownStyle} name="nbaCeiling" value={formik.values.nbaCeiling} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                <option value="" >Select NBA Ceiling</option>
                {
                  nbaCeilings.map((c, ind) =>
                    <option key={ind} value={c.value}>
                      {c.name}
                    </option>)
                }
              </select>
              {
                formik.errors.nbaCeiling && formik.touched.nbaCeiling &&
                <div className={styles.errorMessage} >{formik.errors.nbaCeiling}</div>
              }
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Roles</h4>
            <subtitle>
              This section is used to note player projects. Select up to 8.
            </subtitle>
          </div>
        </div>
        <div className={styles.field}>
          <p>Roles</p>
          <div className={styles.grayContainer}>
            {/* {formik.values.roles && getRolesDropdownsComp()} */}
            {formik.values.roles.length > 0 &&
              <>
                {
                  formik.values.roles.map((role, i) =>
                    <select
                      key={i}
                      className={styles.custDropdownStyle}
                      onChange={formik.handleChange}
                      value={formik.values.roles[i]}
                      name={`roles[${i}]`}
                      onBlur={formik.onBlur}
                    >
                      <option value={""}>Role {i + 1}</option>
                      {rolesDropdownData?.map(({ id, name, value }) =>
                        <option key={id} value={value}>{name}</option>
                      )}
                    </select>
                  )
                }
              </>

            }
            {/* <button onClick={() => setRoleCount((prevCount) => prevCount < 8 ? prevCount + 1 : prevCount)} className={styles.roleBtn} style={{ width: "auto" }}><b>+</b></button> */}
            <button onClick={() => formik.setFieldValue("roles", formik.values.roles.length < 8 ? [...formik.values.roles, ""] : [...formik.values.roles])} className={styles.roleBtn} style={{ width: "auto" }}><b>+</b></button>
          </div>
          {formik.errors.roles && formik.touched.roles &&
            <div className={styles.errorMessage}>
              {formik.errors.roles}
            </div>
          }
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Strengths</h4>
            <subtitle>This section is used to add player strengths. 8.</subtitle>
          </div>
        </div>
        <div className={styles.field}>
          <p>Note(s)</p>
          {
            formik.values.strengths.length > 0 &&
            formik.values.strengths.map((s, index) =>
              <div className={styles.btns} key={index}>
                <input type="text" name={`strengths[${index}]`} value={s} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <button className={`${styles.green} ${styles.small}`} onClick={() => { Add(index, "strengths") }} ><HiPlusSm /></button>
                <button className={`${styles.red} ${styles.small}`} onClick={() => { Delete(index, "strengths") }} ><MdOutlineCancel /></button>
              </div>
            )
          }
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Weaknesses</h4>
            <subtitle>This section is used to add player weaknesses. Add up to 8.</subtitle>
          </div>
        </div>
        <div className={styles.field}>
          <p>Note(s)</p>
          {
            formik.values.weaknesses.length > 0 &&
            formik.values.weaknesses.map((s, index) =>
              <div className={styles.btns} key={index}>
                <input type="text" name={`weaknesses[${index}]`} value={s} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <button className={`${styles.green} ${styles.small}`} onClick={() => { Add(index, "weaknesses") }} ><HiPlusSm /></button>
                <button className={`${styles.red} ${styles.small}`} onClick={() => { Delete(index, "weaknesses") }} ><MdOutlineCancel /></button>
              </div>
            )
          }
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Bottom Line</h4>
            <subtitle>
              This section is used to add any bottom line notes.
            </subtitle>
          </div>
        </div>
        <div className={styles.field}>
          <p>Note(s)</p>
          <textarea name="bottomLine" rows={6} value={formik.values.bottomLine} onChange={formik.handleChange} onBlur={formik.handleBlur} ></textarea>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Questions</h4>
            <subtitle>This section is used to add questions. Add up to 8.</subtitle>
          </div>
        </div>
        <div className={styles.field}>
          <p>Question(s)</p>
          {
            formik.values.questions.length > 0 &&
            formik.values.questions.map((q, index) =>
              <div className={styles.btns} key={index}  >
                <input type="text" name={`questions[${index}]`} value={q} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                <button className={`${styles.green} ${styles.small}`} onClick={() => { Add(index, "questions") }} ><HiPlusSm /></button>
                <button className={`${styles.red} ${styles.small}`} onClick={() => { Delete(index, "questions") }} ><MdOutlineCancel /></button>
              </div>
            )
          }
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Row>
          {/*
          <Col md={4}>
            <Button className={styles.secondary} onClick={() => setPreviewOpen(true)} >Preview</Button>
          </Col>
          <Col md={4}>
            <Button
              disabled={loading}
              className={styles.secondary}
              onClick={() => {
                formik.setFieldValue("drafted", true);
                formik.handleSubmit();
              }}
            >
              {update ? "Update Draft" : "Save Draft"}
            </Button>
          </Col>
          */}
          <Col md={4}>
            <Button
              disabled={loading}
              type="submit"
              onClick={() => {
                formik.setFieldValue("drafted", false);
                formik.handleSubmit();
              }}
            >
              {loading ? <Spinner animation="border" /> : update ? "Update" : "Publish"}
            </Button>

          </Col>
        </Row>
      </div>
    </div>
  );
}


const validationSchema = (allPlayerProfileNames) => {
  return Yup.object({}).shape({
    playerProfile: Yup.lazy((value) => {
      if (value === undefined) {
        return Yup.string().required("Required");
      }
      else if (value.constructor === String) {
        return Yup.string().required("Required").oneOf(allPlayerProfileNames.current, "This player profile doesn't exist")
          .test(
            "ExistingProfile",
            `Profile/s with this name exists. Please Select`,
            async (value2) => await allPlayerProfileNames.current.filter(p => p.fullName === value2).length > 0);
      }
      else {
        return Yup.object().required("Required");
      }
    }),

  })
}