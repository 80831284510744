import React from "react";
import Header from "../header/header";

// react bootstrap
import { Row, Col, Button } from "react-bootstrap";

// styles
import styles from "./stats.module.scss";

// components
import { Dropzone } from "../shared/Dropzone";

export const ImportPage = () => {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Import Stats</h4>
            <subtitle>
              This section is used to import stats in CSV format
            </subtitle>
          </div>
        </div>

        <Row>
          <Col md={12}>
            <div className={styles.field}>
              <p>Select CSV file</p>
              <Dropzone />
            </div>
          </Col>
          <Col md={12}>
            <div className={styles.buttonContainer}>
              <Row>
                <Col md={5}>
                  <Button className={styles.secondary}>Cancel</Button>
                </Col>
                <Col md={5}>
                  <Button>Upload</Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
