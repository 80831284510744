import Layout from "../../../components/layout/layout.js";
import { PlayerPreviewComponents } from "../../../components/PlayerPreview/index.js";

function PlayerPreview({ component, ...rest }) {
  return (
    <Layout
      Component={PlayerPreviewComponents}
      controlsDisabled={true}
      {...rest}
    />
  );
}

export default PlayerPreview;
