import React, { useEffect } from 'react'

import { Accordion, Modal, Button } from 'react-bootstrap'
import styles from '../../PlayerPreview/preview.module.scss'

import {
    BodyTitle,
    BodyColList,
    BodyList,
} from "../../PlayerPreview/sections";

export default function Preview({ open, setOpen, scoutingOverviewData }) {
    function handleClose() {
        setOpen(false)
    }

    const scoutingOverviewDetails = getScoutingOverviewDetails(scoutingOverviewData);
    return (
        <Modal show={open}
            onHide={handleClose}
            size="xl"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Scouting Overview</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Accordion defaultActiveKey="0" className={styles.accordionSection}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>SCOUTING OVERVIEW</Accordion.Header>
                        <Accordion.Body>
                            {(scoutingOverviewDetails.projections.Projections.list.length > 0 || scoutingOverviewDetails.roles.Roles.list.length > 0)
                                &&
                                <>
                                    <BodyTitle title="Outlook" />
                                    {scoutingOverviewDetails.projections.Projections.list.length > 0 &&
                                        <BodyList data={scoutingOverviewDetails.projections} />

                                    }
                                    {scoutingOverviewDetails.roles.Roles.list.length > 0 &&
                                        <BodyColList data={scoutingOverviewDetails.roles} cols={3} />
                                    }
                                </>
                            }


                            {scoutingOverviewDetails.strengths.Strengths.list.length > 0 &&
                                <>
                                    <BodyTitle title="Strengths" />
                                    <BodyList data={scoutingOverviewDetails.strengths} />
                                </>
                            }
                            {scoutingOverviewDetails.weaknesses.Weaknesses.list.length > 0 &&
                                <>
                                    <BodyTitle title="Weaknesses" />
                                    <BodyList data={scoutingOverviewDetails.weaknesses} />
                                </>
                            }
                            {scoutingOverviewDetails.bottomLine.length > 0 &&
                                <>
                                    <BodyTitle title="Bottom Line" />
                                    <p>
                                        {scoutingOverviewDetails.bottomLine}
                                    </p>
                                </>
                            }
                            {scoutingOverviewDetails.questions.Questions.list.length > 0 &&
                                <>
                                    <BodyTitle title="Questions" />
                                    <BodyList data={scoutingOverviewDetails.questions} />
                                </>
                            }
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} >Close</Button>
            </Modal.Footer>
        </Modal>
    )
}


const getScoutingOverviewDetails = (scoutingOverviewData) => {
    let data =
    {
        strengths: {
            Strengths: {
                list: scoutingOverviewData.strengths,
            },
        },
        projections: {
            Projections: {
                title: "Projections",
                list: [],
            },
        },
        weaknesses: {
            Weaknesses: {
                list: scoutingOverviewData.weaknesses,
            },
        },
        questions: {
            Questions: {
                list: scoutingOverviewData.questions,
            },
        },
        roles: {
            Roles: {
                title: "Roles",
                list: scoutingOverviewData.roles,
            }
        },
        bottomLine: scoutingOverviewData.bottomLine,
    }
    scoutingOverviewData.collegeLevel !== "" && data.projections.Projections.list.push(`College Level: ${scoutingOverviewData.collegeLevel}`)
    scoutingOverviewData.collegeCeiling !== "" && data.projections.Projections.list.push(`College Ceiling: ${scoutingOverviewData.collegeCeiling}`)
    scoutingOverviewData.nbaDraft !== "" && data.projections.Projections.list.push(`NBA Draft: ${scoutingOverviewData.nbaDraft}`)
    scoutingOverviewData.nbaCeiling !== "" && data.projections.Projections.list.push(`NBA Ceiling: ${scoutingOverviewData.nbaCeiling}`)

    return data;
}