import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";


import { FileDropzone } from '../../form/report/fileDropzone'
import RichEditor from "../../article/rich-editor";

import { BsArrowRightCircle } from "react-icons/bs";
import { AutoComplete } from "primereact/autocomplete";

import '../../form/index.css'

import { Row, Col, Button, Spinner, Modal } from "react-bootstrap";
//import { Button as PButton } from "primereact/button";

import styles from "../../form/Form.module.scss";

import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";

import { toast } from "react-toastify";
import { db, storage } from "../../../firebase";
import { StatsTable } from "../../form/report/statsTable.form";
import log_activity from "../../activity/logger";
import { useAuth } from "../../../Context/AuthContext";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

export const CreateReportModal = ({ show, handleClose, item, docID, getPlayerReports }) => {
  const [loading, setLoading] = useState(false);
  const [playerProfiles, setPlayerProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState(null);
  const [clearFiles, setClearFiles] = useState(false);
  const [roleCount, setRoleCount] = useState(1);
  const options = ["General", "Medical", "Intel", "Scouting"];
  const [rolesDropdownData, setRolesDropdownData] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [clearQuillBoard, setClearQuillBoard] = useState(false);
  const initialSubFormValues = { urlReference: "", source: "" };
  const { isSuperAdmin } = useAuth();
  // pr low and high dropdown data
  const [prLowData, setPrLowData] = useState([]);
  const [prHighData, setPrHighData] = useState([]);
  //const toast = useRef(null);
  const playerProfileNames = useRef([]);

  const [modalOpen, setModalOpen] = useState(false);
  const { userInfo } = useAuth();

  

  useEffect(() => {
    //getExportReportsData();
    db.collection("player-profile").get().then((allDocs) => {
      const allPlayerProfiles = [];
      allDocs.forEach((doc) => {
        allPlayerProfiles.push({
          fullName: `${doc.data().FirstName} ${doc.data().LastName}`,
          id: doc.id,
          dob: doc.data().DOB,
        });
        playerProfileNames.current.push(
          `${doc.data().FirstName} ${doc.data().LastName}`
        );
      });
      setPlayerProfiles(allPlayerProfiles);
      getRolesData();

      getPrLowData();
      getPrHighData();
    });
  }, []);

  const getRolesData = async () => {
    try {
      const response = await db.collection("roles-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      const sortedRolesData = data.sort((a, b) => a.name.localeCompare(b.name));

      setRolesDropdownData(sortedRolesData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getPrHighData = async () => {
    try {
      const response = await db.collection("hight-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => b.order - a.order);
      setPrHighData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getPrLowData = async () => {
    try {
      const response = await db.collection("low-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => b.order - a.order);
      setPrLowData(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const searchProfile = (event) => {
    let _filteredProfiles = [...playerProfiles];
    if (event.query.trim().length > 0) {
      _filteredProfiles = _filteredProfiles.filter((p) => {
        return p.fullName.toLowerCase().includes(event.query.toLowerCase());
      });
    }
    setFilteredProfiles(_filteredProfiles);
  };
  const searchType = (event) => {
    let _options = options;
    setFilteredOptions(_options);
  };



  

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const modalContent = document.querySelector(`.${styles.modalContent}`);
      if (modalContent && !modalContent.contains(event.target)) {
        setModalOpen(false); 
      }
    };
  
    if (modalOpen) {
      document.addEventListener('click', handleOutsideClick);
    }
  
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [modalOpen]); 

  const handleModalTriggerClick = (event) => {
    event.stopPropagation();
    setModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  

  

  const formik = useFormik({
    initialValues: {
      playerProfile: "",
      type: "General",
      reportPreview: "",
      location: "",
      typeSpecificFields: initialSubFormValues,
      reportDate: "",
      reportContent: "",
      drafted: true,
      attachment: "",
      creator: userInfo.firstName + " " + userInfo.lastName,
      createdAt: moment().unix(),
    },
    validationSchema: validationSchema(playerProfileNames),
    onSubmit: (data, { resetForm }) => {
      let values = cloneDeep(data);
      console.log(values, "values");
      setLoading(true);
      if (values.typeSpecificFields.hasOwnProperty("roles")) {
        values.typeSpecificFields["roles"] =
          values.typeSpecificFields.roles.filter(
            (r) => r !== undefined && r !== ""
          );
      }
      db.collection("reports")
        .add({
          ...values,
          playerProfile: values.playerProfile.id,
        })
        .then((docRef) => {
          console.log("Document successfully written!");
          setLoading(false);
          resetForm({});
          setClearQuillBoard(true);
          setClearFiles(true);
          getPlayerReports();
          handleClose();
          toast.info("Report Created Successfully", {
            theme: "colored",
          });
         log_activity(
                values.playerProfile.fullName,
                `New ${values.type} Report Added`,
                userInfo.firstName + " " + userInfo.lastName,
                
              );
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Error Creating Report", {
            theme: "colored",
          });
          console.error("Error writing document: ", error);
        });
    },
  });

  const getRolesDropdownsComp = useCallback(() => {
    const rolesArray = [];
    for (let i = 0; i < roleCount; i++) {
      rolesArray.push(
        <select
          key={i}
          className={styles.custDropdownStyle}
          onChange={formik.handleChange}
          value={formik.values.typeSpecificFields.roles[i]}
          name={`typeSpecificFields.roles[${i}]`}
          onBlur={formik.onBlur}
        >
          <option value="">Role {i + 1}</option>
          {rolesDropdownData?.map(({ id, name, value }) => (
            <option key={id} value={value}>
              {name}
            </option>
          ))}
        </select>
      );
    }
    return rolesArray;
  }, [
    roleCount,
    formik.values.typeSpecificFields.roles,
    formik.values.type,
    rolesDropdownData,
  ]);

  const itemTemplate = (item) => (
    <div>
      <b>{item.fullName}</b>
      <br />
      <code>DOB: {moment(item.dob).format("MM/DD/YYYY")}</code>
    </div>
  );

  const setNewType = (e) => {
    formik.handleChange(e);
    formik.setFieldValue("typeSpecificFields", initialSubFormValues);
    setRoleCount(1);
  };

  const onBasicUploadAuto = ({ files }) => {
    files.forEach((file) => {
      storage
        .ref(`reports/${file.name}`)
        .put(file)
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
          formik.setFieldValue("attachment", url);
          toast.success("File uploaded successfully!", {
            theme: "colored",
          });
        })
        .catch((error) => {
          console.error("Error uploading file: ", error);
          toast.error("Error uploading file!", {
            theme: "colored",
          });
        });
    });
  };

  const clear = () => {
    confirmDialog({
      message: "Do you want to clear the form?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        formik.resetForm();
        setClearQuillBoard(true);
        setClearFiles(true);
      },
    });
  };

  const renderConditionalFields = () => {
    if (formik.values.type === "Intel") {
      return (
        <div>
          <Row>
            <Col>
              <label htmlFor="location">Location</label>
              <input
                id="location"
                name="location"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.typeSpecificFields.location}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.typeSpecificFields?.location &&
                  formik.touched.typeSpecificFields?.location
                    ? "is-invalid"
                    : ""
                }
              />
              {formik.errors.typeSpecificFields?.location &&
                formik.touched.typeSpecificFields?.location && (
                  <div className="invalid-feedback">
                    {formik.errors.typeSpecificFields.location}
                  </div>
                )}
            </Col>
          </Row>
          <Row>
            <Col>
              <label htmlFor="source">Source</label>
              <input
                id="source"
                name="source"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.typeSpecificFields.source}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.typeSpecificFields?.source &&
                  formik.touched.typeSpecificFields?.source
                    ? "is-invalid"
                    : ""
                }
              />
              {formik.errors.typeSpecificFields?.source &&
                formik.touched.typeSpecificFields?.source && (
                  <div className="invalid-feedback">
                    {formik.errors.typeSpecificFields.source}
                  </div>
                )}
            </Col>
          </Row>
        </div>
      );
    }
    return null;
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const ref = useRef(null);
  

    return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <div>
          <Toast ref={toast} />
          <ConfirmDialog />
         
          {/* ------------- REPORT FORM BEGINS -------------------*/}
    
          <div className={styles.container}>
            <div className={styles.title}>
              <div>
                <h4>Report</h4>
                <subtitle>
                  This section is used to create reports. Please fill all sections.{" "}
                </subtitle>
              </div>
            </div>
    
            <Row>
              <Col md={6}>
                <div className={styles.field}>
                  <p>Player Profile</p>
                  <AutoComplete
                    className={styles.fullWidth}
                    value={formik.values.playerProfile}
                    suggestions={filteredProfiles}
                    completeMethod={searchProfile}
                    field="fullName"
                    itemTemplate={itemTemplate}
                    name="playerProfile"
                    dropdown
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
    
                  {formik.errors.playerProfile && formik.touched.playerProfile ? (
                    <div className={styles.errorMessage}>
                      {formik.errors.playerProfile + " "}
                      {formik.errors.playerProfile ===
                        "This player profile doesn't exist" && (
                        <Link
                          className={styles.createProfLink}
                          to="/player-profile/create"
                        >
                          Create Player Profile <BsArrowRightCircle />
                        </Link>
                      )}
                    </div>
                  ) : null}
                </div>
                <div className={styles.field}>
                  <p>Report Date</p>
                  <input
                    type="date"
                    name="reportDate"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.reportDate}
                  />
                  {formik.errors.reportDate && formik.touched.reportDate ? (
                    <div className={styles.errorMessage}>
                      {formik.errors.reportDate}
                    </div>
                  ) : null}
                </div>
    
                <div className={styles.field}>
          <p>Type</p>
          <AutoComplete
            ref={ref}
            className={styles.fullWidth}
            dropdown
            forceSelection
            readOnly
            value={formik.values.type}
            suggestions={filteredOptions}
            completeMethod={searchType}
            name="type"
            onChange={(e) => {
              formik.handleChange(e);
              let type = e.value;
              let typeSpecificFieldsValues;
              if (["General", "Medical"].includes(type)) {
                typeSpecificFieldsValues = initialSubFormValues;
              } else if (type === "Intel") {
                typeSpecificFieldsValues = { source: "" };
              } else {
                setRoleCount(1);
                typeSpecificFieldsValues = {
                  stats: {
                    team: "",
                    opponent: "",
                    score: [],
                    min: "",
                    fg: [],
                    "3fg": [],
                    ft: [],
                    or: "",
                    tr: "",
                    ast: "",
                    to: "",
                    blk: "",
                    stl: "",
                    pf: "",
                    pts: "",
                  },
                  location: "",
                  roles: [],
                  projectionRange: { low: "", high: "" },
                };
              }
              formik.setFieldValue("typeSpecificFields", typeSpecificFieldsValues);
            }}
            onBlur={formik.handleBlur}
            // @ts-ignore
            onClick={(e) => ref.current?.search(e, "", "dropdown")}
          />
          {formik.errors.type && formik.touched.type ? (
            <div className={styles.errorMessage}>{formik.errors.type}</div>
          ) : null}
        </div>
              </Col>
    
              <Row>
                <Col
                  md={["General", "Medical"].includes(formik.values.type) ? 6 : 12}
                >
                  <div className={styles.field}>
                    <p>Report Preview/Event</p>
                    <input
                      type="text"
                      name="reportPreview"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.reportPreview}
                    />
                    {formik.errors.reportPreview && formik.touched.reportPreview ? (
                      <div className={styles.errorMessage}>
                        {formik.errors.reportPreview}
                      </div>
                    ) : null}
                  </div> 
                </Col>
                <Col md={6}>
                  <div className={styles.field}>
                    {["General", "Medical"].includes(formik.values.type) && (
                      <>
                        <p>URL Reference* (optional)</p>
                        <input
                          type="text"
                          name="typeSpecificFields.urlReference"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.typeSpecificFields.urlReference}
                        />
                        {formik.errors.typeSpecificFields?.urlReference &&
                        formik.touched.typeSpecificFields?.urlReference ? (
                          <div className={styles.errorMessage}>
                            {formik.errors.typeSpecificFields?.urlReference}
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <div className={styles.field}>
                {formik.values.type === "Scouting" && (
                  <>
                  <div className={styles.field}>
                    <p>Location</p>
                    <input
                      type="text"
                      name="location"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.location}
                    />
                    {formik.errors.location && formik.touched.location ? (
                      <div className={styles.errorMessage}>
                        {formik.errors.location}
                      </div>
                    ) : null}
                  </div>
    
                  <p>Projection Range</p>
                    <div className="d-flex flex-wrap">
                      <div className="px-2">
                        <p>Low</p>
                        <select
                          name="typeSpecificFields.projectionRange.low"
                          onChange={handleChange}
                          value={formik.values.typeSpecificFields.projectionRange.low}
                          className={styles.dropdownStyle}
                        >
                          <option value="">Select</option>
                          {prLowData.map((item) => (
                            <option key={item.id} value={item.value}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="px-2">
                        <p>High</p>
                        <select
                          name="typeSpecificFields.projectionRange.high"
                          onChange={handleChange}
                          value={formik.values.typeSpecificFields.projectionRange.high}
                          className={styles.dropdownStyle}
                        >
                          <option value="">Select</option>
                          {prHighData.map((item) => (
                            <option key={item.id} value={item.value}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="px-2">
                        <p>Confidence</p>
                        <select
                          name="typeSpecificFields.projectionRange.confidence"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={styles.dropdownStyle}
                          value={formik.values.typeSpecificFields.projectionRange.confidence}
                        >
                          <option value="">Select</option>
                          {[...Array(21).keys()].reverse().map((index) => (
                            <option key={index} value={`${index * 5}%`}>{`${index * 5}%`}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {(formik.errors.typeSpecificFields?.projectionRange?.low ||
                      formik.errors.typeSpecificFields?.projectionRange?.high) &&
                    (formik.touched.typeSpecificFields?.projectionRange?.low ||
                      formik.touched.typeSpecificFields?.projectionRange?.high) ? (
                      <>
                        <div className={styles.errorMessage}>
                          {formik.errors.typeSpecificFields.projectionRange.low}
                        </div>
                        <div className={styles.errorMessage}>
                          {formik.errors.typeSpecificFields.projectionRange.high}
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </div>
              <div className={styles.field}>
                {formik.values.type === "Scouting" && (
                  <>
                    <p>Roles</p>
                    <div className={styles.grayContainer}>
                      {formik.values.typeSpecificFields.roles &&
                        getRolesDropdownsComp()}
                      <button
                        onClick={() => setRoleCount((prevCount) => prevCount + 1)}
                        className={styles.roleBtn}
                        style={{ width: "auto" }}
                      >
                        <b>+</b>
                      </button>
                    </div>
                    {formik.errors.typeSpecificFields?.roles &&
                    formik.touched.typeSpecificFields?.roles ? (
                      <div className={styles.errorMessage}>
                        {formik.errors.typeSpecificFields?.roles}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              <div>
                {formik.values.type === "Scouting" && (
                  <>
                    <p style={{ fontWeight: "500" }}>Stats</p>
                    <StatsTable formik={formik} />
                    {formik.errors.typeSpecificFields?.stats &&
                    formik.touched.typeSpecificFields?.stats ? (
                      <div className={styles.errorMessage}>
                        {formik.errors.typeSpecificFields?.stats}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              <div className={styles.field}>
                <p>Report</p>
                <RichEditor
                  value={""}
                  setClear={setClearQuillBoard}
                  clear={clearQuillBoard}
                  handleChange={(value) => {
                    formik.setFieldValue("reportContent", value);
                  }}
                />
                {formik.errors.reportContent && formik.touched.reportContent ? (
                  <div className={styles.errorMessage}>
                    {formik.errors.reportContent}
                  </div>
                ) : null}
              </div>
    
              <div className={styles.field}>
                {formik.values.type !== "Scouting" && (
                  <>
                    <p>Source</p>
                    <input
                      type="text"
                      name="typeSpecificFields.source"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.typeSpecificFields.source}
                    />
                    {formik.errors.typeSpecificFields?.source &&
                    formik.touched.typeSpecificFields?.source ? (
                      <div className={styles.errorMessage}>
                        {formik.errors.typeSpecificFields?.source}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              
              <div className={styles.field}>
                <p>Upload Attachment</p>
              </div>
    
              <FileDropzone
                setLoading={setLoading}
                clearFiles={clearFiles}
                formik={formik}
              />
            </Row>
          </div>
          <div className={styles.buttonContainer}>
            <Row>
              {/*
              <Col md={4}>
                <Button className={styles.secondary}>Preview</Button>
              </Col>
              <Col md={4}>
                <Button
                  disabled={loading}
                  className={styles.secondary}
                  onClick={() => {
                    formik.setFieldValue("drafted", true);
                    formik.handleSubmit();
                  }}
                >
                  Save Draft
                </Button>
              </Col>
              */}
              <Col md={4}>
                <Button
                  disabled={loading}
                  type="submit"
                  onClick={() => {
                    formik.setFieldValue("drafted", false);
                    formik.handleSubmit();
                  }}
                >
                  {loading ? <Spinner animation="border" /> : "Publish"}
                </Button>
              </Col>
            </Row>
          </div>
    
          {/* ------------- REPORT ENDS ENDS -------------------*/}
        </div>
        </Modal>
      );
};


const validationSchema = (allPlayerProfileNames) => {
	return Yup.object({}).shape({
	  playerProfile: Yup.lazy((value) => {
		if (value === undefined) {
		  return Yup.string().required("Required");
		} else if (value.constructor === String) {
		  return Yup.string()
			.required("Required")
			.oneOf(
			  allPlayerProfileNames.current,
			  "This player profile doesn't exist"
			)
			.test(
			  "ExistingProfile",
			  `Profile/s with this name exists. Please Select`,
			  async (value2) =>
				(await allPlayerProfileNames.current.filter(
				  (p) => p.fullName === value2
				).length) > 0
			);
		} else {
		  return Yup.object().required("Required");
		}
	  }),
	  reportDate: Yup.date().required("Required"),
	  type: Yup.string().required("Required"),
	  reportPreview: Yup.string().required("Required"),
	  //location: Yup.string().required("Required"),
	  typeSpecificFields: Yup.lazy((value) => {
		let schema = {};
		if (value.hasOwnProperty("urlReference"))
		  schema["urlReference"] = Yup.string().notRequired();
		if (value.hasOwnProperty("projectionRange"))
		  schema["projectionRange"] = Yup.object().shape(
			{
			  low: Yup.mixed()
				.typeError("The value should be a number")
				.when("high", {
				  is: (val) => val !== undefined,
				  then: (schema) =>
					schema.required("Enter both low and high or enter none"),
				}),
			  high: Yup.mixed()
				.typeError("The value should be a number")
				.when("low", {
				  is: (val) => val !== undefined,
				  then: (schema) =>
					schema.required("Enter both low and high or enter none"),
				}),
			},
			[["high", "low"]]
		  );
		return Yup.object().shape(schema).required();
	  }),
	});
  };
