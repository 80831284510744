import { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
import BlotFormatter from "quill-blot-formatter";
import MediaLibraryModal from "../shared/MediaLibraryModal";
import "quill/dist/quill.snow.css";

import "./styles.css";

const Editor = ({ value, handleChange, clear, setClear }) => {
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const { mediaData, setMediaData } = useState(null);
  const { quill, quillRef, Quill } = useQuill({
    modules: { blotFormatter: {} },
  });

  // Insert Image(selected by user) to quill
  const insertToEditor = (url) => {
    quill.focus();
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", url);
    quill.deleteText(range.index + 1, 4);
  };

  // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
  const saveToServer = async (file) => {
    const body = new FormData();
    body.append("file", file);

    const res = await fetch("Your Image Server URL", { method: "POST", body });
    insertToEditor(res.uploadedImageUrl);
  };

  // Open Dialog to select Image File
  const selectLocalImage = () => {
    setShowMediaLibrary(true);
    insertToEditor(mediaData);
    // const input = document.createElement("input");
    // input.setAttribute("type", "file");
    // input.setAttribute("accept", "image/*");
    // input.click();

    // input.onchange = () => {
    //   const file = input.files[0];
    //   saveToServer(file);
    // };
  };

  if (Quill && !quill) {
    // const BlotFormatter = require('quill-blot-formatter');
    Quill.register("modules/blotFormatter", BlotFormatter);
  }
  useEffect(()=>{
    if(quill && clear)
    {
      quill.setText("");
      setClear(false);
    }
    
  }, [clear])

  useEffect(() => {
    if (quill) {
      
      quill.root.innerHTML = value;
      quill.getModule("toolbar").addHandler("image", selectLocalImage);
      // quill.clipboard.dangerouslyPasteHTML(value);
      quill.clipboard.innerHTML = value;
      quill.on("text-change", (delta, oldContents) => {
        // console.log("Text change!");
        // console.log(delta);
        handleChange(quill.root.innerHTML);
      });
    }
  }, [quill, Quill]);

  return (
    <div>
      <div
        style={{
          minHeight: "10rem",
        }}
        ref={quillRef}
      />
      <MediaLibraryModal
        handleClose={() => setShowMediaLibrary(false)}
        show={showMediaLibrary}
        handleSelect={(imageUrl) => {
          // console.log(imageUrl, "selected imageUrl");
          insertToEditor(imageUrl);
          setShowMediaLibrary(false);
        }}
      />
    </div>
  );
};

export default Editor;
