import styles from "./Chat.module.scss";
import Header from "../header/header";
import ChatContainer from "../chatContainer";
import { messages } from "../dashboard/dummyData";

export default function Chat() {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        {/*
        <div>
          <p>Messages</p>
          <div className={styles.list}>
            <div>
              <div>
                <span>Group Channel</span>
                <span>05/12/2021 - 12:01 pm</span>
              </div>
              <div>
                <span>Testing@gmail.com</span>
                <span>Did you check out the kid from san francisco</span>
              </div>
            </div>
            <div>
              <div>
                <span>Testing2@gmail.com</span>
                <span>05/12/2021 - 12:01 pm</span>
              </div>
              <div>
                <span>Testing2@gmail.com</span>
                <span>Did you check out the kid from san francisco</span>
              </div>
            </div>
          </div>
        </div>
        */}
        <ChatContainer messages={messages} />
      </div>
    </div>
  );
}
