import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";
// firebase
import { db } from "../../firebase";

// styles
import styles from "./preview.module.scss";

// icons

import { MdRemoveRedEye } from "react-icons/md";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { ViewReportModal } from "./ViewReportModal";

export function PlayerReports({ playerID, playerName }) {
  const [reports, setReports] = useState(null);
  const [filteredReports, setFilteredReports] = useState(null);
  const [selectedViewReport, setSelectedViewReport] = useState(false);
  const [filterValue, setFilterValue] = useState("Reset");

  const [firstName, lastName] = playerName.split(" ");

  const filters = [
    { name: "General", value: "General" },
    { name: "Scouting", value: "Scouting" },
    { name: "Medical", value: "Medical" },
    { name: "Intel", value: "Intel" },
    { name: "Reset", value: "Reset" },
  ];

  const getPlayerReports = async () => {
    try {
      const response = await db
        .collection("reports")
        .where("playerProfile", "==", playerID)
        .orderBy("reportDate", "desc")
        .get();
      let data = response.docs.map((report) => ({
        ...report.data(),
        id: report.id,
        playerName,
      }));
      console.log(data);
      setFilteredReports(data);
      setReports(data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getPlayerReports();
  }, []);
  useEffect(() => {
    if (filterValue !== "Reset") {
      let rep = reports.filter((report) => report.type === filterValue);
      setFilteredReports(rep);
    } else {
      setFilteredReports(reports);
    }
  }, [filterValue]);
  if ( (filteredReports && Object.keys(filteredReports).length === 0) || filteredReports === null ) {
  return (  
   
      <div className={styles.feed}>
        <div className="d-flex justify-content-start align-items-center">
          <div className="pe-5">Filter Reports By</div>
          <ButtonGroup>
            {filters.map((filter, idx) => (
              <ToggleButton
                key={idx}
                id={`filter-${idx}`}
                type="radio"
                variant="outline-dark"
                name="filter"
                value={filter.value}
                checked={filterValue === filter.value}
                onChange={(e) => setFilterValue(e.currentTarget.value)}
              >
                {filter.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
        <div className={styles.noReportsMessage}>
          No reports available for this selected filter at this time.
        </div>
      </div>
    );
  }
  return (
    <>
      {selectedViewReport && (
        <ViewReportModal
          show={selectedViewReport}
          handleClose={() => setSelectedViewReport(null)}
          reportData={
            filteredReports.filter((rep) => rep.id === selectedViewReport)[0]
          }
          firstName={firstName} 
          lastName={lastName} 
        />
      )}
      <div className={styles.feed}>
        <div className="d-flex justify-content-start align-items-center">
          <div className="pe-5">Filter Reports By</div>
          <ButtonGroup>
            {filters.map((filter, idx) => (
              <ToggleButton
                key={idx}
                id={`filter-${idx}`}
                type="radio"
                variant="outline-dark"
                name="filter"
                value={filter.value}
                checked={filterValue === filter.value}
                onChange={(e) => setFilterValue(e.currentTarget.value)}
              >
                {filter.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
        <div className={styles.tableContainer}>
          <table>
            <thead>
              <tr>
                <th>Report Date</th>
                <th>Report Type</th>
                <th>Preview</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {filteredReports.map((item, index) => (
                <tr key={index}>
                  
                  <td>{moment(item.reportDate).format("MM/DD/YYYY")}</td>
                  <td>{item.type}</td>
                  <td>
                      {item.reportPreview}
                      {item.location && ` (${item.location})`}
                  </td>
                  <td>
                    <div className={styles.actionIcon}>
                      <MdRemoveRedEye
                        onClick={() => setSelectedViewReport(item.id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
