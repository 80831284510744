import React, { useState } from "react";

// react bootstrap
import { Row, Col } from "react-bootstrap";

// components
import DateTimePicker from "react-datetime-picker";
import { BsCardText } from "react-icons/bs";
import styles from "./Form.module.scss";

// header
import Header from "../header/header";

export default function CalendarForm() {
  return (
    <div>

      <Header />
      {/* ------------- CALENDAR FORM BEGINS -------------------*/}

      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Calendar</h4>
            <subtitle>
            This section is used to add a new event on the calendar. Please fill all sections. {" "}
            </subtitle>
          </div>
        </div>
        <Row>
            <Col md={6}>
                <div className={styles.field}>
                    <p>Event Date</p>
                    <input type="text" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={8}>
                <div className={styles.field}>
                    <p>Event Title</p>
                    <input type="text" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={8}>
                <div className={styles.field}>
                    <p>Location</p>
                    <input type="text" />
                </div>
            </Col>
        </Row>
        <div className={styles.field}>
          <p>Note</p>
          <textarea name="" id="" rows={6}></textarea>
        </div>
      </div>    
    </div>
  );
}
