import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.scss";
import moment from "moment";
import { db } from "../../firebase";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


export default function ActivityFeed() {
  const [activityFeed, setActivityFeed] = useState([]);

  const getActivityFeed = () => {
    db.collection("activity")
      .orderBy("timestamp", "desc")
      .get()
      .then((snapshot) => {
        let feed = [];
        snapshot.docs.forEach((doc) => {
          feed.push({ ...doc.data() });
        });
        setActivityFeed(feed);
      });
  };
  useEffect(() => {
    getActivityFeed();
  }, []);
  return (
    <div className={styles.feed}>
      <p>Activity Feed</p>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Player</th>
              <th>Type</th>
              <th>Editor</th>
            </tr>
          </thead>
          <tbody>
            {activityFeed.map((item, index) => (
              <tr key={index}>
                <td>{moment.unix(item.timestamp).format("l")}</td>
                <td>{moment.unix(item.timestamp).format("hh:mm:ss A")}</td>              
                <td>
                  {item.playerID ? (
                    <Link to={`/player-profile/detail/${item.playerID}`}>
                      {item.player}
                    </Link>
                  ) : (
                    item.player
                  )}
                </td>


                <td>{item.desc}</td>
                <td>{item.editor}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
