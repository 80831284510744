import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

// styles
import styles from "./Header.module.scss";

// icons
import { IoNotifications } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import { auth, db } from "../../firebase";
import { notificationService } from "../../Services/notification-service";

export const NotificationMenu = ({ show, handleClose }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    db
    .collection('notifications')
    .where('receipients','array-contains', auth.currentUser.uid)
    .onSnapshot(snapshot => {
      const unreadNotifications = snapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(doc => !doc.read[auth.currentUser.uid]);

      setNotifications(unreadNotifications);
    })
  }, []);

  const handleMarkAsRead = (notificationId) => {
    notificationService.markAsRead(notificationId, auth.currentUser.uid);
  }

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className={styles.notificationsSections}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className={styles.title}>
              <IoNotifications className="mr-2" />
              <span>Notification</span>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={styles.listgroup}>
           {notifications.map(notification => {
            return (
              <ListItem
                key={notification.id}
                item={notification}
                handleMarkAsRead={handleMarkAsRead}
              />
            )
           })} 
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const ListItem = ({ item, handleMarkAsRead }) => {
  return (
    <div className={styles.listItem}>
      <div>
        <h4>{item.message}</h4>
        <p>{new Date(item.timestamp).toDateString()}</p>
      </div>
      <div>
        <MdOutlineCancel onClick={() => handleMarkAsRead(item.id)}/>
      </div>
    </div>
  );
};
