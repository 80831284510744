import Layout from "../../../components/layout/layout.js";

import Packets from "../../../components/componentsPage/piPackets/index.js";

function PacketsPage({ component, ...rest }) {
  return (
    <Layout Component={Packets} controlsDisabled={true} {...rest} />
  );
}

export default PacketsPage;
