import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";

// styles
import styles from "./Form.module.scss";

//icons
import { MdCloudUpload, MdVideocam } from "react-icons/md";

export function StyledDropzone(props) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/gif": [".gif"],
      "image/bmp": [".bmp"],
      "image/tiff": [".tiff"],
      "image/webp": [".webp"],
      "image/svg": [".svg"],
    },
    multiple: false,
    maxSize: 5000000,
    onDrop: (acceptedFiles) => {
      console.log(acceptedFiles[0]);
      props.onDrop(acceptedFiles[0]);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
      <div
        {...getRootProps({ style })}
        onClick={() => {
          props.handleOpenMediaLibrary && props.handleOpenMediaLibrary();
        }}
      >
        {/* <input {...getInputProps()} /> */}
        {props.video ? (
          <MdVideocam style={{ fontSize: "24px" }} />
        ) : (
          <MdCloudUpload style={{ fontSize: "24px" }} />
        )}
        <p>Upload file smaller than 3MB</p>
      </div>
      {props.errors && props.touched ? (
        <div className={styles.errorMessage}>{props.errors}</div>
      ) : null}
      {acceptedFiles.length > 0 && (
        <aside>
          <h6 className="mt-2">Files</h6>
          <ul>{files}</ul>
        </aside>
      )}{" "}
    </>
  );
}

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  marginTop: "15px",
  width: "100%",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
