import { useState, useRef } from "react";

import styles from "./Header.module.scss";
import { FaSearch } from "react-icons/fa";
import { BiComment } from "react-icons/bi";
import { IoNotifications, IoSettingsSharp } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import { NotificationMenu } from "./NotificationMenu";
import SearchSection from "./SearchSection";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useAuth } from "../../Context/AuthContext";
import { useHistory } from "react-router-dom";
import IsAdmin from "../../utils/isSuperAdmin";

export default function Header({ search }) {
  const [toggleNotification, setToggleNotification] = useState(false);

  const handleClose = () => setToggleNotification(false);
  const handleShow = () => setToggleNotification(true);

  const { logout, userInfo, isAdmin, isSuperAdmin } = useAuth();
  const navigate = useHistory();

  const menu = useRef(null);
  //const router = useRouter();
  const toast = useRef(null);
  const items = [
    {
      label: "Options",
      items: [
        {
          label: "Logout",
          icon: "pi pi-fw pi-power-off ",
          command: () => {
            //router.push("/login");
            logout();
            navigate.replace("/login");

            toast.current.show({
              severity: "warn",
              summary: "Logged Out",
              detail: "You have been logged out.",
              life: 3000,
            });
          },
        },
      ],
    },
  ];

  return (
    <div className={styles.container}>
      <SearchSection display={search} />
      <div>
        {(isAdmin() || isSuperAdmin()) && (
          <Link to="/chat">
            <BiComment />
          </Link>
        )}{" "}
        <IoNotifications onClick={() => handleShow()} />
        {/* <IoSettingsSharp /> */}
        <hr />
        <Toast ref={toast}></Toast>
        <Menu model={items} popup ref={menu} />
        <div onClick={(e) => menu.current.toggle(e)} className={styles.profile}>
          <div className={styles.avatar}></div>
          <span>{userInfo?.email}</span>
          <MdOutlineKeyboardArrowDown />
        </div>
      </div>
      <NotificationMenu show={toggleNotification} handleClose={handleClose} />
    </div>
  );
}
