const date = Date.now();
export const eventsList = [
  {
    title: "event title 1 : error",
    start: date,
    end: date,
    allDay: true,
    type: "error",
  },
  {
    title: "event title 2: success",
    start: new Date("2022-06-30"),
    end: new Date("2022-06-30"),
    allDay: true,
    type: "success",
  },
  {
    title: "event title 3: warning",
    start: new Date("2022-07-11"),
    end: new Date("2022-07-15"),
    allDay: true,
    type: "warning",
  },
];
