import Layout from "../../../components/layout/layout.js";

import WingspanDropdown from "../../../components/componentsPage/wingspan/index.js";

function WingspanDropdownPage({ component, ...rest }) {
  return (
    <Layout Component={WingspanDropdown} controlsDisabled={true} {...rest} />
  );
}

export default WingspanDropdownPage;
