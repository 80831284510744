import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";
import { useAuth } from "../../Context/AuthContext";
import { db } from "../../firebase";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { MdRemoveRedEye } from "react-icons/md";
import { ViewReportModal } from "../PlayerPreview/ViewReportModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ITEMS_PER_PAGE = 10; // Adjust the number of reports to load per page

export default function RecentActivity() {
  const history = useHistory();
  const { getUserInfoData } = useAuth();
  const [filteredReports, setFilteredReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedViewReport, setSelectedViewReport] = useState(null);
  

  async function handleGetRecentActivity() {
    const document = await db.collection("reports")
      .orderBy("reportDate", "desc")
      .limit(ITEMS_PER_PAGE * currentPage)
      .get();

    const response = await db.collection("player-profile").get();
    const profileData = response.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });

    let profileIds = [];
    profileData.forEach((el) => {
      if (
        ["admin", "super-admin"].includes(getUserInfoData().memberType) ||
        el["viewing_privileges"] &&
        el["viewing_privileges"].includes(getUserInfoData().memberType)
      ) {
        profileIds.push(el.id);
      }
    });

     const recentActivityList = document.docs
      .filter((doc) => profileIds.includes(doc.data().playerProfile))
      .map((doc) => {
        let player = profileData.find((el) => el.id === doc.data().playerProfile);
        return { id: doc.id, ...doc.data(), player };
      });

    setFilteredReports(recentActivityList);
  }

  useEffect(() => {
    handleGetRecentActivity();
  }, [currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleViewReport = (reportId) => {
    setSelectedViewReport(reportId);
  };

  if (filteredReports.length === 0) {
    return <p>Loading...</p>;
  }

  return (
    <div className={styles.recentActivity}>
      {selectedViewReport && (
        <ViewReportModal
          show={selectedViewReport}
          handleClose={() => setSelectedViewReport(null)}
          reportData={
            filteredReports.find((rep) => rep.id === selectedViewReport)
          }
          firstName={filteredReports.find((rep) => rep.id === selectedViewReport).player.FirstName} 
          lastName={filteredReports.find((rep) => rep.id === selectedViewReport).player.LastName} 
        />
      )}

      <p>Recent Activity</p>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Player</th>
              <th>Report Type</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {filteredReports.map((item, index) => (
              <tr key={index}>
                <td>{moment(item.reportDate).format("MM/DD/YYYY")}</td>
                <td>
                  <Link to={`/player-profile/preview/${item.player.id}`}>
                    {item.player.FirstName}&nbsp;{item.player.LastName}
                  </Link>
                </td>
                <td>{item.type}</td>
                <td>
                  <div className={styles.actionIcon}>
                    <MdRemoveRedEye onClick={() => handleViewReport(item.id)} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          
        </table>
        <div className={styles.centeredButton}>
          <button onClick={handleLoadMore}>Load More</button>
        </div>
      </div>
      
    </div>
  );
}
