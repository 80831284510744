import styles from "./newAAUModal.module.scss";
import { MdClose } from "react-icons/md";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Row, Col, Modal } from 'react-bootstrap';
import * as Yup from "yup";
// firebase
//import { db } from "../../../firebase";
import { useState } from "react";

// firebase
import { db } from "../../../firebase";

export default function AddAAUSuggestionModal({  open, setOpen, reloadData}) {
  const close = () => {
    setOpen(false);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      value: "",
      aauAffilation: "",
      aauCoach: "",
      aauCoachLast: "",
      aauCoachInfo: "",
      aauCoachEmail: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Required";
      }
     
      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      db.collection("aau-suggestion")
        .add({
          ...values,
        })
        .then((docRef) => {
          close();
          resetForm();
          toast.info("AAU Team Suggestion Item Created Successfully", {
            theme: "colored",
          });
          reloadData();
        })
        .catch((error) => {
          toast.error("Error Creating AAU Team Suggestion Item", {
            theme: "colored",
          });

          console.error("Error adding document: ", error);
        })
        .finally(() => {
          close();
        });
    },
  });
  return (
    <Modal
      show={open}
      onHide={close}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className={styles.modal}>
        <button onClick={close}>
          <MdClose />
        </button>
        <form onSubmit={formik.handleSubmit} className="w-100">
          <div className={styles.content}>
            <h3>AAU Team</h3>
            <span>
              This section is used to add new suggested AAU Team option to the dropdown.
            </span>
            <hr />
            <div className={styles.inputs}>
              <span>Name</span>
              <input
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <span className={styles.error}>
                {formik.errors.name &&
                  formik.touched.name &&
                  formik.errors.name}
              </span>
              <span>Value</span>
              <input
                type="text"
                name="value"
                onChange={formik.handleChange}
                value={formik.values.value}
              />
              <span className={styles.error}>
                {formik.errors.value&&
                  formik.touched.value &&
                  formik.errors.value}
              </span>
              <span>AAU Affiliation</span>
                  <select
                    name="aauAffilation"
                    onChange={formik.handleChange}
                    value={formik.values.aauAffilation}
                    onBlur={formik.handleBlur}
                    className={styles.dropdownStyle}
                  >
                    <option value="">Choose AAU Affiliation</option>
                    <option value="Nike">Nike</option>
                    <option value="adidas">adidas</option>
                    <option value="Under Armour">Under Armour</option>
                    <option value="Puma/Pro16">Puma/Pro16</option>
                    <option value="Independent">Independent</option>
                  </select>
                  <span className={styles.error}>
                    {formik.errors.aauAffilation && formik.touched.aauAffilation && formik.errors.aauAffilation}
                  </span>

                  <span>Coach First Name</span>
                  <input
                    type="text"
                    name="aauCoach"
                    onChange={formik.handleChange}
                    value={formik.values.aauCoach}
                  />
                  <span className={styles.error}>
                    {formik.errors.aauCoach &&
                      formik.touched.aauCoach &&
                      formik.errors.aauCoach}
                  </span>
                  <span>Coach Last Name</span>
                  <input
                    type="text"
                    name="aauCoachLast"
                    onChange={formik.handleChange}
                    value={formik.values.aauCoachLast}
                  />
                  <span className={styles.error}>
                    {formik.errors.aauCoachLast&&
                      formik.touched.aauCoachLast &&
                      formik.errors.aauCoachLast}
                  </span>

                  <span>Phone</span>
                  <input
                    type="text"
                    name="aauCoachInfo"
                    onChange={formik.handleChange}
                    value={formik.values.aauCoachInfo}
                  />
                  <span className={styles.error}>
                    {formik.errors.aauCoachInfo &&
                      formik.touched.aauCoachInfo &&
                      formik.errors.aauCoachInfo}
                  </span>
                  <span>Email</span>
                  <input
                    type="text"
                    name="aauCoachEmail"
                    onChange={formik.handleChange}
                    value={formik.values.aauCoachEmail}
                  />
                  <span className={styles.error}>
                    {formik.errors.aauCoachEmail&&
                      formik.touched.aauCoachEmail &&
                      formik.errors.aauCoachEmail}
                  </span>

            </div>
          </div>
          <div className={styles.btns}>
            <button onClick={close}>Cancel</button>
            <button type="submit">Submit Request</button>
          </div>
        </form>
        </div>
        </Modal>
    
  );
}
