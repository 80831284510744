import { db } from "../firebase";

class NotificationService {
  notificationsRef = db.collection('notifications');

  /**
   * @param {string[]} receipients 
   * @param {string} message 
   */
  async send(receipients = [], message = '') {
    const notification = {
      timestamp: Date.now(),
      message,
      receipients,
      read: {}
    };
    try {
      await this.notificationsRef.doc().set(notification)
    } catch (error) {
      console.log('error: notification not sent') 
    }
  }

  /**
   * @param {string} notificationId 
   * @param {string} userId 
   */
  async markAsRead(notificationId, userId) {
    if (!notificationId || !userId) return;

    try {
      await this.notificationsRef.doc(notificationId).update({ [`read.${userId}`]: true });
    } catch (error) {
      console.log('error: notification not marked as read.') 
    }
  }
}

export const notificationService = new NotificationService(); 