import Layout from "../../../components/layout/layout.js";

import AauTeamsDropdown from "../../../components/componentsPage/aauTeams/index.js";

function AauTeamsDropdownPage({ component, ...rest }) {
  return (
    <Layout Component={AauTeamsDropdown} controlsDisabled={true} {...rest} />
  );
}

export default AauTeamsDropdownPage;
