import Layout from "../../../components/layout/layout.js";
import Dashboard from "../../../components/dashboard/index.js";
import { useAuth } from "../../../Context/AuthContext";

function AdminIndexPage({ component, ...rest }) {
  const { userInfo } = useAuth();

  if (!userInfo) {
    return <></>;
  }
  return <Layout Component={Dashboard} controlsDisabled={true} {...rest} />;
}

export default AdminIndexPage;
