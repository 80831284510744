import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import styles from "./subs.module.scss";
import { Formik, Form, Field } from "formik";
import { isEmptyField, isValidEmail } from "../../utils/helpers";
import { functions } from "../../firebase";
import { toast, ToastContainer } from "react-toastify";
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import "react-toastify/dist/ReactToastify.css";

const AddMemberForm = ({ open, handleToggle, refreshMembers }) => {
  const initialValues = {
    firstName: "",
    lastName: "",
    org: "",
    orgTitle: "",
    subStart: "",
    subEnd: "",
    memberType: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  // for console testing purposes submitting data as this: Invalid date value for this field
  // The provided value is: {"nanoseconds":0,"seconds":830934000}
  const handleDateChange = (event, fieldName, setFieldValue) => {
    const { value } = event.target;
    console.log(`${fieldName} selected date (Raw):`, value);
  
   
    const date = moment(value, 'YYYY-MM-DD').toDate();
    console.log(`${fieldName} selected date (Converted):`, date);
  
    
    console.log(`${fieldName} selected date (Formatted):`, moment(date).format('MMMM D, YYYY [at] h:mm:ss A [UTC]Z'));
  
   
    setFieldValue(fieldName, value);
  };

  const handleSubmitForm = (values) => {
    const subStartDate = values.subStart ? new Date(values.subStart + "T00:00:00") : null;
    const subEndDate = values.subEnd ? new Date(values.subEnd + "T00:00:00") : null;

    console.log('Raw subStartDate:', subStartDate);
    console.log('Raw subEndDate:', subEndDate);

    // Firebase Timestamp is not importing as expecting causing the app to crash.
    const formattedValues = {
      ...values,
      subStart: subStartDate ? Timestamp.fromDate(subStartDate) : null,
      subEnd: subEndDate ? Timestamp.fromDate(subEndDate) : null,
    };

    const createUser = functions.httpsCallable('createUser');

    createUser(formattedValues)
      .then((result) => {
        if (result.data.success) {
          console.log('User created with UID:', result.data.uid);
          toast.success('User created successfully!');
          handleToggle();
          refreshMembers(); 
        } else {
          console.log('Error creating user:', result.data.error);
          toast.error('Error creating user: ' + result.data.error);
        }
      })
      .catch((err) => {
        console.log('Error calling cloud function:', err.message);
        toast.error('Error calling cloud function: ' + err.message);
      });
  };

  const validateForm = (values) => {
    let errors = {};

    if (isEmptyField(values.firstName)) errors.firstName = "First Name is a required field";
    if (isEmptyField(values.lastName)) errors.lastName = "Last Name is a required field";
    if (isEmptyField(values.memberType)) errors.memberType = "Member Type is a required field";
    if (isEmptyField(values.org)) errors.org = "Organization Type is a required field";
    if (isEmptyField(values.email)) errors.email = "Email is a required field";
    if (!isValidEmail(values.email.trim())) errors.email = "Invalid email";
    const passwordRegex = /(?=.*[0-9])/;
    if (isEmptyField(values.password)) errors.password = "Password is a required field";
    else if (values.password.length < 8) errors.password = "Password must be at least 8 characters";
    else if (!passwordRegex.test(values.password)) errors.password = "Password must contain a number";
    if (isEmptyField(values.confirmPassword) || values.password !== values.confirmPassword) 
      errors.confirmPassword = "Confirm Password is a required field";

    return errors;
  };

  return (
    <>
      <ToastContainer />
      <Modal show={open} onHide={handleToggle} className={styles.addFormModalContainer}>
        <div className={styles.modalDialog}>
          <Modal.Header className={styles.modalHeader}>
            <Modal.Title>
              <h3>Add Member</h3>
            </Modal.Title>
            <Button variant="close" onClick={handleToggle} />
          </Modal.Header>

          <Modal.Body>
            <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmitForm}>
              {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <label>First Name</label>
                    <Field
                      id="firstName"
                      name="firstName"
                      type="text"
                      value={values.firstName}
                      onChange={(value) =>
                        setFieldValue("firstName", value.target.value)
                      }
                      className={styles.inputField}
                    />
                    {errors.firstName && touched.firstName && (
                      <p className={styles.error}>{errors.firstName}</p>
                    )}
                  </div>
                  <div>
                    <label>Last Name</label>
                    <Field
                      id="lastName"
                      name="lastName"
                      type="text"
                      value={values.lastName}
                      onChange={(value) =>
                        setFieldValue("lastName", value.target.value)
                      }
                      className={styles.inputField}
                    />
                    {errors.lastName && touched.lastName && (
                      <p className={styles.error}>{errors.lastName}</p>
                    )}
                  </div>
                  <div>
                    <label>Organization</label>
                    <Field
                      id="org"
                      name="org"
                      type="text"
                      value={values.org}
                      onChange={(value) =>
                        setFieldValue("org", value.target.value)
                      }
                    />
                    {errors.org && touched.org && (
                      <p className={styles.error}>{errors.org}</p>
                    )}
                  </div>
                  <div>
                    <label>Organization Title</label>
                    <Field
                      id="orgTitle"
                      name="orgTitle"
                      type="text"
                      value={values.orgTitle}
                      onChange={(value) =>
                        setFieldValue("orgTitle", value.target.value)
                      }
                    />
                    {errors.orgTitle && touched.orgTitle && (
                      <p className={styles.error}>{errors.orgTitle}</p>
                    )}
                  </div>
                  <div>
                    <label>Start Date</label>
                    <Field
                      id="subStart"
                      name="subStart"
                      type="date"
                      value={values.subStart}
                      onChange={(event) => {
                        handleDateChange(event, 'subStart', setFieldValue);
                      }}
                    />
                    {errors.subStart && touched.subStart && (
                      <p className={styles.error}>{errors.subStart}</p>
                    )}
                  </div>
                  <div>
                    <label>Renewal Date</label>
                    <Field
                      id="subEnd"
                      name="subEnd"
                      type="date"
                      value={values.subEnd}
                      onChange={(event) => {
                        handleDateChange(event, 'subEnd', setFieldValue);
                      }}
                    />
                    {errors.subEnd && touched.subEnd && (
                      <p className={styles.error}>{errors.subEnd}</p>
                    )}
                  </div>
                  <div>
                    <label>Email</label>
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      value={values.email}
                      onChange={(value) =>
                        setFieldValue("email", value.target.value)
                      }
                    />
                    {errors.email && touched.email && (
                      <p className={styles.error}>{errors.email}</p>
                    )}
                  </div>
                  <div>
                    <label>Member Type</label>
                    <Field
                      id="memberType"
                      as="select"
                      name="memberType"
                      value={values.memberType}
                      onChange={(value) =>
                        setFieldValue("memberType", value.target.value)
                      }
                    >
                      <option value="executive">Executive</option>
                      <option value="ncaa">NCAA</option>
                      <option value="demo">Demo</option>
                      <option value="admin">Admin</option>
                      <option value="super-admin">Super Admin</option>
                    </Field>
                    {errors.memberType && touched.memberType && (
                      <p className={styles.error}>{errors.memberType}</p>
                    )}
                  </div>
                  <div>
                    <label>Password</label>
                    <div className={styles.passwordContainer}>
                      <Field
                        id="password"
                        className={styles.passwordField}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        onChange={(value) =>
                          setFieldValue("password", value.target.value)
                        }
                      />
                      <button
                        className={styles.toggleButton}
                        type="button"
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                    {errors.password && touched.password && (
                      <p className={styles.error}>{errors.password}</p>
                    )}
                  </div>
                  <div>
                    <label>Confirm Password</label>
                    <div className={styles.passwordContainer}>
                      <Field
                        className={styles.passwordField}
                        id="confirmPassword"
                        name="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        value={values.confirmPassword}
                        onChange={(value) =>
                          setFieldValue("confirmPassword", value.target.value)
                        }
                      />
                      <button
                        className={styles.toggleButton}
                        type="button"
                        onClick={toggleShowConfirmPassword}
                      >
                        {showConfirmPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                    {errors.confirmPassword && touched.confirmPassword && (
                      <p className={styles.error}>{errors.confirmPassword}</p>
                    )}
                  </div>
                  <Modal.Footer className={styles.modalFooter}>
                    <Button variant="secondary" onClick={handleToggle}>
                      Close
                    </Button>
                    <Button variant="primary" type="submit">
                      Add Member
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default AddMemberForm;
