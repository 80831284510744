import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import DeleteModal from "../../shared/DeleteModal";
import { MdOutlineCancel } from 'react-icons/md';
import { HiPlusSm } from 'react-icons/hi';
import { db } from '../../../firebase'; // Import db from firebase

// styles
import styles from "../Form.module.scss";

export function Personal({ setFieldValue, personal, handleChange, handleBlur }) {
    useEffect(()=>{
        personal.familyCircleMembers.length === 0 && setFieldValue("personal.familyCircleMembers", [{ member: "", note: "" }]);
        personal.nuggets.length === 0 && setFieldValue("personal.nuggets", [""]);
    }, [personal.familyCircleMembers.length, personal.nuggets.length ])

    const [deleteIndex, setDeleteIndex] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [familyMembers, setFamilyMembers] = useState([]);

    useEffect(() => {
        const fetchFamilyMembers = async () => {
            try {
                const snapshot = await db.collection("family-dropdown").orderBy("order").get();
                const sortedMembers = snapshot.docs.map(doc => doc.data().value);
                setFamilyMembers(sortedMembers);
            } catch (error) {
                console.error("Error fetching family members:", error);
            }
        };

        fetchFamilyMembers();
    }, []);

    const Add = (index, fieldName) => {
        setFieldValue(`personal.${fieldName}`,
            [
                ...personal[fieldName].slice(0, index + 1),
                fieldName === "nuggets" ? "" : { member: "", note: "" },
                ...personal[fieldName].slice(index + 1),
            ]
        );
    }

    const ConfirmDelete = (index, fieldName) => {
        setFieldValue(`personal.${fieldName}`, [
            ...personal[fieldName].filter((_, ind) => ind !== index),
        ])
    }

    const Delete = (index, fieldName) => {
        if (personal[fieldName].length === 1) return;
        setDeleteConfirm(true);
        setDeleteIndex({ index: index, fieldName: fieldName });
    }

    return (
        <>
            <DeleteModal
                show={deleteConfirm}
                handleConfirm={() => { ConfirmDelete(deleteIndex.index, deleteIndex.fieldName) }}
                handleClose={() => setDeleteConfirm(false)}
            />
            <div className={styles.container}>
                <div className={styles.title}>
                    <div>
                        <h4>Personal</h4>
                        <subtitle>
                            This section is used to add personal information related to family and their circle. Please fill as many sections as possible.
                        </subtitle>
                    </div>
                </div>
                {personal.familyCircleMembers.map((item, index) =>
                    <FamilyCircleMembers key={index} index={index} Add={Add} Delete={Delete} handleBlur={handleBlur} handleChange={handleChange} item={item} familyMembers={familyMembers} />
                )}
                <div className={styles.field}>
                    <p>Nuggets</p>
                    {personal.nuggets.map((nug, index) =>
                        <div className={styles.btns} key={index} >
                            <input  type="text" name={`personal.nuggets[${index}]`} value={nug} onChange={handleChange} onBlur={handleBlur} />
                            <button  className={`${styles.green} ${styles.small}`} onClick={() => { Add(index, "nuggets") }} ><HiPlusSm /></button>
                            <button  className={`${styles.red} ${styles.small}`} onClick={() => { Delete(index, "nuggets") }} ><MdOutlineCancel /></button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

const FamilyCircleMembers = ({ index, Add, Delete, handleBlur, handleChange, item, familyMembers }) => {
    return (
        <Row>
            <Col md={4}>
                <div className={styles.field}>
                    {index === 0 && <p>Family/Circle Member</p>}
                    <select className={styles.dropdownStyle} name={`personal.familyCircleMembers[${index}].member`} value={item.member} onChange={handleChange} onBlur={handleBlur}>
                        <option value="">Select Member</option>
                        {familyMembers.map((member, index) => (
                            <option key={index} value={member}>{member}</option>
                        ))}
                    </select>
                </div>
            </Col>
            <Col md={8}>
                <div className={styles.field}>
                    {index === 0 && <p>Note</p>}
                    <div className={styles.btns}>
                        <input type="text" name={`personal.familyCircleMembers[${index}].note`} value={item.note} onChange={handleChange} onBlur={handleBlur} />
                        <button className={`${styles.green} ${styles.small}`} onClick={() => { Add(index, "familyCircleMembers") }}><HiPlusSm /></button>
                        <button className={`${styles.red} ${styles.small}`} onClick={() => { Delete(index, "familyCircleMembers") }}><MdOutlineCancel /></button>
                    </div>
                </div>
            </Col>
        </Row>
    );
};