import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Row, Col, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
// firebase
import { db } from "../../../firebase";

export default function EditModal({ data, setOpen, reloadData, collection }) {
  const close = () => {
    setOpen(false);
  };

  const handleIdentifierChange = (event) => {
    const newIdentifier = event.target.value;
    console.log("Selected Identifier Name:", newIdentifier);
    formik.setFieldValue('identifier', newIdentifier);
  };

  const handleMoveToSchool = () => {
    const newSchoolData = {
      name: formik.values.name,
      value: formik.values.value,
      state: formik.values.state,
      identifier: formik.values.identifier,
      teamName: formik.values.teamName,
      nbaAffil: formik.values.nbaAffil,
      coachFN: formik.values.coachFN,
      coachLN: formik.values.coachLN,
      phone: formik.values.phone,
      email: formik.values.email,
    };

    db.collection("school-dropdown").add(newSchoolData)
      .then(() => {
        db.collection("team-suggestion-dropdown").doc(data.id).delete()
          .then(() => {
            toast.success("Team moved to School Dropdown successfully", {
              theme: "colored",
            });
            close();
            reloadData();
          })
          .catch((error) => {
            toast.error("Error removing team from Team Suggestions", {
              theme: "colored",
            });
            console.error("Error removing team: ", error);
          });
      })
      .catch((error) => {
        toast.error("Error adding team to School Dropdown", {
          theme: "colored",
        });
        console.error("Error adding team: ", error);
      });
  };

  const formik = useFormik({
    initialValues: {
      ...data,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      value: Yup.string().required("Required"),
      state: Yup.string().when('identifier', {
        is: (val) => val === 'HS/Prep',
        then: Yup.string().required("Required"),
        otherwise: Yup.string()
      }),
      teamName: Yup.string().when('identifier', {
        is: (val) => val === 'NBA' || val === 'G League',
        then: Yup.string().required("Required"),
        otherwise: Yup.string()
      }),
      nbaAffil: Yup.string().when('identifier', {
        is: (val) => val === 'G League',
        then: Yup.string().required("Required"),
        otherwise: Yup.string()
      }),
      coachFN: Yup.string(),
      coachLN: Yup.string(),
      phone: Yup.string(),
      email: Yup.string().email("Invalid email"),
    }),
    onSubmit: (values, { resetForm }) => {
      db.collection(collection)
        .doc(data.id)
        .update({
          ...values,
        })
        .then((docRef) => {
          close();
          resetForm();
          toast.success("Item Updated Successfully", {
            theme: "colored",
          });
          reloadData();
        })
        .catch((error) => {
          toast.error("Error Updating Item", {
            theme: "colored",
          });

          console.error("Error updating document: ", error);
        })
        .finally(() => {
          close();
        });
    },
  });

  return (
    <Modal
      show={data}
      onHide={close}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className={styles.modal}>
        <button onClick={close}>
          <MdClose />
        </button>
        <form onSubmit={formik.handleSubmit} className="w-100">
          <div className={styles.content}>
            <h3>Schools and Teams</h3>
            <span>
              This section is used to edit existing school and team options in the dropdown.
            </span>
            <hr />
            <div className={styles.inputs}>
              <Row className="w-100 py-2">
                <Col md={6}>
                  <span>Identifier</span>
                  <select
                    name="identifier"
                    onChange={handleIdentifierChange}
                    value={formik.values.identifier}
                    onBlur={formik.handleBlur}
                    className={styles.dropdownStyle}
                    disabled // Disable editing of identifier
                  >
                    <option value="">Choose Identifier</option>
                    <option value="HS/Prep">HS/Prep</option>
                    <option value="INTL">INT'L</option>
                    <option value="CBB">CBB</option>
                    <option value="NBA">NBA</option>
                    <option value="G League">G League</option>
                    <option value="Other">Other</option>
                  </select>
                  <span className={styles.error}>
                    {formik.errors.identifier &&
                      formik.touched.identifier &&
                      formik.errors.identifier}
                  </span>
                </Col>
              </Row>
              <Row className="w-100 py-2">
                <Col md={6}>
                  <span>
                    {formik.values.identifier === "INTL"
                      ? "Team Name (Country)"
                      : formik.values.identifier === "CBB" || formik.values.identifier === "NBA" || formik.values.identifier === "G League" || formik.values.identifier === "Other"
                      ? "Team Name"
                      : "Team Name (State)"}
                  </span>
                  <input
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                  />
                  <span className={styles.error}>
                    {formik.errors.name && formik.touched.name && formik.errors.name}
                  </span>
                </Col>
                <Col md={6}>
                  <span>Team Name</span>
                  <input
                    className={styles.abbInput}
                    type="text"
                    name="value"
                    onChange={formik.handleChange}
                    value={formik.values.value}
                    onBlur={formik.handleBlur}
                  />
                  <span className={styles.error}>
                    {formik.errors.value && formik.touched.value && formik.errors.value}
                  </span>
                </Col>
              </Row>

              {(formik.values.identifier === "NBA" || formik.values.identifier === "G League") && (
                <Row className="w-100 py-2">
                  <Col md={6}>
                    <span>Full Team Name</span>
                    <input
                      type="text"
                      name="teamName"
                      onChange={formik.handleChange}
                      value={formik.values.teamName}
                      onBlur={formik.handleBlur}
                    />
                    <span className={styles.error}>
                      {formik.errors.teamName &&
                        formik.touched.teamName &&
                        formik.errors.teamName}
                    </span>
                  </Col>
                  {formik.values.identifier === "G League" && (
                    <Col md={6}>
                      <span>NBA Affiliation</span>
                      <input
                        className={styles.abbInput}
                        type="text"
                        name="nbaAffil"
                        onChange={formik.handleChange}
                        value={formik.values.nbaAffil}
                        onBlur={formik.handleBlur}
                      />
                      <span className={styles.error}>
                        {formik.errors.nbaAffil &&
                          formik.touched.nbaAffil &&
                          formik.errors.nbaAffil}
                      </span>
                    </Col>
                  )}
                </Row>
              )}
              {(formik.values.identifier !== "CBB" && formik.values.identifier !== "NBA" && formik.values.identifier !== "G League") && (
                <Row className="w-100 py-2">
                  <Col md={6}>
                    <span>State</span>
                    <input
                      type="text"
                      name="state"
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      onBlur={formik.handleBlur}
                    />
                    <span className={styles.error}>
                      {formik.errors.state &&
                        formik.touched.state &&
                        formik.errors.state}
                    </span>
                  </Col>
                </Row>
              )}
              <Row className="w-100 py-2">
                <Col md={6}>
                  <span>Coach First Name</span>
                  <input
                    type="text"
                    name="coachFN"
                    onChange={formik.handleChange}
                    value={formik.values.coachFN}
                    onBlur={formik.handleBlur}
                  />
                  <span className={styles.error}>
                    {formik.errors.coachFN &&
                      formik.touched.coachFN &&
                      formik.errors.coachFN}
                  </span>
                </Col>
                <Col md={6}>
                  <span>Coach Last Name</span>
                  <input
                    type="text"
                    name="coachLN"
                    onChange={formik.handleChange}
                    value={formik.values.coachLN}
                    onBlur={formik.handleBlur}
                  />
                  <span className={styles.error}>
                    {formik.errors.coachLN &&
                      formik.touched.coachLN &&
                      formik.errors.coachLN}
                  </span>
                </Col>
              </Row>
              <Row className="w-100 py-2">
                <Col md={6}>
                  <span>Phone</span>
                  <input
                    type="tel"
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    onBlur={formik.handleBlur}
                  />
                  <span className={styles.error}>
                    {formik.errors.phone &&
                      formik.touched.phone &&
                      formik.errors.phone}
                  </span>
                </Col>
                <Col md={6}>
                  <span>Email</span>
                  <input
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                  />
                  <span className={styles.error}>
                    {formik.errors.email &&
                      formik.touched.email &&
                      formik.errors.email}
                  </span>
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.btns}>
            <button onClick={close}>Cancel</button>
            {collection === 'team-suggestion-dropdown' && (
              <button className={styles.btns} type="button" onClick={handleMoveToSchool}>
                Approve
              </button>
            )}
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
