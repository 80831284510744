import React from "react";

import {  Modal } from "react-bootstrap";

import ScoutingOverviewForm from "../../form/scouting-overview.form";

export function EditScoutingOverviewModal({ show, handleClose, scoutingOverviewData }) {
	
	const { id, ...scData } = scoutingOverviewData;


	return (
		<Modal
			show={show}
			onHide={handleClose}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
			<ScoutingOverviewForm closeEditModal={handleClose} formData={scData} scoutingFormID={id} />
			</Modal.Body>
			
		</Modal>
	);
}

