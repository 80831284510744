import { db } from '../../firebase';
import { toast } from "react-toastify";
import moment from "moment";

function log_activity(player, desc, editor) {
    db.collection('activity').add({
        timestamp:moment().unix(),
        player, desc, editor
    })
        .then((docRef) => {
            toast.info("Activity Logged", {
                theme: "colored",
            });
        })
        .catch((docRef) => {
            toast.info("Logging Failed", {
                theme: "colored",
            });
        })
}

export default log_activity;