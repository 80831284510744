import React, { useEffect, useState, useCallback, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import DeleteModal from "../../shared/DeleteModal";
import { MdOutlineCancel } from 'react-icons/md';
import { HiPlusSm } from 'react-icons/hi';
import { db } from "../../../firebase";

// styles
import styles from "../Form.module.scss";

export function NationalTeamExperience({ years, setFieldValue, formikNationalTeamExperience, handleChange, handleBlur }) {
    useEffect(()=>{
        formikNationalTeamExperience.experiences.length === 0 && setFieldValue("nationalTeamExperience.experiences", [{ year: "", experience: "" }]);
    }, [formikNationalTeamExperience.experiences.length])
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [countries, setCountries] = useState([]);

    const getCountries = async () => {
        let response = await db.collection("nationality-dropdown").get();
        let countriesData = response.docs.map(doc => ({ ...doc.data() }));
        
        // Separate United States of America from other countries
        let unitedStates = countriesData.filter(country => country.name === "United States of America");
        let otherCountries = countriesData.filter(country => country.name !== "United States of America");
      
        // Sort other countries alphabetically
        otherCountries.sort((a, b) => a.name.localeCompare(b.name));
      
        // Concatenate United States of America and other countries
        let sortedCountries = unitedStates.concat(otherCountries);
      
        setCountries(sortedCountries);
      }
    useEffect(() => {
        getCountries();
    }, [])

    const AddExperience = (index) => {
        setFieldValue("nationalTeamExperience.experiences",
            [
                ...formikNationalTeamExperience.experiences.slice(0, index + 1),
                { year: "", experience: "" },
                ...formikNationalTeamExperience.experiences.slice(index + 1),
            ]
        )
    }
    const ConfirmDelete = (index) => {
        setFieldValue("nationalTeamExperience.experiences", [
            ...formikNationalTeamExperience.experiences.filter((experience, ind) => ind !== index),
        ])
    }
    const DeleteExperience = (index) => {
        if (formikNationalTeamExperience.experiences.length === 1) return;
        setDeleteIndex(index);
        setDeleteConfirm(true);
    }
    return (
        <>
            <DeleteModal
                show={deleteConfirm}
                handleConfirm={() => { ConfirmDelete(deleteIndex) }}
                handleClose={() => setDeleteConfirm(false)}
            />
            <div className={styles.container}>
                <div className={styles.title}>
                    <div>
                        <h4>National Team Experience</h4>
                        <subtitle>
                            This section is used to add national team experience. Please fill as many sections as possible.
                        </subtitle>
                    </div>
                </div>
                <div className={styles.field}>
                    <Row>
                        <Col md={4}>
                            <p>Country</p>
                            <select className={styles.countryDropdown} name={`nationalTeamExperience.country`} value={formikNationalTeamExperience.country} onChange={handleChange} onBlur={handleBlur} >
                                <option value="" >Select Country</option>
                                {countries.map((c, ind) =>
                                    <option key={ind} value={c.value} >{c.name}</option>
                                )}
                            </select>
                        </Col>
                    </Row>
                </div>
                {formikNationalTeamExperience.experiences.length > 0 &&
                    formikNationalTeamExperience.experiences.map((experience, index) =>
                        <Experience key={index} years={years} DeleteExperience={DeleteExperience} AddExperience={AddExperience} experience={experience} handleChange={handleChange} index={index} handleBlur={handleBlur} />
                    ) 
                }

            </div>
        </>
    )
}

const Experience = ({ years, AddExperience, DeleteExperience, experience, handleChange, handleBlur, index }) => {
    return (
        <Row>
            <Col md={4}>
                <div className={styles.field}>
                    {index === 0 && <p>Year</p>}
                    <select className={styles.yearDropdown} name={`nationalTeamExperience.experiences[${index}].year`} value={experience.year} onChange={handleChange} onBlur={handleBlur} >
                        <option value="">Select Year</option>
                        {years.map((y, ind) =>
                            <option key={ind} >{y}</option>
                        )}
                    </select>
                </div>
            </Col>
            <Col md={8}>
                <div className={styles.field}>
                    {index === 0 && <p>Experience</p>}
                    <div className={styles.btns}>
                        <input type="text" name={`nationalTeamExperience.experiences[${index}].experience`} value={experience.experience} onChange={handleChange} onBlur={handleBlur} />
                        <button className={`${styles.green} ${styles.small}`} onClick={() => { AddExperience(index) }} ><HiPlusSm /></button>
                        <button className={`${styles.red} ${styles.small}`} onClick={() => { DeleteExperience(index) }} ><MdOutlineCancel /></button>
                    </div>

                </div>
            </Col>
        </Row>

    )
}

