import { useState } from "react";
import styles from "./Tickets.module.scss";
import Header from "../header/header";
import { FaSort, FaFilter, FaEye, FaPlus } from "react-icons/fa";
import { MdEdit, MdDelete } from "react-icons/md";
import EditModal from "./editModal";

// Import Firebase and initialize it
import { db } from "../../firebase";
import { useAuth } from "../../Context/AuthContext"; // Assuming you have AuthContext set up

export default function Tickets() {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState(null); // Initialize as null
  const [ticketsTable, setTicketsTable] = useState([]);
  const { currentUser } = useAuth(); // Access the currentUser from useAuth hook

  const addTicket = () => {
    setOpenEditModal(true);
    setSelectedEdit(null); // Set selectedEdit to null when adding a new ticket
  };

  const handleSave = async (newTicket) => {
    try {
      // Add the new ticket to the Firestore collection
      await db.collection("ticket-bugs").add(newTicket);

      // Update the local ticketsTable state
      setTicketsTable([...ticketsTable, newTicket]);
    } catch (error) {
      console.error("Error adding ticket: ", error);
    }

    setOpenEditModal(false);
  };

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.ticketsContainer}>
        <div className={styles.title}>
          <h4>Tickets</h4>
          <button onClick={addTicket}>
            <FaPlus /> Add Ticket
          </button>
        </div>
        <hr />
        <div className={styles.filters}>
          {/* Filters code remains the same */}
        </div>
        <table>
          <thead>
            <tr>
              <th>Ticket Date</th>
              <th>Priority Type</th>
              <th>Report Type</th>
              <th>Title</th>
              <th>Submitted By</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {ticketsTable.map((item, index) => (
              <tr key={index}>
                <td>{item.date}</td>
                <td>{item.priority}</td>
                <td>{item.Report}</td>
                <td>{item.Title}</td>
                <td>{item.submittedBy}</td>
                <td>
                  {/* Action icons code remains the same */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {openEditModal && (
        <EditModal
          setOpen={setOpenEditModal}
          onSave={handleSave}
          item={selectedEdit} // Pass selectedEdit to EditModal
        />
      )}
    </div>
  );
}
