import React, { memo, useEffect, useRef, useState } from "react";
import styles from "./Table.module.scss";
import { FaFilter, FaTimes } from "react-icons/fa";
import Slider from "@mui/material/Slider";
import "./index.css";

const feetInchesToInches = (height) => {
  const [feet, inches] = height.split("'").map(Number);
  return feet * 12 + inches;
};

const inchesToFeetInches = (inches) => {
  const feet = Math.floor(inches / 12);
  const remainder = inches % 12;
  return `${feet}'${remainder.toFixed(0)}`;
};

const FiltersComponent = ({
  options,
  handleChange,
  currentFilter,
  heightRange,
  handleHeightChange,
  weightRange,
  handleWeightChange,
  wingspanRange,
  handleWingspanChange,
  clearFilters,
}) => {
  const [showAllFilters, setShowAllFilters] = useState(false);
  const filterProps = {
    heightRange,
    handleHeightChange,
    weightRange,
    handleWeightChange,
    wingspanRange,
    handleWingspanChange,
    clearFilters,
  };

  const toggleAllFilters = () => {
    setShowAllFilters(!showAllFilters);
  };

  const handleSelectFilter = (event, option) => {
    event.stopPropagation();
    handleChange(option);
  };

  return (
    <div className="d-flex">
      <div
        id="filter-card"
        className={styles.filterCard}
        onClick={(e) => {
          e.stopPropagation();
          toggleAllFilters();
        }}
      >
        <div>Filters</div>
        <div
          style={{
            width: "40px",
            display: "flex",
            justifyContent: "end",
            color: "#212529",
          }}
        >
          <FaFilter size={12} />
        </div>
        {showAllFilters && (
          <AllFiltersPopup
            options={options}
            selectedFilters={currentFilter}
            selectFilter={(event, value) => handleSelectFilter(event, value)}
            toggleAllFilters={(e) => {
              e.stopPropagation();
              toggleAllFilters();
            }}
            filterProps={filterProps}
          />
        )}
      </div>
    </div>
  );
};

const AllFiltersPopup = ({
  options,
  selectedFilters,
  selectFilter,
  toggleAllFilters,
  filterProps,
}) => {
  const popupRef = useRef();
  const {
    heightRange,
    handleHeightChange,
    weightRange,
    handleWeightChange,
    wingspanRange,
    handleWingspanChange,
    clearFilters,
  } = filterProps;
  const handleClickOutside = (event) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      !event.target.closest(`.${styles.filterCard}`)
    ) {
      toggleAllFilters(event);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  return (
    <div ref={popupRef} className={styles.popup}>
      <header>
        <h3>Filters</h3>
        <FaTimes />
      </header>
      <h4>Sort By</h4>
      {options.map((option) => (
        <>
          <div key={option?.name}>
            <label onClick={(event) => selectFilter(event, option)}>
              <input
                type="radio"
                checked={selectedFilters.includes(option.value)}
                readOnly
              />
              <p>{option?.name}</p>
            </label>
          </div>
        </>
      ))}

      <hr />
      <h4>Filters</h4>

      <div className="slider-column">
        <p>Height</p>
        <Slider
          value={heightRange}
          onChange={handleHeightChange}
          min={feetInchesToInches("4'6")}
          max={feetInchesToInches("8'0")}
          step={3} // 0.25 feet in inches
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => inchesToFeetInches(value)}
          color="black"
        />
      </div>

      <div className="slider-column">
        <p>Weight Range</p>
        <Slider
          value={weightRange}
          onChange={handleWeightChange}
          min={100}
          max={300}
          step={1}
          valueLabelDisplay="auto"
          color="black"
        />
      </div>

      <div className="slider-column">
        <p>Wingspan Range</p>
        <Slider
          value={wingspanRange}
          onChange={handleWingspanChange}
          min={feetInchesToInches("5'0")}
          max={feetInchesToInches("8'6")}
          step={3} // 0.25 feet in inches
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => inchesToFeetInches(value)}
          color="black"
        />
      </div>

      <footer>
        <h4
          onClick={(e) => {
            e.stopPropagation();
            clearFilters();
          }}
        >
          Clear All
        </h4>
      </footer>
    </div>
  );
};

export default FiltersComponent;
