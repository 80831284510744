import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.scss";
import moment from "moment";
import { db } from "../../../firebase";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { MdModeEdit } from "react-icons/md";
import { EditScoutingOverviewModal } from "../../PlayerProfileDetail/scouting-overview/EditScoutingOverviewModal"; 
import Header from "../../header/header";

export default function ScoutingOverviewFeed() {
  const [overviewFeed, setOverviewFeed] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc"); // State to track sorting order
  const [selectedEditItem, setSelectedEditItem] = useState(null); // State to handle the selected item for editing
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State to control modal visibility

  // Function to get player title from player-profile collection
  const getPlayerTitle = async (playerProfileId) => {
    try {
      if (!playerProfileId) return "Null"; // If no playerProfileId, return Null

      const playerDoc = await db.collection("player-profile").doc(playerProfileId).get();
      if (playerDoc.exists) {
        return playerDoc.data().Title || "Null"; // Return title or Null if title doesn't exist
      } else {
        return "Null"; // Player profile document doesn't exist
      }
    } catch (error) {
      console.error("Error fetching player title:", error);
      return "Null"; // In case of error, return Null
    }
  };

  const getOverviewFeed = async () => {
    const snapshot = await db.collection("scouting-overview").orderBy("createdAt", "desc").get();
    let feed = [];

    for (const doc of snapshot.docs) {
      let data = doc.data();

      // If title does not exist in scouting-overview, fetch it from player-profile
      if (!data.title && data.playerProfile) {
        const playerTitle = await getPlayerTitle(data.playerProfile);
        data.title = playerTitle;
      }

      // Handle the case where createdAt does not exist
      if (!data.createdAt) {
        data.createdAt = null; // If createdAt is missing, we assign null
      }

      feed.push({ ...data, id: doc.id });
    }

    setOverviewFeed(feed);
  };

  // Function to sort the feed by player profile
  const sortByPlayerProfile = () => {
    const sortedFeed = [...overviewFeed].sort((a, b) => {
      const playerA = a.title || "Null";
      const playerB = b.title || "Null";

      if (sortOrder === "asc") {
        return playerA.localeCompare(playerB);
      } else {
        return playerB.localeCompare(playerA);
      }
    });

    setOverviewFeed(sortedFeed);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); // Toggle sort order
  };

  // Function to handle opening the edit modal with the selected item
  const handleEditClick = (item) => {
    setSelectedEditItem(item); // Set the item to be edited
    setIsEditModalOpen(true); // Open the modal
  };

  // Function to close the edit modal and refresh the feed after editing
  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedEditItem(null);
    getOverviewFeed(); // Refresh the feed after editing
  };

  useEffect(() => {
    getOverviewFeed(); // Get the default sorted feed by date on first load
  }, []);

  return (
    <div className={styles.container}>
       <Header />
      <div className={styles.feed}>
        <p>Scouting Overview Feed</p>
        <div className={styles.tableContainer}>
          <table>
            <thead>
              <tr>
                <th>Date Created</th>
                <th onClick={sortByPlayerProfile} style={{ cursor: "pointer" }}>
                  Player Profile {sortOrder === "asc" ? "↑" : "↓"}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {overviewFeed.map((item, index) => (
                <tr key={index}>
                  <td>
                    {item.createdAt ? moment.unix(item.createdAt).format("l") : "No Date"}
                  </td>
                  <td>
                    {item.playerProfile ? (
                      <Link to={`/player-profile/detail/${item.playerProfile}`}>
                        {item.title || "Null"}
                      </Link>
                    ) : (
                      "No Profile"
                    )}
                  </td>
                  <td>
                    <MdModeEdit onClick={() => handleEditClick(item)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {isEditModalOpen && (
          <EditScoutingOverviewModal
            show={isEditModalOpen}
            handleClose={handleEditModalClose} // Pass close handler
            scoutingOverviewData={selectedEditItem} // Pass selected item to the modal
          />
        )}
      </div>
    </div>
  );
}
