import { useEffect, useRef, useState } from "react";
import styles from "./ChatContainer.module.scss";
import { FiSend } from "react-icons/fi";

import { auth, db } from "../../firebase";
import { collection, addDoc, serverTimestamp, onSnapshot  } from "firebase/firestore";

export default function ChatContainer() {
  //const dummy = useRef();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const lastMessageRef = useRef();

  const scroll = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  const getMessages = async () => {
    const snapshot = await db.collection("group-chat").get();
    const messages = snapshot.docs.map((doc) => doc.data());
    console.log(messages, "messages");
    messages.sort((a, b) => a.createdAt.toDate() - b.createdAt.toDate());
    //setMessages(messages);
    setMessages(messages);
    
  };

  useEffect(() => {
    getMessages();
    scroll();
  }, []);

  useEffect(() => {
    const unsubscribe = db.collection("group-chat").onSnapshot((snapshot) => {
      const newMessages = snapshot.docs.map((doc) => doc.data());
      newMessages.sort((a, b) => a.createdAt.toDate() - b.createdAt.toDate());
      setMessages(newMessages);
      scroll();
    });
  
    return () => unsubscribe(); // Cleanup on unmount
  }, []);


  const renderMessages = () => {
    if (messages.length === 0) {
      return (
        <div className={styles.noMessages}>
          No messages available. Be the first to send one!
        </div>
      );
    }
  
    return messages.map((item, index) => (
      <div
        className={`${
          item.uid === auth.currentUser.uid ? styles.currentUser : ""
        }`}
        key={index}
      >
        <div>
          <div className={styles.avatar}></div>
          <div>
            <p>{item.name}</p>
            <p>{item.text}</p>
            <p>
              {item.createdAt && item.createdAt.toDate()
                ? `${item.createdAt.toDate().toDateString()} ${item.createdAt.toDate().toLocaleTimeString("en-US")}`
                : "Date not available"}
            </p>
          </div>
        </div>
      </div>
    ));
  };


  const sendMessage = async (event) => {
    event.preventDefault();
    if (message.trim() === "") {
      alert("Enter valid message");
      return;
    }
    const { uid, displayName, photoURL, email } = auth.currentUser;
    console.log(auth.currentUser, "sendMessage");
    await addDoc(collection(db, "group-chat"), {
      text: message,
      name: email,
      avatar: photoURL,
      createdAt: serverTimestamp(),
      uid,
    });
    setMessage("");
    getMessages();
    scroll();
  };
  
  return (
    <div className={styles.container}>
      <p>Message Board</p>
      <div className={styles.msgs}>
        {renderMessages()}
        <span ref={lastMessageRef}></span>
      </div>
      <div>
        <input
          type="text"
          placeholder="Write Message Here"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => (e.key === "Enter" ? sendMessage(e) : null)}
        />
        <button onClick={(e) => sendMessage(e)}>
          <FiSend />
        </button>
      </div>
    </div>
  );
}
