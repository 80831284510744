import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { MdCloudUpload } from "react-icons/md";
import ListGroup from 'react-bootstrap/ListGroup';
import { Image } from "primereact/image";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { MdOutlineCancel } from 'react-icons/md';
import { storage } from "../../../firebase";
import styles from './fileDropzone.module.scss'
import moment from "moment";
import { toast } from 'react-toastify';
import {IoMdOpen} from 'react-icons/io'

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;
var uploadTask;
export function FileDropzone(props) {
    const [fileProgress, setFileProgress] = useState(0);
    const [fileID, setFileID] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("notUploaded");
    const [fileAttachments, setFileAttachments] = useState([]);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            "application/pdf": [".pdf"],
            "image/jpeg": [".jpeg", ".jpg"],
        },
        multiple: false,
        noClick: uploadStatus !== "notUploaded" ? true : false,
        noDrag: uploadStatus !== "notUploaded" ? true : false,
        maxSize: 5000000,
        onDrop: (acceptedFiles, fileRejections) => {
            if (fileRejections.length === 0) {
                props.setLoading(true);
                setUploadStatus("uploading");
                setFileAttachments([acceptedFiles[0]])
                uploadFile(acceptedFiles[0]);
            }
            else {
                toast.error(fileRejections[0].errors[0].code.toUpperCase().split('-').join(" "))
            }
        },
    });
    
    const uploadFile = (file) => {
        let filename = `${file.name.split('.').at(0)}-${moment().unix()}.${file.name.split('.').at(-1)}`
        const ref = storage.ref('/reports-attachments').child(filename);
        uploadTask = ref.put(file);
        uploadTask.on('state_changed',
            (snapshot) => {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setFileProgress(progress.toFixed(2));
            },
            (error) => {
                console.log(error.code);
                toast.error(error.code === "storage/canceled" ? "File Upload Cancelled" : "Could not upload file");
                props.setLoading(false);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    toast.success("File Uploaded Successfully");
                    props.formik.setFieldValue("attachment", filename);
                    setFileID(filename);
                    setUploadStatus("uploaded");
                    console.log('File available at', downloadURL);
                    props.setLoading(false);
                });
            }
        );
    };
    const openFile = () =>{
        let win = window.open('');
        win.focus();
        storage.ref('/reports-attachments').child(fileID).getDownloadURL().then((url)=>{
            if(win) win.location.href = url
        })
    }
    const deleteFile = (e) => {
        setUploadStatus('cancel');
        if (uploadStatus === "uploading") {
            if (uploadTask.cancel()) {
                setFileProgress(0);
                setFileID(null);
                setUploadStatus("notUploaded");
            } else {
                setUploadStatus('uploading');
            }
        }
        else if (uploadStatus === "uploaded") {
            storage.ref('/reports-attachments').child(fileID).delete().then(() => {
                setFileProgress(0);
                toast.error("File Deleted Successfully");
                setFileID(null);
                setUploadStatus("notUploaded");
            })
        }
    }
    useEffect(() => {
        setFileID(null);
        setUploadStatus("notUploaded");
        setFileProgress(0);
    }, [props.clearFiles])
    useEffect(()=>{
        if(props.attachment !== undefined && props.attachment !== "")
        {
            setUploadStatus("uploaded");
            setFileID(props.attachment);
            setFileAttachments([{name:props.attachment}])
            setFileProgress(100);
        }
    }, [props.attachment])
    return (
        <>
            <div className="container">
                <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
                    <input {...getInputProps()} />
                    <MdCloudUpload className={styles.icon} />
                    <p>Upload file smaller than 3MB</p>
                </Container>
                <br />
                <ListGroup>
                    {uploadStatus !== "notUploaded" &&
                        fileAttachments.map(file => {
                            const ext = file.name.split(".").at(-1);
                            return (
                                <ListGroup.Item className={`${uploadStatus === "cancel" && styles.halfOpacity}`}>
                                    <div className={styles.fileContainer}>
                                        <Image
                                            src={`/${["pdf", "jpeg", "jpg"].includes(ext) ? ext : "other"}.png`}
                                            height={"32px"}
                                            width={"32px"}
                                            className={styles.fileIcon}
                                        />
                                        &nbsp;
                                        <span>
                                            {file.name.length > 25 ? file.name.slice(0,20)+"..."+file.name.slice(-5): file.name}
                                            {"  "}
                                            <IoMdOpen className={styles.openFileIcon} onClick={openFile} />
                                        
                                        </span>
                                        <div className={styles.cancelContainer} onClick={deleteFile}>
                                            <MdOutlineCancel />
                                        </div>
                                    </div>
                                    <br />
                                    <ProgressBar animated variant="success" style={{ width: "100%" }} now={fileProgress} label={`${fileProgress}%`} />
                                </ListGroup.Item>
                            )
                        }
                        )
                    }
                </ListGroup>
            </div>

        </>
    );
}
