import React from "react";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

export const CustomSpinner = () => {
  return (
    <SpinnerContainer>
      <Spinner animation="border" />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
