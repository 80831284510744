import Layout from "../../../components/layout/layout.js";

import RoleDropdown from "../../../components/componentsPage/rolesDropdown/index.js";

function RoleDropdownPage({ component, ...rest }) {
  return (
    <Layout Component={RoleDropdown} controlsDisabled={true} {...rest} />
  );
}

export default RoleDropdownPage;
