import styles from "./Dashboard.module.scss";
import Header from "../header/header";
import StatsWidgets from "./statsWidgets";
import ActivityFeed from "./activityFeed";
import { useAuth } from "../../Context/AuthContext";
import IsAdmin from "../../utils/isAdmin";
import WatchList from "./watchlist";
import RecentActivity from "./recentActivity";
//import BackgroundOverviewFeed from "./backgroundOverviewFeed";
//import ScoutingOverviewFeed from "./scoutingOverviewFeed";

export default function Dashboard() {
  const { isAdmin, isSuperAdmin } = useAuth();
  return (
    <div className={styles.container}>
      <Header />
      {!isAdmin() && !isSuperAdmin() && (
        <>
          <WatchList />
          <RecentActivity />
        </>
      )}
      {(isAdmin() || isSuperAdmin()) && (
        <>
          <StatsWidgets />
          <ActivityFeed />
          <RecentActivity />    
        </>
      )}
    </div>
  );
}
