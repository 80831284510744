import React from 'react'

import { Accordion, Modal, Button } from 'react-bootstrap'
import styles from '../../PlayerPreview/preview.module.scss'

import {
    BodyTitle,
    BodyColList,
    BodySocial,
    BodyList,
} from "../../PlayerPreview/sections";

const teamHistoryFields = { year: "", school: "", state: "", coachFirstName: "", coachLastName: "", phone: "", email: "" };
const IBOteamHistoryFields = { year: "", team: "", country: "", coachFirstName: "", coachLastName: "", phone: "", email: "" };

export default function Preview({ open, setOpen, bgData }) {
    function handleClose() {
        setOpen(false)
    }
    const recruitmentData = bgData ? {
        NotableVisits: {
            title: "Notable Visits",
            list: bgData.recruitment.notableVisits
        }, NotableOffers: {
            title: "Notable Offers",
            list: bgData.recruitment.notableOffers
        },
        NotableInterests: {
            title: "Notable Interests",
            list: bgData.recruitment.notableInterests
        },
    } : {};
    const buyOutData = bgData && bgData.IBO ? {
        BuyOutInfo: {
            title: "Buy-Out Information",
            list: bgData.buyOutInfo.terms
        }
    } : {};
    const personalData = bgData ? {
        family: {
            title: "Family/Circle",
            list: bgData.personal.familyCircleMembers.map(item => item.member === "" ? "" : `${item.member}: ${item.note}`),
        },
        nugget: {
            title: "Nugget(s)",
            list: bgData.personal.nuggets,
        },
    } : {};
    const socialData = bgData ? {
        Twitter: {
            title: "Twitter",
            social: {
                tag: bgData.socialMedia.twitter.account === "" ? "" : `@${bgData.socialMedia.twitter.account}`,
                followers: `Followers:`,
            },
        },
        Instagram: {
            title: "Instagram",
            social: {
                tag: bgData.socialMedia.instagram.account === "" ? "" : `@${bgData.socialMedia.instagram.account}`,
                followers: `Followers:`,
            },
        },
    } : {};
    return (
        <Modal show={open}
            onHide={handleClose}
            size="xl"
            centered

        >
            <Modal.Header closeButton>
                <Modal.Title>{bgData.IBO ? "INT'L Background Overview" : "Background Overview"}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Accordion defaultActiveKey="0" className={styles.accordionSection}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{bgData.IBO ? "INT'L BACKGROUND OVERVEIW" : "BACKGROUND OVERVEIW"}</Accordion.Header>
                        <Accordion.Body>

                            {bgData.teamHistory.length > 0 &&
                                <>
                                    <BodyTitle title="Playing History" />
                                    <h2 className={styles.grayTitle}>Team History</h2>
                                    <div className={styles.table}>
                                        <table >

                                            <thead>
                                                <tr>
                                                    <th>Year</th>
                                                    <th>Team</th>
                                                    <th>Location</th>
                                                    <th>Coach</th>
                                                    <th>Phone</th>
                                                    <th>Email</th>
                                                </tr>

                                            </thead>
                                            <tbody>

                                                {bgData.teamHistory.map(th =>
                                                    <tr>
                                                        <td>{th.year}</td>
                                                        <td>{th.school || th.team}</td>
                                                        <td>{th.country || th.state}</td>
                                                        <td>{th.coachFirstName} {th.coachLastName}</td>
                                                        <td>{th.phone}</td>
                                                        <td>{th.email}</td>
                                                    </tr>
                                                )}


                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }
                            {/* <BodyDescCol data={getPlayerHistory()} /> */}
                            {bgData.IBO ?
                                <>
                                    {
                                        buyOutData.BuyOutInfo.list.length > 0
                                        &&
                                        <>
                                            <BodyTitle title="Buy-Out Information" />
                                            <BodyList data={buyOutData} cols={2} />
                                        </>
                                    }
                                </>
                                :
                                <>
                                    {
                                        (recruitmentData.NotableOffers.list.length > 0 || recruitmentData.NotableInterests.list.length > 0 || recruitmentData.NotableVisits.list.length > 0)
                                        &&
                                        <>
                                            <BodyTitle title="Recruitment" />
                                            <BodyColList data={recruitmentData} cols={2} />
                                        </>
                                    }
                                </>
                            }
                            {
                                (personalData.family.list.length > 0 || personalData.nugget.list.length > 0)
                                &&
                                <>
                                    <BodyTitle title="Personal" />
                                    <BodyList data={personalData} />
                                </>
                            }

                            {
                                (socialData.Instagram.social.tag !== "" || socialData.Twitter.social.tag !== "")
                                &&
                                <>
                                    <BodyTitle title="Social" />
                                    <BodySocial data={socialData} />
                                </>
                            }
                            {bgData.additionalNotes.length > 0 &&
                                <>
                                    <BodyTitle title="Notes" />
                                    <p>{bgData.additionalNotes}</p>
                                </>
                            }

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Social Media</Accordion.Header>
                        <Accordion.Body>
                            {bgData?.socialMedia.instagram.account !== "" &&
                                <>
                                    <BodyTitle title="Instagram" />
                                    <BodyColList data={{
                                        Roles: {
                                            list: [
                                                `@${bgData?.socialMedia.instagram.account}`,
                                                "Followers: ",
                                                "Account is:",
                                                "DMs are: "
                                            ],
                                        },
                                    }} cols={3} noIcon />
                                    <BodyList data={{
                                        Roles: {
                                            list: bgData?.socialMedia.instagram.nuggets,
                                        },
                                    }} />
                                </>
                            }

                            {bgData?.socialMedia.twitter.account !== "" &&
                                <>
                                    <BodyTitle title="Twitter" />
                                    <BodyColList data={{
                                        Roles: {
                                            list: [
                                                `@${bgData?.socialMedia.twitter.account}`,
                                                "Followers:",
                                                "Account is: ",
                                                bgData?.socialMedia.twitter.dms !== "" && `DMs are: ${bgData?.socialMedia.twitter.dms}`
                                            ],
                                        },
                                    }} cols={3} noIcon />
                                    <BodyList data={{
                                        Roles: {
                                            list: bgData?.socialMedia.twitter.nuggets,
                                        },
                                    }} />
                                </>
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} >Close</Button>
            </Modal.Footer>
        </Modal>
    )
}
