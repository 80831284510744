import React, { useState, useEffect } from "react";
import styles from "./Packet.module.scss";
import Header from "../../header/header";
import { FaEye, FaSortAmountDownAlt } from "react-icons/fa";
import { MdModeEdit, MdRestoreFromTrash, MdVisibility, MdCloudDownload } from "react-icons/md"; // Import view and download icons
import AddModal from "./addModal";
import EditModal from "./editModal";
import { toast } from "react-toastify";
import { db, storage } from "../../../firebase";
import { CustomSpinner } from "../../shared/Spinner";

export default function PacketPage() {
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [packetData, setPacketData] = useState(null);
  const [editModelToggle, setEditModelToggle] = useState(null);

  useEffect(() => {
    getPacketData();
  }, []);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const getPacketData = async () => {
    setLoading(true);
    try {
      const response = await db.collection("pi-packets").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setPacketData(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  const handleDeletePacket = async (id, pdfUrl) => {
    try {
      const confirmation = window.confirm("Are you sure you want to delete this packet?");
      if (confirmation) {
        await db.collection("pi-packets").doc(id).delete();
        const storageRef = storage.refFromURL(pdfUrl);
        await storageRef.delete();
        toast.success("Packet deleted successfully");
        getPacketData();
      }
    } catch (error) {
      console.error("Error deleting packet: ", error);
      toast.error("Error deleting packet");
    }
  };

  if (loading) {
    return <CustomSpinner />;
  }

  if (!packetData && !loading) {
    return <div>Loading Packet data</div>;
  }

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <div className={styles.title}>
          <h4>PI Packets</h4>
          <p>This section is used to add PDF packets</p>
        </div>
        <hr />
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div>
              <span>Total</span>
              <span>{packetData ? packetData.length : "0"}</span>
            </div>
            <button onClick={() => setShowAddModal(true)}>Add Packet</button>
          </div>
          <div className={styles.row}>
            <div>Event Title</div>
            <div>Date</div>
            <div>Actions</div>
          </div>
          {packetData.map((item) => (
            <div key={item.id} className={styles.row}>
              <div>{item.title}</div>
              <div>{formatDate(item.date)}</div>
              <div>
                <a
                  href={item.pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEye style={{ color: "grey", marginRight: "5px" }} />
                </a>
                <MdModeEdit
                  onClick={() => setEditModelToggle(item)}
                  style={{ color: "green", marginRight: "5px" }}
                />
                <MdRestoreFromTrash
                  style={{ color: "red", marginRight: "5px" }} // Add spacing
                  onClick={() => handleDeletePacket(item.id, item.pdfUrl)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {showAddModal && (
        <AddModal setOpen={setShowAddModal} reloadData={getPacketData} />
      )}

      {editModelToggle && (
        <EditModal
          data={editModelToggle}
          setOpen={setEditModelToggle}
          reloadData={getPacketData}
        />
      )}
    </div>
  );
}
