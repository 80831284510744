export const feed = [
  {
    date: "00/00/0000",
    player: "Zion Williamson",
    type: "Player profile Edited",
    editor: "First and L Name",
    time: "00:00:00 AM",
  },
  {
    date: "00/00/0000",
    player: "Zion Williamson",
    type: "Player profile Edited",
    editor: "First and L Name",
    time: "00:00:00 AM",
  },
  {
    date: "00/00/0000",
    player: "Zion Williamson",
    type: "Player profile Edited",
    editor: "First and L Name",
    time: "00:00:00 AM",
  },
  {
    date: "00/00/0000",
    player: "Zion Williamson",
    type: "Player profile Edited",
    editor: "First and L Name",
    time: "00:00:00 AM",
  },
  {
    date: "00/00/0000",
    player: "Zion Williamson",
    type: "Player profile Edited",
    editor: "First and L Name",
    time: "00:00:00 AM",
  },
  {
    date: "00/00/0000",
    player: "Zion Williamson",
    type: "Player profile Edited",
    editor: "First and L Name",
    time: "00:00:00 AM",
  },
  {
    date: "00/00/0000",
    player: "Zion Williamson",
    type: "Player profile Edited",
    editor: "First and L Name",
    time: "00:00:00 AM",
  },
  {
    date: "00/00/0000",
    player: "Zion Williamson",
    type: "Player profile Edited",
    editor: "First and L Name",
    time: "00:00:00 AM",
  },
  {
    date: "00/00/0000",
    player: "Zion Williamson",
    type: "Player profile Edited",
    editor: "First and L Name",
    time: "00:00:00 AM",
  },
];

export const messages = [
  {
    user: "test@gmail.com",
    content: "Hi! how're u doin?",
    date: "05/21/2022 - 10:55 pm",
  },
  {
    user: "test@gmail.com",
    content: "Hi! how're u doin?",
    date: "05/21/2022 - 10:55 pm",
  },
  {
    user: "test@gmail.com",
    content: "Hi! how're u doin?",
    date: "05/21/2022 - 10:55 pm",
  },
  {
    user: "test@gmail.com",
    content: "Hi! how're u doin?",
    date: "05/21/2022 - 10:55 pm",
  },
  {
    user: "anonym@gmail.com",
    content: "Hi! how're u doin?",
    date: "05/21/2022 - 10:55 pm",
  },
  {
    user: "test@gmail.com",
    content: "Hi! how're u doin?",
    date: "05/21/2022 - 10:55 pm",
  },
  {
    user: "john@gmail.com",
    content: "Hi! how're u doin?",
    date: "05/21/2022 - 10:55 pm",
  },
  {
    user: "test@gmail.com",
    content: "Hi! how're u doin?",
    date: "05/21/2022 - 10:55 pm",
  },
  {
    user: "test@gmail.com",
    content: "Hi! how're u doin?",
    date: "05/21/2022 - 10:55 pm",
  },
];
