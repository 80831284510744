import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { useFormik } from "formik";
import { toast } from "react-toastify";

// firebase
import { db } from "../../../firebase";

export default function EditModal({ data, setOpen, reloadData }) {
  const close = () => {
    setOpen(false);
  };
  console.log(data, "data");
  const formik = useFormik({
    initialValues: {
      ...data,
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Required";
      }
      if (!values.value) {
        errors.value = "Required";
      }
      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      db.collection("wingspan-dropdown")
        .doc(data.id)
        .update({
          ...values,
        })
        .then((docRef) => {
          close();
          resetForm();
          toast.success("College Level Item Updated Successfully", {
            theme: "colored",
          });
          reloadData();
        })
        .catch((error) => {
          toast.error("Error Creating College Level Item", {
            theme: "colored",
          });

          console.error("Error adding document: ", error);
        })
        .finally(() => {
          close();
        });
    },
  });
  return (
    <div className={styles.modalContainer}>
      <div onClick={close} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={close}>
          <MdClose />
        </button>
        <form onSubmit={formik.handleSubmit} className="w-100">
          <div className={styles.content}>
            <h3>Wingspan</h3>
            <span>
              This section is used to edit existing wingspan option to the
              dropdown.
            </span>
            <hr />
            <div className={styles.inputs}>
              <span>Wingspan</span>
              <input
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <span className={styles.error}>
                {formik.errors.name &&
                  formik.touched.name &&
                  formik.errors.name}
              </span>

              <span>Abbreviation</span>
              <input
                className={styles.abbInput}
                type="text"
                name="value"
                onChange={formik.handleChange}
                value={formik.values.value}
              />
              <span className={styles.error}>
                {formik.errors.value &&
                  formik.touched.value &&
                  formik.errors.value}
              </span>
            </div>
          </div>
          <div className={styles.btns}>
            <button onClick={close}>Cancel</button>
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}
