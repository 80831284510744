import React from "react";
import {  Modal } from "react-bootstrap";

import BackgroundOverviewForm from "../../form/background.form";


export function EditBgOverviewModal({ show, handleClose, bgOverviewData }) {
	
	const { id, ...bgData } = bgOverviewData;


	return (
		<Modal
			show={show}
			onHide={handleClose}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
			<BackgroundOverviewForm closeEditModal={handleClose}  formData={bgData} bgFormID={id} />
			</Modal.Body>
			
		</Modal>
	);
}

