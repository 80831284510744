import React, { useEffect, useState, useCallback, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import DeleteModal from "../../shared/DeleteModal";
import { MdOutlineCancel } from 'react-icons/md';
import { HiPlusSm } from 'react-icons/hi';

// styles
import styles from "../Form.module.scss";

export function BuyOutInfo({ setFieldValue, buyOutInfo, handleChange, handleBlur }) {
    useEffect(()=>{
        buyOutInfo.terms.length === 0 && setFieldValue("buyOutInfo.terms", [""])
    }, [buyOutInfo.terms.length])
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const Add = (index) => {
        setFieldValue("buyOutInfo.terms",
            [
                ...buyOutInfo.terms.slice(0, index + 1),
                "",
                ...buyOutInfo.terms.slice(index + 1),
            ]
        );
    }
    const ConfirmDelete = (index) => {
        setFieldValue("buyOutInfo.terms", [
            ...buyOutInfo.terms.filter((_, ind) => ind !== index),
        ])
    }
    const Delete = (index) => {
        if (buyOutInfo.terms.length === 1) return;
        setDeleteConfirm(true);
        setDeleteIndex(index);

    }
    return (
        <>
            <DeleteModal
                show={deleteConfirm}
                handleConfirm={() => { ConfirmDelete(deleteIndex) }}
                handleClose={() => setDeleteConfirm(false)}
            />
            <div className={styles.container}>
                <div className={styles.title}>
                    <div>
                        <h4>Buy-Out Information</h4>
                        <subtitle>
                            This section is used to add any buy out information. Please fill as many sections as possible.
                        </subtitle>
                    </div>
                </div>
                <div className={styles.field}>
                    <p>Terms</p>
                    {
                        buyOutInfo.terms.length > 0 &&
                            buyOutInfo.terms.map((term, index) =>
                                <div className={styles.btns}>
                                    <input key={index} type="text" name={`buyOutInfo.terms[${index}]`} value={term} onChange={handleChange} onBlur={handleBlur} />
                                    <button key={`b1-${index}`} className={`${styles.green} ${styles.small}`} onClick={() => { Add(index) }} ><HiPlusSm /></button>
                                    <button key={`b2-${index}`} className={`${styles.red} ${styles.small}`} onClick={() => { Delete(index) }} ><MdOutlineCancel /></button>
                                </div>
                            ) 
                    }
                </div>

            </div>
        </>
    )
}
