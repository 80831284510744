import { useState, useEffect } from "react";
import styles from "./Login.module.scss";
import { FaUserAlt, FaLock, FaSpinner } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import PI_Bulb from "../../Assets/images/PI_Bulb.svg";

// Auth Context
import { useAuth } from "../../Context/AuthContext";
import { db } from "../../firebase";

const isMobile = () => {
  const userAgent = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );
};

const MobileMessage = () => (
  <div className={styles.content}>
    <img
      src={PI_Bulb}
      style={{ width: "150px", paddingBottom: "60px" }}
      alt="PI Logo"
      className={styles.logo}
    />

    <div style={{ textAlign: "center", padding: "20px", color: "white" }}>
      Welcome to the new Pro Insight Database.
      <br></br>
      <br></br>
      Mobile version is still in development and will be available soon.
      <br></br>
      <br></br>
      In the meantime, please visit our site on your desktop.
    </div>
  </div>
);

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useHistory();
  const { login, getUserInfoData } = useAuth();
  const [showMobileMessage, setShowMobileMessage] = useState(false);

  useEffect(() => {
    if (isMobile()) {
      setShowMobileMessage(true);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await login(values.email, values.password);

        setTimeout(() => {
          // update last login time in db
          db.collection("Accounts").doc(getUserInfoData().uid).update(
            {
              lastLogin: new Date(),
            },
            {
              merge: true,
            }
          );
        }, 1000);

        navigate.replace("/");
      } catch (error) {
        console.log("Failed to login", error);
      }

      setLoading(false);
    },
  });

  return (
    <div className={styles.container}>
      {showMobileMessage && <MobileMessage />}
      {!showMobileMessage && (
        <div className={styles.content}>
          <img
            src={PI_Bulb}
            style={{ width: "150px", paddingBottom: "60px" }}
            alt="PI Logo"
            className={styles.logo}
          />
          <h2 style={{ textAlign: "center" }}>LOGIN</h2>
          <div className={styles.inputContainer}>
            <FaUserAlt />
            <input
              type="email"
              placeholder="E-Mail"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </div>
          <div className={styles.inputContainer}>
            <FaLock />
            <input
              placeholder="Password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
          </div>
          <div className={styles.btns}>
            <button
              type="submit"
              onClick={formik.handleSubmit}
              disabled={loading}
            >
              {loading ? <FaSpinner className={styles.spinner} /> : "Log In"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
