import React from "react";
import styles from './statsTable.module.scss'

export const StatsTable = ({ formik }) => {

  if (!formik) {
    console.error('Formik instance is undefined.');
    return null;
  }

  const { handleChange, values } = formik;
  return (
    <div className="table-responsive">
      <table className={`table-sm ${styles.table}`}>
        <thead className="text-center">
          <tr>
            <th className={styles.team}>Team</th>
            <th >vs</th>
            <th className={styles.team}>Opponent</th>
            <th className={styles.doubleInputs}>Score</th>
            <th>MIN</th>
            <th className={styles.doubleInputs}>FG</th>
            <th className={styles.doubleInputs}>3FG</th>
            <th className={styles.doubleInputs}>FT</th>
            <th>OR</th>
            <th>TR</th>
            <th>AST</th>
            <th>TO</th>
            <th>BLK</th>
            <th>STL</th>
            <th>PF</th>
            <th>PTS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input type="text" className="border"  style={{width : '160px'}}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.team}
                name="typeSpecificFields.stats.team"
              />
            </td>
            <td>vs</td>
            <td>
              <input type="text" className="border" style={{width : '160px'}}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.opponent}
                name="typeSpecificFields.stats.opponent"
              />
            </td>
            <td className={styles.doubleInputs}>
              <input type="text" className="border"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.score[0]}
                name="typeSpecificFields.stats.score[0]"
              />
              <span>-</span>
              <input type="text" className="border"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.score[1]}
                name="typeSpecificFields.stats.score[1]"
              />
            </td>
            <td>
              <input type="text" className="border" style={{width : '40px'}}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.min}
                name="typeSpecificFields.stats.min"
              />
            </td>
            <td className={styles.doubleInputs}>
              <input type="text" className="border"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.fg[0]}
                name="typeSpecificFields.stats.fg[0]"
              />
              <span>-</span>
              <input type="text" className="border"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.fg[1]}
                name="typeSpecificFields.stats.fg[1]"
              />
            </td>
            <td className={styles.doubleInputs}>
              <input type="text" className="border"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats["3fg"][0]}
                name="typeSpecificFields.stats['3fg'][0]"
              />
              <span>-</span>
              <input type="text" className="border"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats["3fg"][1]}
                name="typeSpecificFields.stats['3fg'][1]"
              />
            </td>
            <td className={styles.doubleInputs}>
              <input type="text" className="border"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.ft[0]}
                name="typeSpecificFields.stats.ft[0]"
              />
              <span>-</span>
              <input type="text" className="border"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.ft[1]}
                name="typeSpecificFields.stats.ft[1]"
              />
            </td>
            <td>
              <input type="text" className="border" style={{width : '40px'}}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.or}
                name="typeSpecificFields.stats.or"
              />
            </td>
            <td>
              <input type="text" className="border" style={{width : '40px'}}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.tr}
                name="typeSpecificFields.stats.tr"
              />
            </td>
            <td>
              <input type="text" className="border" style={{width : '40px'}}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.ast}
                name="typeSpecificFields.stats.ast"
              />
            </td>
            <td>
              <input type="text" className="border" style={{width : '40px'}}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.to}
                name="typeSpecificFields.stats.to"
              />
            </td>
            <td>
              <input type="text" className="border" style={{width : '40px'}}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.blk}
                name="typeSpecificFields.stats.blk"
              />
            </td>
            <td>
              <input type="text" className="border" style={{width : '40px'}}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.stl}
                name="typeSpecificFields.stats.stl"
              />
            </td>
            <td>
              <input type="text" className="border" style={{width : '40px'}}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.pf}
                name="typeSpecificFields.stats.pf"
              />
            </td>
            <td>
              <input type="text" className="border" style={{width : '40px'}}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.typeSpecificFields.stats.pts}
                name="typeSpecificFields.stats.pts"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}