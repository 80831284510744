import React from "react";
import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { useFormik } from "formik";
import { toast } from "react-toastify";

// firebase
import { db, storage } from "../../../firebase";

export default function EditModal({ data, setOpen, reloadData }) {
  const close = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      title: data.title,
      date: data.date,
      file: null,
    },
    validate: (values) => {
      const errors = {};
      if (!values.title) {
        errors.title = "Required";
      }
      if (!values.date) {
        errors.date = "Required";
      }
      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      const { title, date, file } = values;

      try {
        let downloadURL = data.pdfUrl;

        // Check if a new file is provided
        if (file) {
          // Upload the new file to Firebase Storage
          const storageRef = storage.ref();
          const fileRef = storageRef.child(file.name);
          await fileRef.put(file);

          // Get the download URL of the new file
          downloadURL = await fileRef.getDownloadURL();
        }

        // Update document in Firestore
        await db.collection("pi-packets").doc(data.id).update({
          title,
          date,
          pdfUrl: downloadURL, // Update the pdfUrl with the new downloadURL
        });

        toast.success("Packet updated successfully");
        reloadData();
        close();
      } catch (error) {
        console.error("Error updating packet: ", error);
        toast.error("Error updating packet");
      }
    },
  });

  return (
    <div className={styles.modalContainer}>
      <div onClick={close} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={close}>
          <MdClose />
        </button>
        <form onSubmit={formik.handleSubmit} className="w-100">
          <div className={styles.content}>
            <h3>Edit Packet</h3>
            <hr />
            <div className={styles.inputs}>
              <span>Title</span>
              <input
                type="text"
                name="title"
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              <span className={styles.error}>
                {formik.errors.title &&
                  formik.touched.title &&
                  formik.errors.title}
              </span>

              <span>Date</span>
              <input
                type="date"
                name="date"
                onChange={formik.handleChange}
                value={formik.values.date}
              />
              <span className={styles.error}>
                {formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date}
              </span>

              {/* Allow users to upload a new file */}
              <span>PDF - Uploading a new pdf will replace the previous document</span>
              <input
                type="file"
                name="file"
                onChange={(event) => {
                  formik.setFieldValue("file", event.currentTarget.files[0]);
                }}
              />
            </div>
          </div>
          <div className={styles.btns}>
            <button onClick={close}>Cancel</button>
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}
