import React, { useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import moment from "moment";

import defaultImage from "../../Assets/images/piLogo.png";

// firebase
import { db } from "../../firebase";

// styles
import styles from "./detail.module.scss";

// dummy data
import { feed } from "../dashboard/dummyData";

// icons
import { FaSearch } from "react-icons/fa";
import {
  MdModeEdit,
  MdRemoveRedEye,
  MdRestoreFromTrash,
  MdCloudUpload,
  MdVideocam,
} from "react-icons/md";
import { Image, Row, Col } from "react-bootstrap";
import { EditProfileModal } from "./EditProfileModal";
import { PlayerReports } from "./reports/PlayerReports";
import Header from "../header/header";
import { useParams, useHistory } from "react-router-dom";
import { CustomSpinner } from "../shared/Spinner";
import { BackgroundOverview } from "./background-overview/BackgroundOverview";
import { ScoutingOverview } from "./scouting-overview/ScoutingOverview";
import { toast } from "react-toastify";
import log_activity from "../activity/logger";
import DeleteModal from "../shared/DeleteModal";
import { notificationService } from "../../Services/notification-service";

export const PlayerProfileDetail = () => {
  const history = useHistory();
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [playerProfileData, setPlayerProfileData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState(false);
  const [backgroundOverviewLoaded, setBackgroundOverviewLoaded] = useState(false); // New state


  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getPlayerProfile();
    }
    return () => {};
  }, [id]);
  
  useEffect(() => {
    // Check if background overview data exists
    const hasBackgroundOverview = Boolean(playerProfileData && playerProfileData.backgroundOverview);
    setBackgroundOverviewLoaded(hasBackgroundOverview);
  }, [playerProfileData]);
  

  const getPlayerProfile = async () => {
    setLoading(true);
    try {
      const response = await db.collection("player-profile").doc(id).get();
      const data = response.data();
      //console.log("Player Profile Data:", data); // Log data to check background overview information
      setPlayerProfileData(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  const handleDelete = () => {
    db.collection("player-profile")
      .doc(id)
      .delete()
      .then(async () => {
        toast.error("Player Profile Deleted Successfully", {
          theme: "colored",
        });
        const watchlistDoc = await db.collection("watchlist").doc(id).get();
        if (watchlistDoc.exists) {
          const receipients = watchlistDoc.data().subscribers;
          const playerName =
            (playerProfileData.FirstName ?? "").trim() +
            " " +
            (playerProfileData.LastName ?? "").trim();
          notificationService.send(
            receipients,
            `${playerName}'s profile was deleted.`
          );
        }
        log_activity(
          `${playerProfileData.FirstName} ${playerProfileData.LastName}`,
          "Player Profile was Deleted"
        );
        history.push("/player-profile");
      })
      .catch((error) => {
        toast.error("Error Deleting Player Profile", {
          theme: "colored",
        });
      });
  };

  if (loading) {
    return <CustomSpinner />;
  }

  if (playerProfileData === null && loading === false) {
    return <div>Error Loading data</div>;
  }

  return (
    <>
      <DeleteModal
        handleClose={() => {
          setSelectedDelete(false);
        }}
        show={selectedDelete}
        handleConfirm={() => {
          handleDelete();
        }}
      />
      <div className={styles.container}>
        <Header />
        {/* content */}
        <div className={styles.title}>
          <h2>Player Profile</h2>
          <hr />
        </div>
        {/* Intro */}
        <Row className="">
          <Col md={9}>
            <div className={styles.profileInto}>
              <Row>
                <Col
                  md={2}
                  className="d-flex justify-content-center align-items-center"
                >
                  <Image
                    width="100px"
                    height="100px"
                    style={{
                      objectFit: "cover",
                    }}
                    roundedCircle
                    src={playerProfileData.Headshot || defaultImage} 
                  />
                </Col>
                <Col md={10}>
                  <div className="d-flex justify-content-between">
                    <div className={styles.tablePersonInfo}>
                      <h4>
                        {playerProfileData.FirstName}{" "}
                        {playerProfileData.LastName}
                      </h4>
                      <h6>{playerProfileData.Team}</h6>
                      <p>
                        {playerProfileData.hometown_City}
                        {playerProfileData.hometown_City && playerProfileData.hometown_State_Country ? ", " : ""}
                        {playerProfileData.hometown_State_Country}
                      </p>
                    </div>
                    <div>
                      <MdRemoveRedEye
                        onClick={() => {
                          history.push(`/player-profile/preview/${id}`);
                        }}
                      />
                      <MdModeEdit
                        onClick={() => {
                          setOpenEditProfile(true);
                        }}
                      />
                      <MdRestoreFromTrash
                        onClick={() => {
                          setSelectedDelete(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.tableData}>
                    <div>
                      <p>Nationalality</p>
                      <h4>{playerProfileData.Country ? playerProfileData.Country : "--"}</h4>
                    </div>
                    <div>
                      <p>Position</p>
                      <h4>{playerProfileData.Position ? playerProfileData.Position : "--"}</h4>
                    </div>
                    <div>
                      <p>Year</p>
                      <h4>{playerProfileData.Year ? playerProfileData.Year : "--" }</h4>
                    </div>
                    <div>
                      <p>DOB</p>
                      <h4>{playerProfileData.DOB ? moment(playerProfileData.DOB).format("l") : '--'}</h4>
                    </div>
                    <div>
                      <p>Age</p>
                      <h4>
                        {playerProfileData.Age !== 0.0 && !isNaN(parseFloat(playerProfileData.Age))
                          ? parseFloat(playerProfileData.Age).toFixed(1)
                          : '--'}
                      </h4>
                    </div>
                    <div>
                      <p>AAU Team</p>
                      <h4 style={{ textAlign: 'center' }}>{playerProfileData.aauTeam ? playerProfileData.aauTeam : "--" }</h4>
                    </div>
                    <div>
                      <p>Affiliation</p>
                      <h4>{playerProfileData.aauAffil ? playerProfileData.aauAffil : "--"}</h4>
                    </div>
                    <div>
                      <p>Status</p>
                      <h4 style={{ textAlign: 'center' }}>
                      {playerProfileData.Status !== "Undecided" ? `${playerProfileData.Status}: ${playerProfileData.committedTeam}` : playerProfileData.Status}
                      </h4>
                    </div>
                    <div className={styles.tableDataDark}>
                      <p>P.I. Score</p>
                      <h4 style={{ textAlign: 'center' }}>Coming Soon</h4>
                      {/*<h4>{playerProfileData.Piscore}</h4>*/}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={3}>
            <div className={styles.profileComplete}>
              <h2>Profile Completion</h2>
              <div className={styles.tableData}>
                {[
                  {
                    title: "Profile",
                    desc: "--",
                  },
                  {
                    title: "BO",
                    desc: "--",
                  },
                  {
                    title: "SO",
                    desc: "--",
                  },
                ].map((el, ind) => (
                  <div key={ind}>
                    <p>{el.title}</p>
                    <h4>{el.desc}</h4>
                  </div>
                ))}{" "}
              </div>
              <div className={styles.tableData}>
                {[
                  {
                    title: "Reports",
                    desc: "--",
                  },
                  {
                    title: "Stats",
                    desc: "--",
                  },
                  {
                    title: "Images",
                    desc: "--",
                  },
                ].map((el, ind) => (
                  <div key={ind}>
                    <p>{el.title}</p>
                    <h4>{el.desc}</h4>
                  </div>
                ))}{" "}
              </div>
            </div>
          </Col>
        </Row>
        {/* Reports */}
        <PlayerReports playerID={id} playerProfileData={playerProfileData} />
        {/* end */}
        {/* Background Overview  */}
        <BackgroundOverview
          playerID={id}
          playerName={`${playerProfileData.FirstName} ${playerProfileData.LastName}`}
        />
        {/* end */}
        {/* end */}
        {/* Scouting Overview */}
        <ScoutingOverview
          playerID={id}
          playerName={`${playerProfileData.FirstName} ${playerProfileData.LastName}`}
        />
        {/* Stats
        <div
          className={styles.feed}
          style={{
            marginTop: "20px",
          }}
        >
          <h2>Stats</h2>
          <hr />
          <div className="d-flex justify-content-end">
            <div className={styles.filterCard}>
              <div>Sort By</div>
              <div
                style={{
                  width: "40px",
                }}
              >
                <FaSearch />
              </div>
            </div>
          </div>
          <div className={styles.tableContainer}>
            <table>
              <thead>
                <tr>
                  <th>Created Date</th>
                  <th>Report Type</th>
                  <th>Note Date</th>
                  <th>Preview</th>
                  <th>Editor</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {feed.map((item, index) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td>{item.player}</td>
                    <td>{item.type}</td>
                    <td>{item.editor}</td>
                    <td>{item.time}</td>
                    <td>
                      <div className={styles.actionIcon}>
                        <MdRemoveRedEye />
                        <MdModeEdit
                          style={{
                            color: "green",
                          }}
                        />
                        <MdRestoreFromTrash
                          style={{
                            color: "red",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        */}
        {/* gallery 
        <Row>
          <Col md={6}>
            <div className={styles.uploadSection}>
              <h2>Gallery</h2>
              <hr />
              <label htmlFor="caption">Caption</label>
              <br />
              <input type="text" id="caption" name="caption" />
              <StyledDropzone />
            </div>
          </Col>
          <Col md={6}>
            <div className={styles.uploadSection}>
              <h2>Video</h2>
              <hr />
              <label htmlFor="caption">Caption</label>
              <br />
              <input type="text" id="caption" name="caption" />
              <StyledDropzone video />
            </div>
          </Col>
        </Row>
        {/* Edit Profile Section */}
        {playerProfileData && (
          <EditProfileModal
            show={openEditProfile}
            getPlayerProfile={getPlayerProfile}
            docID={id}
            handleClose={() => setOpenEditProfile(false)}
            item={playerProfileData}
          />
        )}{" "}
      </div>
    </>
  );
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  marginTop: "15px",
  width: "100%",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function StyledDropzone(props) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone();

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {props.video ? (
          <MdVideocam style={{ fontSize: "24px" }} />
        ) : (
          <MdCloudUpload style={{ fontSize: "24px" }} />
        )}
        <p>Upload file smaller than 3MB</p>
      </div>
      {acceptedFiles.length > 0 && (
        <aside>
          <h6 className="mt-2">Files</h6>
          <ul>{files}</ul>
        </aside>
      )}{" "}
    </>
  );
}

const calculateAge = (birthday) => {
  return (new Date() - new Date(birthday)) / 31557600000;

  // var ageDifMs = Date.now() - new Date(birthday).getTime();
  // var ageDate = new Date(ageDifMs);
  // return Math.abs(ageDate.getUTCFullYear() - 1970);
};
