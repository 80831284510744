import { useState, useEffect } from "react";
import styles from "./aauTeamsDropdown.module.scss";
import Header from "../../header/header";
import { FaSortAmountDownAlt } from "react-icons/fa";
import AddModal from "./addModal";
import EditModal from "./editModal";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import { CustomSpinner } from "../../shared/Spinner";
import { MdModeEdit, MdRemoveRedEye, MdRestoreFromTrash } from "react-icons/md";
import DeleteModal from "../../shared/DeleteModal";
import DeleteCollectionModal from "../../shared/DeleteCollection";

export default function AauTeamsDropdown() {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [AAUTeamData, setAAUTeamsData] = useState(null);
  const [aauSuggestedTeams, setAAUSuggestedTeams] = useState([]);
  const [selectedDeleteSuggestedAAUTeam, setSelectedDeleteSuggestedAAUTeam] = useState(null);
  const [editModelToggle, setEditModelToggle] = useState(false);
  const [selectedDeleteAAUTeams, setSelectedDeleteAAUTeams] = useState(false);
  const [showDeleteCollectionModal, setShowDeleteCollectionModal] = useState(false);
  const [currentCollection, setCurrentCollection] = useState(null);

  useEffect(() => {
    getAAUTeamsData();
    getAAUSuggestedTeams();
    return () => {};
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    db.collection("AAUTeam-dropdown")
      .doc(id)
      .delete()
      .then(() => {
        setLoading(false);
        toast.error("AAU Team Item Deleted Successfully", {
          theme: "colored",
        });
        getAAUTeamsData();
      })
      .catch((error) => {
        toast.error("Error Deleting AAU Team Item", {
          theme: "colored",
        });
      });
  };

  const handleDeleteTeam = (id) => {
    setLoading(true);
    db.collection("aau-suggestion")
      .doc(id)
      .delete()
      .then(() => {
        setLoading(false);
        toast.error("AAU Team Suggestion Deleted Successfully", {
          theme: "colored",
        });
        getAAUSuggestedTeams();
      })
      .catch((error) => {
        toast.error("Error Deleting AAU Team Suggestion", {
          theme: "colored",
        });
      });
  };

  const deleteCollection = () => {
    setLoading(true);
    db.collection("AAUTeam-dropdown")
      .get()
      .then((querySnapshot) => {
        const batch = db.batch();
        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
        return batch.commit();
      })
      .then(() => {
        setLoading(false);
        toast.success("AAU Teams Collection Deleted Successfully", {
          theme: "colored",
        });
        setAAUTeamsData([]);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error Deleting AAU Teams Collection", {
          theme: "colored",
        });
      });
  };

  const getAAUTeamsData = async () => {
    setLoading(true);
    try {
      const response = await db.collection("AAUTeam-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setAAUTeamsData(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  const getAAUSuggestedTeams = async () => {
    setLoading(true);
    try {
      const response = await db.collection("aau-suggestion").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      setAAUSuggestedTeams(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  const importData = (file) => {
    setLoading(true);
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileData = event.target.result;
      const lines = fileData.split(/\r\n|\n/);
      const headers = lines[0].split(",");
      const importedData = [];

      for (let i = 1; i < lines.length; i++) {
        const row = lines[i].split(",");
        if (row.length === headers.length) {
          const rowData = {};
          for (let j = 0; j < headers.length; j++) {
            rowData[headers[j]] = row[j];
          }
          importedData.push(rowData);
        }
      }

      try {
        const batch = db.batch();
        importedData.forEach((data) => {
          const docRef = db
            .collection("AAUTeam-dropdown")
            .doc(data.id || db.collection("AAUTeam-dropdown").doc().id);
          batch.set(docRef, data, { merge: true });
        });
        await batch.commit();
        setLoading(false);
        toast.success("Data Imported Successfully", {
          theme: "colored",
        });
        getAAUTeamsData();
      } catch (error) {
        setLoading(false);
        toast.error("Error Importing Data", {
          theme: "colored",
        });
      }
    };

    reader.readAsText(file);
  };

  const exportData = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      "id,name,aauAffiliation\n" +
      AAUTeamData.map((item) => `${item.id || ""},${item.name},${item.aauAffilation}`).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "aauTeams.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <CustomSpinner />;
  }

  if (AAUTeamData === null && !loading) {
    return <div>Error loading AAUTeam data</div>;
  }

  const sortedAAUTeamData = [...AAUTeamData].sort((a, b) =>
  a.name.localeCompare(b.name)
  );

  const sortedAAUSuggestedTeams = [...aauSuggestedTeams].sort((a, b) =>
    a.name.localeCompare(b.name)
    );
  

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <div className={styles.title}>
          <h4>AAU Team</h4>
          <p>This section is used to edit AAU Teams dropdown menu</p>
        </div>
        <hr />


        {sortedAAUSuggestedTeams.length > 0 && (
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <div>
                <span>Total</span>
                <span>{aauSuggestedTeams ? aauSuggestedTeams.length : "0"}</span>
              </div>
            </div>
            <div className={styles.row}>
              <div>AAU Teams</div>
              <div>Affiliation</div>
              <div>CoachFN</div>
              <div>CoachLN</div>
              <div>Phone</div>
              <div>Email</div>
              <div>Actions</div>
            </div>
            {sortedAAUSuggestedTeams.map((item) => (
              <div key={item.id} className={styles.row}>
                <div>{item.name}</div>
                <div>{item.aauAffilation}</div>
                <div>{item.aauCoach}</div>
                <div>{item.aauCoachLast}</div>
                <div>{item.aauCoachInfo}</div>
                <div>{item.aauCoachEmail}</div>
                <div>
                  <MdModeEdit
                    onClick={() => {
                      setEditModelToggle(item);
                      setCurrentCollection("aau-suggestion");
                    }}
                  />
                  <MdRestoreFromTrash
                    onClick={() => {
                      setSelectedDeleteSuggestedAAUTeam(item.id);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}


        
        




        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div>
              <span>Total</span>
              <span>{AAUTeamData ? AAUTeamData.length : "0"}</span>
            </div>
            <div className={styles.importButton}>
              <input
                type="file"
                id="csvFile"
                accept=".csv"
                onChange={(e) => importData(e.target.files[0])}
                style={{ display: "none" }}
              />
              <label htmlFor="csvFile" className={styles.importButtonLabel}>
                Import CSV File
              </label>
            </div>
            <div>
              <button onClick={exportData} className={styles.exportButton}>
                Export Data
              </button>
            </div>
            <button
              onClick={() => {
                setShowDeleteCollectionModal(true);
              }}
              className={styles.deleteButton}
            >
              Delete Collection
            </button>
            <button
              onClick={() => {
                setOpenEditModal(true);
              }}
            >
              Add Item
            </button>
          </div>
          <div className={styles.row}>
            <div>
              AAU Teams
            </div>
            <div>
              Affiliation
            </div>
            <div>
              CoachFN
            </div>
            <div>
              CoachLN
            </div>
            <div>
              Phone
            </div>
            <div>
              Email
            </div>
            <div>Actions</div>
          </div>
          {sortedAAUTeamData.map((item) => (
            <div key={item.id} className={styles.row}>
              <div>{item.name}</div>
              <div>{item.aauAffilation}</div>
              <div>{item.aauCoach}</div>
              <div>{item.aauCoachLast}</div>
              <div>{item.aauCoachInfo}</div>
              <div>{item.aauCoachEmail}</div>
              
              <div>
                <MdModeEdit
                  onClick={() => {
                    setEditModelToggle(item);
                    setCurrentCollection("AAUTeam-dropdown");
                  }}
                />
                <MdRestoreFromTrash
                  onClick={() => {
                    setSelectedDeleteAAUTeams(item.id);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <DeleteCollectionModal
        handleClose={() => {
          setShowDeleteCollectionModal(false);
        }}
        show={showDeleteCollectionModal}
        handleConfirm={deleteCollection}
        collectionName="AAUTeam-dropdown"
      />

      <DeleteModal
        handleClose={() => {
          setSelectedDeleteAAUTeams(false);
        }}
        show={selectedDeleteAAUTeams}
        handleConfirm={() => {
          handleDelete(selectedDeleteAAUTeams);
        }}
        title="Delete Item"
        message="Are you sure you want to delete the item? This action cannot be undone."
      />


      <DeleteModal
        handleClose={() => {
          setSelectedDeleteSuggestedAAUTeam(false);
        }}
        show={selectedDeleteSuggestedAAUTeam}
        handleConfirm={() => {
          handleDeleteTeam(selectedDeleteSuggestedAAUTeam);
        }}
        title="Delete Suggeted Item"
        message="Are you sure you want to delete the item? This action cannot be undone."
      />



      {openEditModal && (
        <AddModal  open={openEditModal} setOpen={setOpenEditModal} reloadData={getAAUTeamsData} />
      )}

      {editModelToggle && (
        <EditModal
          data={editModelToggle}
          setOpen={setEditModelToggle}
          reloadData={() => {
            getAAUTeamsData();
            getAAUSuggestedTeams();
          }}
          collection={currentCollection} // Use the state variable
        />
      )}
    </div>
  );
}
