import React, { useState, useEffect } from "react";
import styles from "./Header.module.scss";
import previewImage from "../../Assets/images/piLogo.png";

// firebase
import { db } from "../../firebase";

// components
import { Image, Row, Col, Collapse } from "react-bootstrap";
import moment from "moment";

// history
import { useHistory } from 'react-router-dom'; 

// icons
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";

export default function SearchSection({ display }) {
  const { isAdmin, isSuperAdmin, getUserInfoData } = useAuth();

  const [playerProfileData, setPlayerProfileData] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [openList, setopenList] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getPlayerProfiles();

    return () => {};
  }, []);

  const getPlayerProfiles = async () => {
    try {
      const response = await db.collection("player-profile").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      // console.log(data, "response");

      let filteredData;
      if (isAdmin() || isSuperAdmin()) {
        filteredData = data;
      } else {
        filteredData = data.filter((el) => {
          if (
            el["viewing_privileges"] &&
            el["viewing_privileges"].includes(getUserInfoData().memberType)
          ) {
            return el;
          }
        });
      }

      setPlayerProfileData(filteredData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleSearch = (value) => {
    const filteredData = playerProfileData.filter((data) => {
      return data.Title.toLowerCase().includes(value.toLowerCase());
    });
    console.log(filteredData);
    setFilteredData(filteredData);
  };

  if (display === false) return <></>;

  return (
    <>
      <div className={styles.searchContainer}>
        <FaSearch />
        <input
          style={{
            marginLeft: "10px",
          }}
          type="text"
          placeholder="Quick Player Search"
          onChange={(e) => {
            if (e.target.value.length > 0) {
              setopenList(true);
              handleSearch(e.target.value);
            } else {
              setopenList(false);
            }
          }}
        />
      </div>

      <Collapse in={openList} className={styles.collapseStyle}>
        <div className={styles.listgroup}>
        {filteredData && filteredData.length === 0 ? (
          <p>Can't find a player? Try <Link to="/player-profile">smart search</Link></p>
          //or <Link to="/request-player">request a player</Link>
        ) : (
          filteredData.map((item, key) => (
            <Link
              to={
                (isAdmin() || isSuperAdmin())
                  ? `/player-profile/detail/${item.id}`
                  : `/player-profile/preview/${item.id}`
              }
                style={{
                  textDecoration: "none",
                }}
              >
                <SearchList item={item} isAdmin={isAdmin} isSuperAdmin={isSuperAdmin} />
              </Link>
            ))
          )}
        </div>
      </Collapse>
      {openList && <OverlayComponent onClickAway={() => setopenList(false)} />}
    </>
  );
}

const OverlayComponent = ({ onClickAway }) => {
  return <div onClick={onClickAway} className={styles.overlay}></div>;
};

export const SearchList = ({ item, isAdmin, isSuperAdmin }) => {

  const history = useHistory();
  
  const handleItemClick = () => {
    const destination = isAdmin() || isSuperAdmin()
      ? `/player-profile/detail/${item.id}`
      : `/player-profile/preview/${item.id}`;

    history.push(destination); // Navigate to the selected destination
  };




  return (

    <Link
      to={
        isAdmin() || isSuperAdmin()
          ? `/player-profile/detail/${item.id}`
          : `/player-profile/preview/${item.id}`
      }
      style={{
        textDecoration: 'none',
      }}
    >
    <div onClick={handleItemClick} className={styles.searchListItem}>
    

    <Row className={styles.tableRow}>
      <Col className="p-0">
        <Row className="p-2">
          <Col md={2}>
            <Image
              width="50px"
              height="50px"
              roundedCircle
              src={item?.Headshot ? item.Headshot : previewImage}
              onError={(e) => {
                e.target.src = previewImage; // Set default image if loading fails
              }}
              style={{
                objectFit: "contain",
              }}
            />
          </Col>
          <Col md={10}>
            <div className={styles.tablePersonInfo}
            style={{
              width: "200px",
              marginLeft: "20px"
            }}>
            <h4>{item.FirstName} {item.LastName}</h4>
              <h6>{item.Team}</h6>
              <p>
                {item.hometown_City}
                {item.hometown_City && item.hometown_State_Country ? ", " : ""}
                {item.hometown_State_Country}
              </p>
            </div>
          </Col>
        </Row>
      </Col>
      <Col className="p-0">
        <div className={styles.tableData}
        style={{
          width: "80px",
          marginLeft: "30px"
        }}>
          <p>Nationalality</p>
          <h4>{item.Country ? item.Country : "--"}</h4>
        </div>
      </Col>
      <Col className="p-0">
        <div className={styles.tableData}>
          <p>Position</p>
          <h4>{item.Position ? item.Position : "--"}</h4>
        </div>
      </Col>
      <Col className="p-0">
        <div className={styles.tableData}>
          <p>Year</p>
          <h4>{item.Year ? item.Year : "--" }</h4>
        </div>
      </Col>
      <Col className="p-0">
        <div className={styles.tableData}>
          <p>DOB</p>
          <h4>{item.DOB ? moment(item.DOB).format("l") : '--'}</h4>
          
        </div>
      </Col>
      <Col className="p-0">
        <div className={styles.tableData}>
          <p>Age</p>
          <h4>
            {item.Age !== 0.0 && !isNaN(parseFloat(item.Age))
              ? parseFloat(item.Age).toFixed(1)
              : '--'}
          </h4>
        </div>
      </Col>
      <Col className="p-0">
        <div
          className={styles.tableData}
          style={{
            width: "150px",
          }}
        >
          <p>AAU Team</p>
          <h4>{item.aauTeam ? item.aauTeam : "--" }</h4>
        </div>
      </Col>
      <Col className="p-0">
        <div className={styles.tableData}>
          
          <p>Affiliation</p>
          <h4>{item.aauAffil ? item.aauAffil : "--"}</h4>
        </div>
      </Col>
      <Col className="p-0">
        <div
          className={styles.tableData}
          style={{
            width: "200px",
          }}
        >
          <p>Status</p>
          <h4>
          {item.Status !== "Undecided" ? `${item.Status}: ${item.committedTeam}` : item.Status}
          </h4>
        </div>
      </Col>
      <Col className="p-0">
        <div
          className={styles.tableData}
          style={{
            backgroundColor: "#000",
            width: "100%",
          }}
        >
          <div className={styles.tableDataDark}>
            <p>P.I. Score</p>
            <h4
              style={{
                color: "#fff",
              }}
            >
            {/*item.Piscore*/}
            Coming Soon
            </h4>
          </div>
        </div>{" "}
      </Col>
    </Row>
    </div>
    </Link>
  );
};
