import { useState, useEffect } from "react";
import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { FaUpload } from "react-icons/fa";

export default function EditModal({ setOpen, onSave, item }) {
  const [ticketData, setTicketData] = useState({
    date: "",
    priority: "",
    Report: "",
    Title: "",
    submittedBy: "",
  });

  useEffect(() => {
    if (item) {
      setTicketData(item);
    }
  }, [item]);

  const close = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTicketData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(ticketData); // Pass the updated ticketData to onSave function
  };

  return (
    <div className={styles.modalContainer}>
      <div onClick={close} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={close}>
          <MdClose />
        </button>
        <div className={styles.content}>
          <h3>Ticket</h3>
          <span>This section is used to edit a ticket.</span>
          <hr />
          <div className={styles.data}>
            <div>
              <span>Name</span>
              <input
                type="text"
                name="submittedBy"
                value={ticketData.submittedBy}
                onChange={handleChange}
              />
              <span>Title</span>
              <input
                type="text"
                name="Title"
                value={ticketData.Title}
                onChange={handleChange}
              />
              <span>Report Type</span>
              <input
                type="text"
                name="Report"
                value={ticketData.Report}
                onChange={handleChange}
              />
              <span>Priority Type</span>
              <input
                type="text"
                name="priority"
                value={ticketData.priority}
                onChange={handleChange}
              />
            </div>
            <div>
              <span>Upload Attachment</span>
              <div className={styles.dropzone}>
                <FaUpload />
                <span>Upload smaller than 3MB</span>
              </div>
            </div>
          </div>
          <div className={styles.desc}>
            <span>Description Note</span>
            <textarea
              name="description"
              value={ticketData.description}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className={styles.btns}>
          <button onClick={close}>Cancel</button>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
}
