import Layout from "../../../components/layout/layout.js";

import YearRangeDropdown from "../../../components/componentsPage/yearRangeDropdown/index.js";

function YearRangeDropdownPage({ component, ...rest }) {
  return (
    <Layout Component={YearRangeDropdown} controlsDisabled={true} {...rest} />
  );
}

export default YearRangeDropdownPage;
