// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions"; 

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCeoXjOaIJM-sRiQPgMNTLm8w0BsJeaJOc",
  authDomain: "proinsight-admin.firebaseapp.com",
  projectId: "proinsight-admin",
  storageBucket: "proinsight-admin.appspot.com",
  messagingSenderId: "1029628106942",
  appId: "1:1029628106942:web:dbc6f934eca0d9503be6ef",
  measurementId: "G-SCD4624E2E",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig); // You need to call initializeApp

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const functions = app.functions(); // Initialize Firebase Functions

export default app;


