import { useState, useEffect } from "react";
import styles from "./NationalityDropdown.module.scss";
import Header from "../../header/header";
import { FaSortAmountDownAlt } from "react-icons/fa";
import AddModal from "./addModal";
import EditModal from "./editModal";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import { CustomSpinner } from "../../shared/Spinner";
import { MdModeEdit, MdRestoreFromTrash } from "react-icons/md";
import DeleteModal from "../../shared/DeleteModal";
import DeleteCollectionModal from "../../shared/DeleteCollection";

export default function NationalityDropdown() {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nationalityData, setNationalityData] = useState(null);
  const [editModelToggle, setEditModelToggle] = useState(false);
  const [selectedDeleteNationality, setSelectedDeleteNationality] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteCollectionModal, setShowDeleteCollectionModal] = useState(false);

  useEffect(() => {
    getNationalityData();
    return () => {};
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    db.collection("nationality-dropdown")
      .doc(id)
      .delete()
      .then(() => {
        setLoading(false);
        toast.error("Nationality Item Deleted Successfully", {
          theme: "colored",
        });
        getNationalityData();
      })
      .catch((error) => {
        toast.error("Error Deleting Nationality Item", {
          theme: "colored",
        });
      });
  };

  const deleteCollection = () => {
    setLoading(true);
    db.collection("nationality-dropdown")
      .get()
      .then((querySnapshot) => {
        const batch = db.batch();
        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
        return batch.commit();
      })
      .then(() => {
        setLoading(false);
        toast.success("Nationality Collection Deleted Successfully", {
          theme: "colored",
        });
        setNationalityData([]);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error Deleting Nationality Collection", {
          theme: "colored",
        });
      });
  };

  const getNationalityData = async () => {
    setLoading(true);
    try {
      const response = await db.collection("nationality-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      //console.log(data, "data response");
      setNationalityData(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  const importData = (file) => {
    setLoading(true);
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileData = event.target.result;
      const lines = fileData.split(/\r\n|\n/);
      const headers = lines[0].split(",");
      const importedData = [];

      for (let i = 1; i < lines.length; i++) {
        const row = lines[i].split(",");
        if (row.length === headers.length) {
          const rowData = {};
          for (let j = 0; j < headers.length; j++) {
            rowData[headers[j]] = row[j];
          }
          importedData.push(rowData);
        }
      }

      try {
        const batch = db.batch();
        importedData.forEach((data) => {
          const docRef = db.collection("nationality-dropdown").doc(data.id || db.collection("nationality-dropdown").doc().id );
          batch.set(docRef, data, { merge: true });
        });
        await batch.commit();
        setLoading(false);
        toast.success("Data Imported Successfully", {
          theme: "colored",
        });
        getNationalityData();
      } catch (error) {
        setLoading(false);
        toast.error("Error Importing Data", {
          theme: "colored",
        });
      }
    };

    reader.readAsText(file);
  };

  const exportData = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      "id,name,value\n" +
      nationalityData.map((item) => `${item.id || ""},${item.name},${item.value}`).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "nationalities.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <CustomSpinner />;
  }

  if (nationalityData === null && !loading) {
    return <div>Error loading nationality data</div>;
  }

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <div className={styles.title}>
          <h4>Nationality</h4>
          <p>This section is used to edit nationality dropdown menu</p>
        </div>
        <hr />
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div>
              <span>Total</span>
              <span>{nationalityData ? nationalityData.length : "0"}</span>
            </div>
            <div className={styles.importButton}>
              <input
                type="file"
                id="csvFile"
                accept=".csv"
                onChange={(e) => importData(e.target.files[0])}
                style={{ display: "none" }}
              />
              <label htmlFor="csvFile" className={styles.importButtonLabel}>
                Import CSV File
              </label>
            </div>
            <div>
              <button onClick={exportData} className={styles.exportButton}>
                Export Data
              </button>
            </div>
            <button
              onClick={() => {
                setOpenEditModal(true);
              }}
            >
              Add Item
            </button>
            <button
              onClick={() => {
                setShowDeleteCollectionModal(true);
              }}
              className={styles.deleteButton}
            >
              Delete Collection
            </button>
          </div>
          <div className={styles.row}>
            <div>Nationality</div>
            <div>Value</div>
            <div>Actions</div>
          </div>
          {nationalityData.map((item) => (
            <div key={item.id} className={styles.row}>
              <div>{item.name}</div>
              <div>{item.value}</div>
              <div>
                <MdModeEdit
                  onClick={() => {
                    setEditModelToggle(item);
                  }}
                />
                <MdRestoreFromTrash
                  onClick={() => {
                    setSelectedDeleteNationality(item.id);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <DeleteCollectionModal
        handleClose={() => {
          setShowDeleteCollectionModal(false);
        }}
        show={showDeleteCollectionModal}
        handleConfirm={deleteCollection}
        title="Delete Collection"
        message="Are you sure you want to delete the entire collection? This action cannot be undone."
      />

      <DeleteModal
        handleClose={() => {
          setSelectedDeleteNationality(false);
        }}
        show={selectedDeleteNationality}
        handleConfirm={() => {
          handleDelete(selectedDeleteNationality);
        }}
        title="Delete Item"
        message="Are you sure you want to delete the item? This action cannot be undone."
      />

      {openEditModal && (
        <AddModal setOpen={setOpenEditModal} reloadData={getNationalityData} />
      )}
      {editModelToggle && (
        <EditModal
          data={editModelToggle}
          setOpen={setEditModelToggle}
          reloadData={getNationalityData}
        />
      )}
    </div>
  );
}
