import React, { useEffect, useState, useCallback, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import DeleteModal from "../../shared/DeleteModal";
import { MdOutlineCancel } from 'react-icons/md';
import { HiPlusSm } from 'react-icons/hi';
import { BsInstagram, BsTwitter } from 'react-icons/bs';


// styles
import styles from "../Form.module.scss";

export function SocialMedia({ setFieldValue, socialMedia, handleChange, handleBlur }) {
    useEffect(()=>{
        socialMedia.twitter.nuggets.length === 0 && setFieldValue("socialMedia.twitter.nuggets", [""]);
        socialMedia.instagram.nuggets.length === 0 && setFieldValue("socialMedia.instagram.nuggets", [""])
    }, [socialMedia.twitter.nuggets.length ,socialMedia.instagram.nuggets.length])
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const Add = (index, fieldName) => {
        setFieldValue(`socialMedia.${fieldName}.nuggets`,
            [
                ...socialMedia[fieldName].nuggets.slice(0, index + 1),
                "",
                ...socialMedia[fieldName].nuggets.slice(index + 1),
            ]
        );
    }
    const ConfirmDelete = (index, fieldName) => {
        setFieldValue(`socialMedia.${fieldName}.nuggets`, [
            ...socialMedia[fieldName].nuggets.filter((_, ind) => ind !== index),
        ])
    }
    const Delete = (index, fieldName) => {
        if (socialMedia[fieldName].nuggets.length === 1) return;
        setDeleteConfirm(true);
        setDeleteIndex({ index: index, fieldName: fieldName });
    }
    return (
        <>
            <DeleteModal
                show={deleteConfirm}
                handleConfirm={() => { ConfirmDelete(deleteIndex.index, deleteIndex.fieldName) }}
                handleClose={() => setDeleteConfirm(false)}
            />
            <div className={styles.container}>
                <div className={styles.title}>
                    <div>
                        <h4>Social Media</h4>
                        <subtitle>
                            This section is used to add social media. Please fill as many sections as possible.
                        </subtitle>
                    </div>
                </div>
                <h4 className={styles.subHead}><BsTwitter />Twitter</h4>
                <div className={styles.field}>
                <Row className={styles.field}>
                    <label className="col-sm-2">Account is: </label>
                    <div className="col-sm-6">
                        <select 
                            name={`socialMedia.twitter.account`} 
                            value={socialMedia.twitter.account} 
                            onChange={handleChange} 
                            onBlur={handleBlur} 
                            className={styles.dropdownStyle} 
                        >
                            <option value="" >Select Option</option>
                            <option value="Private">Private</option>
                            <option value="Public">Public</option>
                        </select>
                    </div>
                </Row>
                </div>
                <div className={styles.field}>
                    <Row className={styles.field}>
                        <label className="col-sm-2">DMs are: </label>
                        <div className="col-sm-6">
                            <select 
                                name={`socialMedia.twitter.dms`} 
                                value={socialMedia.twitter.dms} 
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                className={styles.dropdownStyle} 
                            >
                                <option value="">Select Option</option>
                                <option value="Open">Open</option>
                                <option value="Closed">Closed</option>
                            </select>
                        </div>
                    </Row>
                </div>

                <div className={styles.field}>
                    <p>Twitter Nuggets</p>
                    {socialMedia.twitter.nuggets.length > 0 &&
                        socialMedia.twitter.nuggets.map((nug, index) =>
                            <div className={styles.btns} key={index} >
                                <input type="text" name={`socialMedia.twitter.nuggets[${index}]`} value={nug} onChange={handleChange} onBlur={handleBlur} />
                                <button className={`${styles.green} ${styles.small}`} onClick={() => { Add(index, "twitter") }} ><HiPlusSm /></button>
                                <button className={`${styles.red} ${styles.small}`} onClick={() => { Delete(index, "twitter") }} ><MdOutlineCancel /></button>
                            </div>
                        )                       
                    }
                </div>

                <h4 className={styles.subHead}><BsInstagram />Instagram</h4>
                <div className={styles.field}>
                    <Row className={styles.field}>
                        <label className="col-sm-2">Account is: </label>
                        <div className="col-sm-6">
                            <select 
                                name={`socialMedia.instagram.account`} 
                                value={socialMedia.instagram.account} 
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                className={styles.dropdownStyle} 
                            >
                                <option value="">Select Option</option>
                                <option value="Private">Private</option>
                                <option value="Public">Public</option>
                            </select>
                        </div>
                    </Row>
                </div>
                <div className={styles.field}>
                    <p>Instagram Nuggets</p>
                    {socialMedia.instagram.nuggets.length > 0 &&
                        socialMedia.instagram.nuggets.map((nug, index) =>
                            <div className={styles.btns} key={index}>
                                <input  type="text" name={`socialMedia.instagram.nuggets[${index}]`} value={nug} onChange={handleChange} onBlur={handleBlur} />
                                <button  className={`${styles.green} ${styles.small}`} onClick={() => { Add(index, "instagram") }} ><HiPlusSm /></button>
                                <button className={`${styles.red} ${styles.small}`} onClick={() => { Delete(index, "instagram") }} ><MdOutlineCancel /></button>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}