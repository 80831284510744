import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { FaUpload } from "react-icons/fa";

export default function EditModal({ setOpen, item }) {
  const close = () => {
    setOpen(false);
  };
  return (
    <div className={styles.modalContainer}>
      <div onClick={close} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={close}>
          <MdClose />
        </button>
        <div className={styles.content}>
          <h3>Calendar</h3>
          <span>
            This section is used to edit an event on the calendar. Please update
            desired sections.
          </span>
          <hr />
          <div className={styles.inputs}>
            <span>Event date</span>
            <input
              className={styles.dateInput}
              type="date"
              defaultValue={item.start}
            />
            <span>Event Title</span>
            <input type="text" defaultValue={item.title} />
            <span>Location</span>
            <input type="text" />
            <span>Note</span>
            <textarea></textarea>
          </div>
        </div>
        <div className={styles.btns}>
          <button onClick={close}>Cancel</button>
          <button>Save</button>
        </div>
      </div>
    </div>
  );
}
