import React, { useState, useEffect } from "react";
import { Button, DropdownButton, Dropdown, Col, Row } from "react-bootstrap";
import Header from "../header/header";

import styles from "./FormArticle.module.scss";

// icons
import { FaEye } from "react-icons/fa";
import { BsHandThumbsUpFill } from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import { EditArticleModal } from "./edit-article-modal";

// firebase
import { db } from "../../firebase";
import { CustomSpinner } from "../shared/Spinner";
import DeleteModal from "../shared/DeleteModal.jsx";

export const Published = () => {
  const [openEditArticle, setOpenEditArticle] = useState(false);
  const [selectedDeleteArticle, setSelectedDeleteArticle] = useState(null);
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getArticleData();

    return () => {};
  }, []);

  const deleteArticle = async () => {
    setLoading(true);
    try {
      const response = await db
        .collection("articles")
        .doc(selectedDeleteArticle)
        .delete();
      console.log(response, "response");
      getArticleData();
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  const getArticleData = async () => {
    setLoading(true);
    try {
      const response = await db
        .collection("articles")
        .where("drafted", "==", false)
        .orderBy("createdAt", "desc")
        .get();

      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      //console.log(data, "data response");
      setArticle(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  if (loading) {
    return <CustomSpinner />;
  }

  if (article === null && !loading) {
    return <div>Error loading article data</div>;
  }

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Published Articles</h4>
            <subtitle>This section is edit published articles</subtitle>
          </div>
        </div>

        <div className={styles.cardList}>
          {article.map((item) => (
            <PublishCard
              key={item.id}
              item={item}
              getArticleData={getArticleData}
              handleDelete={() => {
                setSelectedDeleteArticle(item.id);
              }}
              handleEditOpen={() => setOpenEditArticle(item.id)}
            />
          ))}
        </div>
      </div>
      <DeleteModal
        handleClose={() => setSelectedDeleteArticle(false)}
        show={selectedDeleteArticle}
        handleConfirm={() => {
          deleteArticle(selectedDeleteArticle);
          setSelectedDeleteArticle(false);
        }}
      />
      <EditArticleModal
        show={openEditArticle}
        handleClose={() => setOpenEditArticle(false)}
      />
    </>
  );
};

const PublishCard = ({
  handleDelete,
  handleEditOpen,
  item,
  getArticleData,
}) => {
  const handlePublish = async (id) => {
    try {
      const response = await db.collection("articles").doc(id).update({
        drafted: true,
      });
      console.log(response, "response");
      getArticleData();
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <Row className={styles.card}>
      <Col xs={6}>
        <div className={styles.field}>
          <p>Article Title</p>
          <img
            // src={"https://picsum.photos/seed/picsum/500/500"}
            src={item["article-image"]}
            height={"218px"}
            width={"100%"}
            style={{objectFit:"contain"}}
            alt="articleImage"
          />
        </div>

        <div className={styles.imageFooter}>
          <div>
            <FaEye />
            <span>1,256 views</span>
          </div>
          <div
            style={{
              marginLeft: "20px",
            }}
          >
            <BsHandThumbsUpFill />
            <span>1,256 views</span>
          </div>
        </div>
      </Col>
      <Col xs={6}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <DropdownButton
            className={styles.dropdown}
            variant="light"
            style={{
              marginLeft: "10px",
            }}
            title={<AiOutlineMenu />}
          >
            <Dropdown.Item
              eventKey="1"
              onClick={() => {
                handlePublish(item.id);
              }}
            >
              {" "}
              Unpublish
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </Dropdown.Item>
          </DropdownButton>
        </div>

        <div className={styles.field}>
          <p>Article Title</p>
          <input type="text" value={item.title} disabled />
        </div>
        <div className={styles.field}>
          <p>Type</p>
          <input type="text" value={item.type} disabled />
        </div>
        <div className={styles.field}>
          <p>Preview</p>
          <input type="text" value={item["preview-text"]} disabled />
        </div>
        <div className={styles.buttonWrap}>
          <Button onClick={handleEditOpen}>Edit</Button>
        </div>
      </Col>
    </Row>
  );
};
