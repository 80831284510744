import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { db } from "../firebase";

const AuthContext = React.createContext();

export { AuthContext };
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userInfo, setUserInfo] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword("asd@gmail.com", "asd");
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    localStorage.removeItem("account");
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function getUserInfoData() {
    if (!userInfo) {
      getUserInfo(currentUser);
      return JSON.parse(localStorage.getItem("account"));
    }
    return userInfo;
  }

  function isDemo() {
    if (!userInfo) {
      const userInfoLocal = JSON.parse(localStorage.getItem("account"));
      return userInfoLocal && userInfoLocal.memberType === "demo";
    }
    return userInfo && userInfo.memberType === "demo";
  }
  


  function isAdmin() {
    if (!userInfo) {
      const userInfoLocal = JSON.parse(localStorage.getItem("account"));
      return userInfoLocal && userInfoLocal.memberType === "admin";
    }
    return userInfo && userInfo.memberType === "admin";
  }

  function isSuperAdmin() {
    if (!userInfo) {
      const userInfoLocal = JSON.parse(localStorage.getItem("account"));

      return (
        userInfoLocal &&
        userInfoLocal.memberType === "super-admin" //&&
        //userInfoLocal.uid === "7cqgwoW9qkbCCBatNfu4kANY0PZ2"
      );
    }

    return (
      userInfo &&
      userInfo.memberType === "super-admin" //&&
      //userInfo.uid === "7cqgwoW9qkbCCBatNfu4kANY0PZ2"
    );
  }

  async function getUserInfo(loggedInUser) {
    if (loggedInUser) {
      const accountsRef = db
        .collection("Accounts")
        .doc(loggedInUser._delegate.uid);

      const doc = await accountsRef.get();
      if (!doc.exists) {
        console.log("No such document!");
      } else {
        setUserInfo({ ...doc.data(), uid: loggedInUser._delegate.uid });
        setCurrentUser(loggedInUser);
        setLoading(false);

        localStorage.setItem(
          "account",
          JSON.stringify({ ...doc.data(), uid: loggedInUser._delegate.uid })
        );
      }
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      getUserInfo(user);
      setCurrentUser(user);

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userInfo,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    isDemo,
    isAdmin,
    isSuperAdmin,
    getUserInfoData,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
