import Layout from "../../../components/layout/layout.js";

import NationalityDropdown from "../../../components/componentsPage/nationalityDropdown/index.js";

function NationalityDropdownPage({ component, ...rest }) {
  return (
    <Layout Component={NationalityDropdown} controlsDisabled={true} {...rest} />
  );
}

export default NationalityDropdownPage;
