import React, { useEffect, useState } from "react";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
// import { getStorage, ref } from "firebase/storage";

import { Modal } from "react-bootstrap";

function DropzonewithEdit({ isImgEditorShown, closeImgEditor, onSave }) {
  // const storage = getStorage();
  // const [loadHTTPS, setloadHTTPS] = useState(false);

  // useEffect(() => {
  //   // console.log(isImgEditorShown, "url");
  //   // getBase64Image(isImgEditorShown);
  //   if (isImgEditorShown !== false) {
  //     getBase64Image(isImgEditorShown);
  //     getHTTPSImage(isImgEditorShown);
  //   }
  //   // getBase64Image(
  //   //   "https://uploads.sitepoint.com/wp-content/uploads/2015/12/1450377118cors3.png"
  //   // );
  //   return () => {
  //     // setloadHTTPS(false);
  //   };
  // }, []);

  // const getBase64Image = (url) => {
  //   const img = new Image();
  //   console.log(url, "url");
  //   img.setAttribute("crossOrigin", "anonymous");
  //   img.onload = () => {
  //     const canvas = document.createElement("canvas");
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  //     const ctx = canvas.getContext("2d");
  //     ctx.drawImage(img, 0, 0);
  //     const dataURL = canvas.toDataURL("image/png");
  //     console.log(dataURL, "sad");
  //   };
  //   img.src = url;
  // };

  // const getHTTPSImage = (url) => {
  //   const httpsReference = ref(storage, url);
  //   console.log(httpsReference);
  //   // setloadHTTPS(true);
  // };

  return (
    <div>
      <Modal
        show={isImgEditorShown}
        onHide={closeImgEditor}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          {isImgEditorShown && (
            <FilerobotImageEditor
              source={isImgEditorShown}
              // source={getBase64Image(
              //   "https://uploads.sitepoint.com/wp-content/uploads/2015/12/1450377118cors3.png"
              // )}
              // source={
              //   "https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg"
              // }
              onSave={(editedImageObject, designState) => {
                // console.log("saved", editedImageObject, designState)
                console.log(editedImageObject);
                return onSave(editedImageObject);
                
              }}
              theme={{
                palette: {
                  "accent-primary": "#000",
                  "accent-primary-hover": "grey",
                  "accent-primary-active": "#000",
                  "bg-primary-active": "#DDD",
                  "btn-primary-text": "#fff",
                  "link-hover": "grey",
                  // "active-secondary-hover": "grey",
                  // "active-secondary": "grey",
                  // "txt-primary-invert": "#000",
                  // "txt-secondary": "#000",
                  // "bg-primary": "#000",
                  // "btn-primary-text": "#fff",
                  // "icons-primary": "#fff",
                },
                typography: {
                  fontFamily: "Roboto, Arial",
                },
              }}
              onClose={closeImgEditor}
              annotationsCommon={{
                fill: "#ff0000",
              }}
              Text={{ text: "Filerobot..." }}
              Rotate={{ angle: 90, componentType: "slider" }}
              Crop={{
                presetsItems: [
                  {
                    titleKey: "classicTv",
                    descriptionKey: "4:3",
                    ratio: 4 / 3,
                    // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
                  },
                  {
                    titleKey: "cinemascope",
                    descriptionKey: "21:9",
                    ratio: 21 / 9,
                    // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
                  },
                ],
                // presetsFolders: [
                //   {
                //     titleKey: "socialMedia", // will be translated into Social Media as backend contains this translation key
                //     // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
                //     groups: [
                //       {
                //         titleKey: "facebook",
                //         items: [
                //           {
                //             titleKey: "profile",
                //             width: 180,
                //             height: 180,
                //             descriptionKey: "fbProfileSize",
                //           },
                //           {
                //             titleKey: "coverPhoto",
                //             width: 820,
                //             height: 312,
                //             descriptionKey: "fbCoverPhotoSize",
                //           },
                //         ],
                //       },
                //     ],
                //   },
                // ],
              }}
              tabsIds={[
                TABS.ADJUST,
                // TABS.ANNOTATE,
                // , TABS.WATERMARK
              ]} // or {['Adjust', 'Annotate', 'Watermark']}
              defaultTabId={TABS.ADJUST} // or 'Annotate'
              defaultToolId={TOOLS.TEXT} // or 'Text'
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DropzonewithEdit;

// - remove the watermark
// - remove draw as well
// - active text colour to whit
// - home town State
// report page changes on change of type
//  on player profile it would be month date and year
//
