import React, { useEffect, useState } from "react";

//  toast
import { toast } from "react-toastify";

// slugify
import slugify from 'react-slugify'

// forms
import { Formik } from "formik";
import * as Yup from "yup";

// firebase
import { db, storage } from "../../firebase";

import { Button, Modal } from "react-bootstrap";
import RichEditor from "./rich-editor";
// react bootstrap
import { Row, Col, Spinner, Accordion } from "react-bootstrap";
import { StyledDropzone } from "../form/imageDropZone";

import styles from "./FormArticle.module.scss";
import { CustomSpinner } from "../shared/Spinner";
import MediaLibraryModal from "../shared/MediaLibraryModal";
import { Image } from "primereact/image";

export const EditArticleModal = ({ show, handleClose, item }) => {
  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [articleData, setArticleData] = useState(null);
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);

  useEffect(() => {
    if (show) {
      getArticleData();
    }
  }, [show]);

  const getArticleData = async () => {
    setLoadData(true);
    try {
      const response = await db.collection("articles").doc(show).get();
      const data = response.data();
      //console.log(data, "data response");
      setArticleData(data);
      setLoadData(false);
    } catch (error) {
      console.log(error, "error");
      setLoadData(false);
    }
  };

  const handleFireBaseUpload = (imageAsFile, docID) => {
    console.log("start of upload");
    // async magic goes here...
    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    }
    const uploadTask = storage
      .ref(`/article-images/${imageAsFile.name}`)
      .put(imageAsFile);
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        console.log(snapShot);
      },
      (err) => {
        //catches the errors
        console.log(err);
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref("article-images")
          .child(imageAsFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            db.collection("articles")
              .doc(docID)
              .update({
                ID: docID,
                "article-image": fireBaseUrl,
              })
              .then(() => {
                console.log("image uploaded and saved to firebase");
              })
              .catch((err) => {
                console.log(err);
              });
          });
      }
    );
  };
  // const formik = useFormik({
  //   initialValues: {
  //     ...articleData,
  //   },
  //   validationSchema: Yup.object({
  //     "article-image": Yup.string().required("Article Image is required"),
  //     content: Yup.string().required("Content is required"),
  //     "preview-text": Yup.string().required("Preview Text is required"),
  //     title: Yup.string().required("Title is required"),
  //     type: Yup.string().required("Type is required"),
  //   }),
  //   onSubmit: (values, { resetForm }) => {
  //     console.log(values, "values");
  //     setLoading(true);
  //     db.collection("articles")
  //       .add({
  //         ...values,
  //         "article-image": "null",
  //       })
  //       .then((docRef) => {
  //         console.log("Document successfully written!");
  //         handleFireBaseUpload(values["article-image"], docRef.id);
  //         setLoading(false);
  //         resetForm({});
  //         toast.success("Article Updated Successfully", {
  //           theme: "colored",
  //         });
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         toast.error("Error Creating Article", {
  //           theme: "colored",
  //         });
  //         console.error("Error writing document: ", error);
  //       });
  //   },
  // });

  if (loadData) {
    return <CustomSpinner />;
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Formik
        initialValues={{ ...articleData }}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
          }, 1000);
        }}
      >
        {(formik) => (
          <>
            <Modal.Body>
              <div className={styles.container}>
                <div className={styles.title}>
                  <div>
                    <h4>New Article</h4>
                    <subtitle>This section is used to create articles</subtitle>
                  </div>
                </div>

                <Row>
                  <Col md={12}>
                    <Accordion className={styles.accordionSection}>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Article Detail</Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col md={6}>
                              <div className={styles.field}>
                                <p>Article Title</p>
                                <input
                                  type="text"
                                  name="title"
                                  onChange={(e) => { formik.handleChange(e); 
                                    formik.setFieldValue("slug", slugify(e.target.value));
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.title}
                                />
                                {formik.errors.title && formik.touched.title ? (
                                  <div className={styles.errorMessage}>
                                    {formik.errors.title}
                                  </div>
                                ) : null}
                              </div>
                              <div className={styles.field}>
                                <p>Type</p>
                                <input
                                  type="text"
                                  name="type"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.type}
                                />
                                {formik.errors.type && formik.touched.type ? (
                                  <div className={styles.errorMessage}>
                                    {formik.errors.type}
                                  </div>
                                ) : null}
                              </div>
                              <div className={styles.field}>
                                <p>Preview</p>
                                <input
                                  type="text"
                                  name="preview-text"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values["preview-text"]}
                                />
                                {formik.errors["preview-text"] &&
                                  formik.touched["preview-text"] ? (
                                  <div className={styles.errorMessage}>
                                    {formik.errors["preview-text"]}
                                  </div>
                                ) : null}
                              </div>
                              <div className={styles.field}>
                                <p>Slug</p>
                                <input
                                  type="text"
                                  name="slug"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.slug}
                                />
                                {formik.errors.slug && formik.touched.slug ? (
                                  <div className={styles.errorMessage}>
                                    {formik.errors.slug}
                                  </div>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div
                                className={styles.field}
                                style={{
                                  height: "26vh",
                                }}
                              >
                                <p>Article Image</p>
                                <StyledDropzone
                                  handleOpenMediaLibrary={() =>
                                    setShowMediaLibrary(true)
                                  }
                                  errors={formik.errors["article-image"]}
                                  touched={formik.touched["article-image"]}
                                  onDrop={(acceptedFiles) => {
                                    console.log(acceptedFiles);
                                    formik.setFieldValue(
                                      "article-image",
                                      acceptedFiles
                                    );
                                  }}
                                />
                                {formik.values["article-image"] !== "" && (
                                  <Image
                                    src={formik.values["article-image"]}
                                    thumbnail
                                    preview
                                    width="110"
                                    height={"100%"}
                                    style={{
                                      marginTop: "1rem",
                                      width: "110px",
                                      height: "100px",
                                      objectFit: "contain",
                                      objectPosition: "center",
                                    }}
                                  />
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                  <Col md={12}>
                    <div className={styles.field}>
                      <p>Content</p>
                      <RichEditor
                        value={formik.values.content}
                        handleChange={(value) => {
                          formik.setFieldValue("content", value);
                        }}
                      />
                      {formik.errors.content && formik.touched.content ? (
                        <div className={styles.errorMessage}>
                          {formik.errors.content}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <MediaLibraryModal
                handleClose={() => setShowMediaLibrary(false)}
                show={showMediaLibrary}
                handleSelect={(imageUrl) => {
                  console.log(imageUrl, "selected imageUrl");
                  formik.setFieldValue("article-image", imageUrl);
                  setShowMediaLibrary(false);
                }}
              />
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                disabled={loading}
                onClick={() => {
                  formik.handleSubmit();
                  handleClose();
                }}
                variant="primary"
              // onClick={handleClose}
              >
                {loading ? <Spinner animation="border" /> : "Publish"}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
