import Layout from "../../../components/layout/layout.js";

import WeightDropdown from "../../../components/componentsPage/weight/index.js";

function WeightDropdownPage({ component, ...rest }) {
  return (
    <Layout Component={WeightDropdown} controlsDisabled={true} {...rest} />
  );
}

export default WeightDropdownPage;
