import React, { useState } from "react";

// react bootstrap
import { Row, Col } from "react-bootstrap";

// components
import DateTimePicker from "react-datetime-picker";
import { BsCardText } from "react-icons/bs";
import styles from "./Form.module.scss";

// header
import Header from "../header/header";

export default function TicketForm() {
  return (
    <div>
      <Header/>
      {/* ------------- TICKET FORM BEGINS -------------------*/}

      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Ticket</h4>
            <subtitle>
            This section is used to create a ticket. Please fill all sections. {" "}
            </subtitle>
          </div>
        </div>

        <Row>
            <Col md={6}>
                <div className={styles.field}>    
                <p>Name</p>
                <input type="text" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <div className={styles.field}>
                <p>Title</p>
                <input type="text" />
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <div className={styles.field}>
                    <p>Report Type</p>
                    <input type="text" />
                </div>
                <div className={styles.field}>
                    <p>Priority Type</p>
                    <input type="text" />
                </div>
            </Col>
            <Col md={6}>
            <div className={styles.field}>
                <p>Upload Attachment</p>
                <textarea name="" id="" rows={4}></textarea>
            </div>
            </Col>
        </Row>
        <div className={styles.field}>
          <p>Description Note</p>
          <textarea name="" id="" rows={6}></textarea>
        </div>
      </div>    
    </div>
  );
}
