import Layout from "../../../components/layout/layout.js";

import Resources from "../../../components/componentsPage/resources/index.js";

function ResourcesPage({ component, ...rest }) {
  return (
    <Layout Component={Resources} controlsDisabled={true} {...rest} />
  );
}

export default ResourcesPage;
