import Layout from "../../../components/layout/layout.js";

//import BackgroundOverviewFeed from "../../../components/componentsPage/overviewFeed/backgroundOverviewFeed.js";
import BackgroundOverviewFeed from "../../../components/componentsPage/backgroundOverviewFeed/index.js";


function BackgroundOverviewFeedPage ({ component, ...rest }) {
  return <Layout Component={BackgroundOverviewFeed } controlsDisabled={true} {...rest} />;
}

export default BackgroundOverviewFeedPage;
