import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.scss";
import moment from "moment";
import { db } from "../../../firebase";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { MdModeEdit } from "react-icons/md";
import { EditBgOverviewModal } from "../../PlayerProfileDetail/background-overview/EditBgOverviewModal";
import Header from "../../header/header";

export default function BackgroundOverviewFeed() {
  const [boFeed, setBoFeed] = useState([]); // BO feed state
  const [iboFeed, setIboFeed] = useState([]); // IBO feed state
  const [boSortOrder, setBoSortOrder] = useState(null); // BO sort order
  const [iboSortOrder, setIboSortOrder] = useState(null); // IBO sort order
  const [selectedEditItem, setSelectedEditItem] = useState(null); // State to handle the selected item for editing
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State to control modal visibility

  // Function to get player title from player-profile collection
  const getPlayerTitle = async (playerProfileId) => {
    try {
      if (!playerProfileId) return "Null"; // If no playerProfileId, return Null

      const playerDoc = await db.collection("player-profile").doc(playerProfileId).get();
      if (playerDoc.exists) {
        return playerDoc.data().Title || "Null"; // Return title or Null if title doesn't exist
      } else {
        return "Null"; // Player profile document doesn't exist
      }
    } catch (error) {
      console.error("Error fetching player title:", error);
      return "Null"; // In case of error, return Null
    }
  };

  const getOverviewFeed = async () => {
    const snapshot = await db.collection("background-overview").orderBy("createdAt", "desc").get();
    let bo = [];
    let ibo = [];

    for (const doc of snapshot.docs) {
      let data = doc.data();

      // If title does not exist in background-overview, fetch it from player-profile
      if (!data.title && data.playerProfile) {
        const playerTitle = await getPlayerTitle(data.playerProfile);
        data.title = playerTitle;
      }

      if (data.IBO) {
        ibo.push({ ...data, id: doc.id }); // Add to IBO feed
      } else {
        bo.push({ ...data, id: doc.id }); // Add to BO feed
      }
    }

    setBoFeed(bo); // Set BO feed
    setIboFeed(ibo); // Set IBO feed
  };

  // Function to sort the BO feed by player profile
  const sortBoByPlayerProfile = () => {
    const sortedFeed = [...boFeed].sort((a, b) => {
      const playerA = a.title || "Null";
      const playerB = b.title || "Null";

      if (boSortOrder === "asc") {
        return playerA.localeCompare(playerB);
      } else {
        return playerB.localeCompare(playerA);
      }
    });

    setBoFeed(sortedFeed);
    setBoSortOrder(boSortOrder === "asc" ? "desc" : "asc"); // Toggle BO sort order
  };

  // Function to sort the IBO feed by player profile
  const sortIboByPlayerProfile = () => {
    const sortedFeed = [...iboFeed].sort((a, b) => {
      const playerA = a.title || "Null";
      const playerB = b.title || "Null";

      if (iboSortOrder === "asc") {
        return playerA.localeCompare(playerB);
      } else {
        return playerB.localeCompare(playerA);
      }
    });

    setIboFeed(sortedFeed);
    setIboSortOrder(iboSortOrder === "asc" ? "desc" : "asc"); // Toggle IBO sort order
  };

  // Function to handle opening the edit modal with the selected item
  const handleEditClick = (item) => {
    setSelectedEditItem(item); // Set the item to be edited
    setIsEditModalOpen(true); // Open the modal
  };

  // Function to close the edit modal and refresh the feed after editing
  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedEditItem(null);
    getOverviewFeed(); // Refresh the feed after editing
  };

  useEffect(() => {
    getOverviewFeed(); // Get the default sorted feed by date on first load
  }, []);

  return (
    <div className={styles.container}>
       <Header />
      <div className={styles.feedContainer}>
        {/* BO Feed */}
        <div className={styles.feed}>
          <p>Background Overview Feed</p>
          <div className={styles.tableContainer}>
            <table>
              <thead>
                <tr>
                  <th>Date Created</th>
                  <th onClick={sortBoByPlayerProfile} style={{ cursor: "pointer" }}>
                    Player Profile {boSortOrder ? (boSortOrder === "asc" ? "↑" : "↓") : ""}
                  </th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {boFeed.map((item, index) => (
                  <tr key={index}>
                    <td>{moment.unix(item.createdAt).format("l")}</td>
                    <td>
                      {item.playerProfile ? (
                        <Link to={`/player-profile/detail/${item.playerProfile}`}>
                          {item.title || "Null"}
                        </Link>
                      ) : (
                        "No Profile"
                      )}
                    </td>
                    <td>BO</td>
                    <td>
                      <MdModeEdit onClick={() => handleEditClick(item)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* IBO Feed */}
        <div className={styles.feed}>
          <p>INT'L Background Overview Feed</p>
          <div className={styles.tableContainer}>
            <table>
              <thead>
                <tr>
                  <th>Date Created</th>
                  <th onClick={sortIboByPlayerProfile} style={{ cursor: "pointer" }}>
                    Player Profile {iboSortOrder ? (iboSortOrder === "asc" ? "↑" : "↓") : ""}
                  </th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {iboFeed.map((item, index) => (
                  <tr key={index}>
                    <td>{moment.unix(item.createdAt).format("l")}</td>
                    <td>
                      {item.playerProfile ? (
                        <Link to={`/player-profile/detail/${item.playerProfile}`}>
                          {item.title || "Null"}
                        </Link>
                      ) : (
                        "No Profile"
                      )}
                    </td>
                    <td>IBO</td>
                    <td>
                      <MdModeEdit onClick={() => handleEditClick(item)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {isEditModalOpen && (
          <EditBgOverviewModal
            show={isEditModalOpen}
            handleClose={handleEditModalClose} // Pass close handler
            bgOverviewData={selectedEditItem} // Pass selected item to the modal
          />
        )}
      </div>
    </div>
  );
}
