import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Header from "../header/header";

import styles from "./subs.module.scss";

import { AiOutlinePlus } from "react-icons/ai";

export const PlansPage = () => {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Plans</h4>
            <subtitle>This section is used to edit paid subscription cost</subtitle>
          </div>
        </div>

        <div className={styles.cardList}>
          {Array(4)
            .fill(null)
            .map(() => (
              <PublishCard />
            ))}
        </div>
      </div>
    </>
  );
};

const PublishCard = (props) => {
  const [dropdownNumber, setDropdownNumber] = useState(1);

  return (
    <Row className={styles.card}>
      <Col xs={6}>
        <div className={styles.field}>
          <p>Subscription Plan</p>
          <input type="text" />
        </div>
        <div className={styles.field}>
          <p>Price</p>
          <input type="text" />
        </div>
      </Col>
      <Col xs={6}>
        <div className={styles.field}>
          <p>Plan details</p>
          <textarea rows={5} />
        </div>
      </Col>
      <Col xs={12}>
        <div className={styles.field}>
          <p>Permissions</p>
          <div className={styles.permissions}>
            {Array(dropdownNumber)
              .fill(null)
              .map(() => (
                <Form.Select aria-label="Default select example">
                  <option>Select Permission</option>
                  <option value="1">LiveDB</option>
                  <option value="2">ArchiveDB</option>
                  <option value="3">Prorated Plan</option>
                  <option value="4">Demo</option>
                </Form.Select>
              ))}

            <button onClick={() => setDropdownNumber(dropdownNumber + 1)}>
              <AiOutlinePlus />
            </button>
          </div>
        </div>
      </Col>
      <Col xs={12}>
        <div className={styles.buttonWrap}>
          <Button onClick={props.handleEditOpen}>Edit</Button>
        </div>
      </Col>
    </Row>
  );
};
