import React from "react";
import { Badge, Col, ListGroup, Row, Table } from "react-bootstrap";
import Header from "../header/header";
import styles from "./contact.module.scss";

import { AiOutlineSearch, AiTwotoneFilter } from "react-icons/ai";

export const contact = () => {
  return (
    <>
      <Header />
      <Row>
        <Col md={8}>
          <div className={styles.container}>
            <div className={styles.title}>
              <h4>Contacts</h4>
              <subtitle>
                This section is used to monitor database contacts
              </subtitle>
            </div>
            <div className={styles.contentBody}>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <div className={styles.thWrap}>
                        Name
                        <AiOutlineSearch />
                      </div>
                    </th>
                    <th>
                      <div className={styles.thWrap}>
                        Title
                        <AiTwotoneFilter />
                      </div>
                    </th>
                    <th>
                      <div className={styles.thWrap}>
                        Contact Type
                        <AiTwotoneFilter />
                      </div>
                    </th>
                    <th>
                      <div className={styles.thWrap}>
                        Contact Email
                        <AiTwotoneFilter />
                      </div>
                    </th>
                    <th>
                      <div className={styles.thWrap}>
                        Phone #
                        <AiTwotoneFilter />
                      </div>
                    </th>
                    <th>
                      <div className={styles.thWrap}>
                        Team Affiliation
                        <AiTwotoneFilter />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Coach K</td>
                    <td>HeadCoach</td>
                    <td>Live Database</td>
                    <td>coach@duke.com</td>
                    <td>2139266974</td>
                    <td>Duke</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Coach K</td>
                    <td>HeadCoach</td>
                    <td>Live Database</td>
                    <td>coach@duke.com</td>
                    <td>2139266974</td>
                    <td>Duke</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Coach K</td>
                    <td>HeadCoach</td>
                    <td>Live Database</td>
                    <td>coach@duke.com</td>
                    <td>2139266974</td>
                    <td>Duke</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className={styles.container}>
            <div className={styles.title}>
              <h4>Contact Details</h4>
              <subtitle>Review contact details and notes</subtitle>
            </div>
            <div className={styles.field}>
              <p>First</p>
              <input type="text" />
            </div>
            <div className={styles.field}>
              <p>Last</p>
              <input type="text" />
            </div>
            <div className={styles.field}>
              <p>Team</p>
              <input type="text" />
            </div>
            <div className={styles.field}>
              <p>Email</p>
              <input type="text" />
            </div>
            <div className={styles.field}>
              <p>Notes</p>
              <textarea rows={6}></textarea>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
