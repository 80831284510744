import React, { useState, useEffect } from "react";
import ChatContainer from "../chatContainer";
import { db } from "../../firebase";
import styles from "./Dashboard.module.scss";

export default function StatsWidgets() {
  const [livePlayerCount, setPlayerCount] = useState(0);
  const [liveNoteCount, setNoteCount] = useState(0);
  const [scoutingOverviewCount, setSOCount] = useState(0);
  const [bgOverviewCount, setBgOCount] = useState(0);

  const getDocsCount = async (collection_name) => {
    const snapshot = await db.collection(collection_name).get();
    return snapshot.size;
  };
  const getCounts = async () => {
    setPlayerCount(await getDocsCount("player-profile"));
    setNoteCount(await getDocsCount("reports"));
    setSOCount(await getDocsCount("scouting-overview"));
    setBgOCount(await getDocsCount("background-overview"));
    // console.log(livePlayerCount,bgOverviewCount, scoutingOverviewCount);
  };
  useEffect(() => {
    getCounts();
  }, []);

  return (
    <div className={styles.content}>
      <div>
        <div>
          <p>Total Players</p>
          <span>{livePlayerCount}</span>
        </div>
        {/*
        <div>
          <p>Total Archive Players</p>
          <span>--</span>
        </div>
        */}
        <div>
          <p>Total Reports</p>
          <span>{liveNoteCount}</span>
        </div>
        {/*
        <div>
          <p>Total Archive Notes</p>
          <span>--</span>
        </div>
        */}
        <div>
          <p>Background Overviews</p>
          <span>{bgOverviewCount}</span>
        </div>
        <div>
          <p>Scouting Overviews</p>
          <span>{scoutingOverviewCount}</span>
        </div>
      </div>
      <ChatContainer />
    </div>
  );
}
