import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { useFormik } from "formik";
import { toast } from "react-toastify";

// firebase
import { db, storage } from "../../../firebase";

export default function AddModal({ setOpen, reloadData }) {
  const close = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      type: "",
      date: "",
      file: null,
    },
    validate: (values) => {
      const errors = {};
      if (!values.title) {
        errors.title = "Required";
      }
      if (!values.type) {
        errors.type = "Required";
      }
      if (!values.date) {
        errors.date = "Required";
      }
      if (!values.file) {
        errors.file = "Required";
      }
      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      const { title, type, date, file } = values;

      try {
        // Upload PDF file to Firebase Storage in the folder named '/Resources'
        const storageRef = storage.ref("/Resources");
        const fileRef = storageRef.child(file.name);
        await fileRef.put(file);

        // Get the download URL of the uploaded file
        const downloadURL = await fileRef.getDownloadURL();

        // Add metadata to Firestore
        await db.collection("resources").add({
          title,
          type,
          date,
          pdfUrl: downloadURL,
        });

        toast.info("Resource Item Created Successfully", { theme: "colored" });
        reloadData();
        close();
      } catch (error) {
        console.error("Error adding document: ", error);
        toast.error("Error Creating Resources Item", { theme: "colored" });
      }
    },
  });

  return (
    <div className={styles.modalContainer}>
      <div onClick={close} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={close}>
          <MdClose />
        </button>
        <form onSubmit={formik.handleSubmit} className="w-100">
          <div className={styles.content}>
            <h3>Resources</h3>
            <span>This section is used to add a new resource.</span>
            <hr />
            <div className={styles.inputs}>
              <span>Title</span>
              <input
                type="text"
                name="title"
                onChange={formik.handleChange}
                value={formik.values.title}
              />
              <span className={styles.error}>
                {formik.errors.title &&
                  formik.touched.title &&
                  formik.errors.title}
              </span>
              
              
              <label>
                Type
                <select
                  name="type"
                  onChange={formik.handleChange}
                  value={formik.values.type}
                >
                  <option value="" label="Select type" />
                  <option value="FAQs" label="FAQs" />
                  <option value="templates" label="Templates" />
                  <option value="how-tos" label="How-to's" />
                  <option value="onboarding-docs" label="Onboarding Docs" />
                  <option value="other" label="Other" />
                </select>
              </label>
              
              <span>Date</span>
              <input
                type="date"
                name="date"
                onChange={formik.handleChange}
                value={formik.values.date}
              />
              <span className={styles.error}>
                {formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date}
              </span>

              <span>PDF</span>
              <input
                type="file"
                name="file"
                onChange={(event) => {
                  formik.setFieldValue("file", event.currentTarget.files[0]);
                }}
              />
              <span className={styles.error}>
                {formik.errors.file &&
                  formik.touched.file &&
                  formik.errors.file}
              </span>
            </div>
          </div>
          <div className={styles.btns}>
            <button onClick={close}>Cancel</button>
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}
