import styles from "./preview.module.scss";
import { Col, Row } from "react-bootstrap";

//import { AiFillBulb } from "react-icons/ai";

export const BodyTitle = ({ title }) => {
  return (
    <div className={styles.bodyTitle}>
      <h1>{title}</h1>
      <hr />
    </div>
  );
};

export const BodyDescCol = ({ data }) => {
  return (
    <div className={styles.bodyDescCol}>
      {Object.keys(data).map((el) => (
        <>
          <h2>{data[el].title}</h2>
          <Row>
            {data[el].list.map((item) => (
              <Col>
                <ul>
                  <li className={styles.listHead}>{item.title}</li>
                  {item.list.map((itemli) => (
                    <li>{itemli}</li>
                  ))}
                </ul>
              </Col>
            ))}
          </Row>
        </>
      ))}
    </div>
  );
};

export const BodyColList = ({ data, cols, noIcon }) => {
  return (
    <div className={styles.bodyDescCol}>
      {Object.keys(data).map((el) => (
        data[el].list.length > 0 &&
        <>
          <h2>{data[el].title}</h2>
          <Row>
            {data[el].list.map((item) => (
              <Col sm={12} md={cols}>
                <h5 className={styles.colListDesc}>
                  {!noIcon && (
                    <span style={{ marginRight: "10px"}}  role="img" aria-label="bulb">💡</span>
                  )}
                  <span>{item}</span>
                </h5>
              </Col>
            ))}
          </Row>
        </>
      ))}
    </div>
  );
};

export const BodySocial = ({ data }) => {
  return (
    <div className={styles.bodyDescCol}>
      <Row>
        <Col md={4}>
          {Object.keys(data).map((el) => (
            data[el].social.tag !== "" &&
            <>
              <h2>{data[el].title}</h2>
              <ul>
                <li>
                  <h5 className={styles.colListDesc}>{data[el].social.tag}</h5>
                  <h5 className={styles.colListDesc}>
                    {data[el].social.followers}
                  </h5>
                </li>
              </ul>
            </>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export const BodyList = ({ data }) => {
  return (
    <div className={styles.bodyDescCol}>
      <div>
        {Object.keys(data).map((el) => (
          data[el].list.length > 0 && <>
            <h2>{data[el].title}</h2>

            <ul
              style={{
                listStyle: "none",
              }}
            >
              {data[el].list.map((item) => (
                <li>
                  <h5 className={styles.colListDesc}>
                  <span style={{ marginRight: "10px"}}  role="img" aria-label="bulb">💡</span>
                    <span>{item}</span>
                  </h5>
                </li>
              ))}
            </ul>
          </>
        ))}
      </div>
    </div>
  );
};
