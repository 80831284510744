import React from "react";
import { Modal, Button } from "react-bootstrap";
import MediaLibrary from "../componentsPage/mediaLibrary/medialibrary";

export default function MediaLibraryModal({ show, handleClose, handleSelect }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Select Media</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MediaLibrary noheader handleSelect={handleSelect} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button
            variant="danger"
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
          >
            Confirm
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
