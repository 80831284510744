import Layout from "../../../components/layout/layout.js";

import Packets from "../../../components/PIPackets/index.js";

function ProPacketPage({ component, ...rest }) {
  return (
    <Layout Component={Packets} controlsDisabled={true} {...rest} />
  );
}

export default ProPacketPage;
