import React, { useState } from "react";
import Header from "../header/header";

// react bootstrap
import { Row, Col, Button, Modal } from "react-bootstrap";

import { AiOutlinePlus } from "react-icons/ai";

// styles
import styles from "./stats.module.scss";

import {
  MdModeEdit,
  MdRemoveRedEye,
  MdRestoreFromTrash,
  MdCloudUpload,
  MdVideocam,
} from "react-icons/md";

const data = [
  {
    year: "2016",
    league: "NCAA",
    team: "Duke",
    g: "12",
    min: "150",
    mpg: "28",
    ppg: "15.6",
    twoP: "20.1",
    threeP: "20.1",
    ft: "23.2",
    orpg: "3.4",
    rpg: "9",
    apg: "1.5",
    topg: "2.1",
    spg: "1.2.",
    bpg: "2.5",
    action: "",
  },
];

const emptyobj = {
  year: "",
  league: "",
  team: "",
  g: "",
  min: "",
  mpg: "",
  ppg: "",
  twoP: "",
  threeP: "",
  ft: "",
  orpg: "",
  rpg: "",
  apg: "",
  topg: "",
  spg: "",
  bpg: "",
  action: "",
};

export const AddPage = () => {
  const [statsTableData, setStatsTableData] = useState(data);
  const [show, setShow] = useState({ status: false, index: null });

  const handleConfirm = () => {
    const newData = [...statsTableData];
    newData.splice(show.index, 1);
    setStatsTableData(newData);
    setShow({ status: false, index: null });
  };
  const handleOpen = (index) => {
    setShow({ status: true, index });
  };
  const handleClose = () => {
    setShow({ status: false, index: null });
  };
  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <h4>Add Stats</h4>
          </div>
        </div>

        <Row>
          <Col md={4}>
            <div className={styles.field}>
              <p>Player Profile</p>
              <select name="player-profile" id="player-profile">
                <option value="">Select Player Profile</option>
                <option value="1">Player Profile 1</option>
                <option value="2">Player Profile 2</option>
              </select>
            </div>
          </Col>
          <Col md={12}>
            <table className={styles.table} >
              <thead>
                <tr>
                  <th>Year</th>
                  <th>League</th>
                  <th>Team</th>
                  <th>G</th>
                  <th>Min</th>
                  <th>MPG</th>
                  <th>PPG</th>
                  <th>2P%</th>
                  <th>3P%</th>
                  <th>FT%</th>
                  <th>ORPG</th>
                  <th>RPG</th>
                  <th>APG</th>
                  <th>TOPG</th>
                  <th>SPG</th>
                  <th>BPG</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {statsTableData.map((items, index) => (
                  <TableRow
                    index={index}
                    items={items}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    statsTableData={statsTableData}
                    setStatsTableData={setStatsTableData}
                  />
                ))}
                <tr>
                  <td colSpan="16">
                    <button
                      className={styles.buttonAdd}
                      onClick={() => {
                        setStatsTableData([...statsTableData, emptyobj]);
                      }}
                    >
                      <AiOutlinePlus />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col md={12}>
            <div className={styles.buttonContainer}>
              <Row>
                <Col md={6}>
                  <Button className={styles.secondaryButton}>Cancel</Button>
                </Col>
                <Col md={6}>
                  <Button>Upload</Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <ConfirmModal
        show={show}
        handleClose={() => setShow(false)}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

const TableRow = ({
  index,
  items,
  statsTableData,
  setStatsTableData,
  handleOpen,
}) => {
  // all empty check
  const isEmpty = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
      if (value !== "") return false;
    }
    return true;
  };

  const [editToggle, setEditToggle] = useState(isEmpty(items) ? false : true);

  return (
    <tr key={index}>
      {Object.entries(items).map(([key, value]) => {
        return key === "action" ? (
          <td>
            <div className={styles.actionIcon}>
              {/* <MdRemoveRedEye /> */}
              <MdModeEdit
                style={{
                  color: "green",
                }}
                onClick={() => {
                  setEditToggle(!editToggle);
                }}
              />
              <MdRestoreFromTrash
                style={{
                  color: "red",
                }}
                onClick={() => {
                  handleOpen(index);
                }}
              />
            </div>
          </td>
        ) : (
          <td>
            <input
              value={value}
              disabled={editToggle}
              style={{
                border: !editToggle ? "1px solid #ddd" : "none",
              }}
              onChange={(e) => {
                const newData = [...statsTableData];
                newData[index][key] = e.target.value;
                setStatsTableData(newData);
              }}
            />
          </td>
        );
      })}
    </tr>
  );
};

const ConfirmModal = ({ show, handleClose, handleConfirm }) => {
  return (
    <Modal
      show={show.status}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this stat?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button onClick={handleConfirm} variant="primary">
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
