import React, { useEffect, useState, useCallback, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import DeleteModal from "../../shared/DeleteModal";
import { MdOutlineCancel } from 'react-icons/md';
import { HiPlusSm } from 'react-icons/hi';

// styles
import styles from "../Form.module.scss";

export function Awards({ years, setFieldValue, awards, handleChange, handleBlur }) {
    useEffect(()=>{
        awards.length === 0 &&  setFieldValue("awards", [{ year: "", awards: "" }])
    }, [awards.length])
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const AddAward = (index) => {
        setFieldValue("awards",
            [
                ...awards.slice(0, index + 1),
                { year: "", awards: "" },
                ...awards.slice(index + 1),
            ]
        );
    }
    const ConfirmDelete = (index) => {
        setFieldValue("awards", [
            ...awards.filter((award, ind) => ind !== index),
        ])
    }
    const DeleteAward = (index) => {
        if (awards.length === 1) return;
        setDeleteIndex(index);
        setDeleteConfirm(true);
    }
    return (
        <>
            <DeleteModal
                show={deleteConfirm}
                handleConfirm={() => { ConfirmDelete(deleteIndex) }}
                handleClose={() => setDeleteConfirm(false)}
            />
            <div className={styles.container}>
                <div className={styles.title}>
                    <div>
                        <h4>Awards & Honors</h4>
                        <subtitle>
                            This section is used to add awards. Please fill as many sections as possible.
                        </subtitle>
                    </div>
                </div>

                {awards.length > 0 &&
                    awards.map((award, index) =>
                        <Award key={index} years={years} DeleteAward={DeleteAward} setDeleteIndex={setDeleteIndex} AddAward={AddAward} award={award} handleChange={handleChange} index={index} handleBlur={handleBlur} />
                    )
                }
            </div>
        </>
    )
}

const Award = ({ years, AddAward, DeleteAward, award, handleChange, handleBlur, index }) => {
    return (
        <Row>
            <Col md={4}>
                <div className={styles.field}>
                    {index === 0 && <p>Year</p>}
                    <select className={styles.yearDropdown} name={`awards[${index}].year`} value={award.year} onChange={handleChange} onBlur={handleBlur} >
                        <option value="" >Select Year</option>
                        {years.map((y, ind) =>
                            <option key={ind}>{y}</option>
                        )}
                    </select>
                </div>
            </Col>
            <Col md={8}>
                <div className={styles.field}>
                    {index === 0 && <p>Award(s)</p>}
                    <div className={styles.btns}>
                        <input type="text" name={`awards[${index}].awards`} value={award.awards} onChange={handleChange} onBlur={handleBlur} />
                        <button className={`${styles.green} ${styles.small}`} onClick={() => { AddAward(index) }} ><HiPlusSm /></button>
                        <button className={`${styles.red} ${styles.small}`} onClick={() => { DeleteAward(index) }} ><MdOutlineCancel /></button>
                    </div>

                </div>
            </Col>
            {/* <Col md={6}>
                <div className={styles.field}>
                    {index === 0 && <p>Award(s)</p>}
                    <input type="text" name={`awards[${index}].awards`} value={award.awards} onChange={handleChange} onBlur={handleBlur} />
                </div>
            </Col>
            <Col md={2}>
                {
                    index !== 0 &&
                    <div className={styles.btns}>
                        <button className={`${styles.green} ${styles.small}`} onClick={() => { AddAward(index) }} ><HiPlusSm /></button>
                        <button className={`${styles.red} ${styles.small}`} onClick={() => { DeleteAward(index) }} ><MdOutlineCancel /></button>
                    </div>
                }
            </Col> */}
        </Row >

    )
}

