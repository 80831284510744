import React from "react";

import Layout from "../../../components/layout/layout";
import { PlayerProfileDetail } from "../../../components/PlayerProfileDetail/PlayerProfileDetail";

export default function ProfileDetail({ component, ...rest }) {
  return (
    <Layout Component={PlayerProfileDetail} controlsDisabled={true} {...rest} />
  );
}
