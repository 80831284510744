import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { EditBgOverviewModal } from './EditBgOverviewModal'
import DeleteModal from "../../shared/DeleteModal";
import moment from 'moment';
// firebase
import { db } from "../../../firebase";

// styles
import styles from "../detail.module.scss";

import { Link } from "react-router-dom";

// icons
import { FaSearch } from "react-icons/fa";
import {
    MdModeEdit,
    MdRemoveRedEye,
    MdRestoreFromTrash,
} from "react-icons/md";
import log_activity from "../../activity/logger";

export function BackgroundOverview({ playerID, playerName, userInfo }) {
    const [backgroundOverview, setBackgroundOverview] = useState(null);
    const [selectedEditBgOverview, setSelectedEditBgOverview] = useState(false);
    const [selectedDeleteBgOverview, setSelectedDeleteBgOverview] = useState(false);
  
    const getBackgroundOverview = async () => {
      try {
        const response = await db.collection("background-overview").where("playerProfile", "==", playerID).get();
        response.docs[0] && setBackgroundOverview({ ...response.docs[0].data(), id: response.docs[0].id });
      } catch (error) {
        console.log(error, "error");
      }
    }
  
    const handleDelete = () => {
      db.collection("background-overview")
        .where("playerProfile", "==", playerID)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs[0].ref.delete()
            .then(() => {
              toast.error("Background Overview Deleted Successfully", {
                theme: "colored",
              });
              log_activity(playerName, "Background Overview Was Deleted")
              setBackgroundOverview(null);
            })
        })
        .catch((error) => {
          console.error(error)
          toast.error("Error Deleting Background Overview", {
            theme: "colored",
          });
        });
    };
  
    useEffect(() => {
      getBackgroundOverview();
    }, [playerID]); // Include playerID in the dependency array
  
    return (
      <>
        <DeleteModal
          handleClose={() => {
            setSelectedDeleteBgOverview(false);
            getBackgroundOverview();
          }}
          show={selectedDeleteBgOverview}
          handleConfirm={() => {
            handleDelete();
          }}
        />
        {/* Edit BgOverview Section */}
        {selectedEditBgOverview && (
          <EditBgOverviewModal
            show={selectedEditBgOverview}
            handleClose={() => {
              setSelectedEditBgOverview(false);
              getBackgroundOverview();
            }}
            bgOverviewData={backgroundOverview}
          />
        )}
        <div className={styles.singleSection}>
          <h2>Background Overview</h2>
          {backgroundOverview ? (
            <div>
              {/*
              <MdRemoveRedEye />
              */}
              <MdModeEdit
                onClick={() => setSelectedEditBgOverview(true)}
                style={{
                  color: "green",
                  marginLeft: "10px",
                }}
              />
              <MdRestoreFromTrash
                onClick={() => setSelectedDeleteBgOverview(true)}
                style={{
                  color: "red",
                  marginLeft: "10px",
                }}
              />
            </div>
          ) : (
            <div className={styles.backgroundOverviewNotAvailable}>
                <Link 
                    to={{
                        pathname: "/background-overview/create",
                        state: { userInfo }
                    }}
                >
                    Background Overview not available, please create one using this link
                </Link>
            </div>
          )}
        </div>
      </>
    );
  };