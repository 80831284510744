import { useState, useEffect } from "react";
import styles from "./RoleDropdown.module.scss";
import Header from "../../header/header";
import { FaSortAmountDownAlt } from "react-icons/fa";
import AddModal from "./addModal";
import EditModal from "./editModal";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import { CustomSpinner } from "../../shared/Spinner";
import { MdModeEdit, MdRemoveRedEye, MdRestoreFromTrash } from "react-icons/md";
// Collection Delete
import DeleteCollectionModal from "../../shared/DeleteCollection";
import DeleteModal from "../../shared/DeleteModal";

export default function RoleDropdown() {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState(null);
  const [editModelToggle, setEditModelToggle] = useState(false);
  const [selectedDeleteRole, setSelectedDeleteRole] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteCollectionModal, setShowDeleteCollectionModal] = useState(false);

  useEffect(() => {
    getRoleData();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    db.collection("roles-dropdown")
      .doc(id)
      .delete()
      .then(() => {
        setLoading(false);
        toast.error("Role Item Deleted Successfully", {
          theme: "colored",
        });
        getRoleData();
      })
      .catch((error) => {
        toast.error("Error Deleting Role Item", {
          theme: "colored",
        });
      });
  };

  const deleteCollection = () => {
    setLoading(true);
    db.collection("roles-dropdown")
      .get()
      .then((querySnapshot) => {
        const batch = db.batch();
        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
        return batch.commit();
      })
      .then(() => {
        setLoading(false);
        toast.success("Roles Collection Deleted Successfully", {
          theme: "colored",
        });
        setRoleData([]);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error Deleting Roles Collection", {
          theme: "colored",
        });
      });
  };

  const getRoleData = async () => {
    setLoading(true);
    try {
      const response = await db.collection("roles-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setRoleData(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  const importData = (file) => {
    setLoading(true);
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileData = event.target.result;
      const lines = fileData.split(/\r\n|\n/);
      const headers = lines[0].split(",");
      const importedData = [];
  
      for (let i = 1; i < lines.length; i++) {
        const row = lines[i].split(",");
        if (row.length === headers.length) {
          const rowData = {};
          for (let j = 0; j < headers.length; j++) {
            rowData[headers[j]] = row[j];
          }
          importedData.push(rowData);
        }
      }
  
      try {
        const batch = db.batch();
        importedData.forEach((data) => {
          const docRef = db.collection("roles-dropdown").doc(data.id || db.collection("roles-dropdown").doc().id ); // Use the ID from the imported data
          batch.set(docRef, data, { merge: true }); // Use merge option to overwrite existing data
        });
        await batch.commit();
        setLoading(false);
        toast.success("Data Imported Successfully", {
          theme: "colored",
        });
        getRoleData();
      } catch (error) {
        setLoading(false);
        toast.error("Error Importing Data", {
          theme: "colored",
        });
      }
    };
  
    reader.readAsText(file);
  };
  

  const exportData = () => {
    console.log(roleData);
    const csvContent =
      "data:text/csv;charset=utf-8," +
      "id,name,value\n" +
      roleData.map((item) => `${item.id || ""},${item.name},${item.value}`).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "roles.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <CustomSpinner />;
  }

  if (roleData === null && !loading) {
    return <div>Error loading role data</div>;
  }

 // sort data asc
 //const sortedRoleData = roleData.slice().sort((a, b) => a.value.localeCompare(b.value));
 // numberical order
 const sortedRoleData = roleData.slice().sort((a, b) => a.value - b.value);


  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <div className={styles.title}>
          <h4>Role</h4>
          <p>This section is used to edit role dropdown menu</p>
        </div>
        <hr />
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div>
              <span>Total</span>
              <span>{roleData ? roleData.length : "0"}</span>
            </div>
            <div className={styles.importButton}>
              <input
                type="file"
                id="csvFile"
                accept=".csv"
                onChange={(e) => importData(e.target.files[0])}
                style={{ display: "none" }}
              />
              <label htmlFor="csvFile" className={styles.importButtonLabel}>
                Import CSV File
              </label>
            </div>

            <div>
              <button onClick={exportData} className={styles.exportButton}>
                Export Data
              </button>
            </div>
            <button
              onClick={() => {
                setOpenEditModal(true);
              }}
            >
              Add Item
            </button>
            <button
              onClick={() => {
                setShowDeleteCollectionModal(true);
              }}
              className={styles.deleteButton}
            >
              Delete Collection
            </button>
          </div>
          <div className={styles.row}>
            <div>Order </div>
            <div>Role</div>
            <div>Actions</div>
          </div>
          {sortedRoleData.map((item) => (
            <div key={item.id} className={styles.row}>
              <div>{item.value}</div>
              <div>{item.name}</div>
              <div>
                <MdModeEdit
                  onClick={() => {
                    setEditModelToggle(item);
                  }}
                />
                <MdRestoreFromTrash
                  onClick={() => {
                   setSelectedDeleteRole(item.id);                
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <DeleteCollectionModal
        handleClose={() => {
          setShowDeleteCollectionModal(false);
        }}
        show={showDeleteCollectionModal}
        handleConfirm={deleteCollection}
        title="Delete Collection"
        message="Are you sure you want to delete the entire collection? This action cannot be undone."
      />

        <DeleteModal
        handleClose={() => {
          setSelectedDeleteRole(false);
        }}
        show={selectedDeleteRole}
        handleConfirm={() => {
          handleDelete(selectedDeleteRole);
        }}
        title="Delete Item"
        message="Are you sure you want to delete the item? This action cannot be undone."
      />
      {openEditModal && (
        <AddModal setOpen={setOpenEditModal} reloadData={getRoleData} />
      )}
      {editModelToggle && (
        <EditModal
          data={editModelToggle}
          setOpen={setEditModelToggle}
          reloadData={getRoleData}
        />
      )}
    </div>
  );
}
