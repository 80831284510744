import { useState, useEffect } from "react";
import styles from "./Height.module.scss";
import Header from "../../header/header";
import { FaSortAmountDownAlt } from "react-icons/fa";
import AddModal from "./addModal";
import EditModal from "./editModal";
import { toast } from "react-toastify";
import { db } from "../../../firebase";
import { CustomSpinner } from "../../shared/Spinner";
import { MdModeEdit, MdRemoveRedEye, MdRestoreFromTrash } from "react-icons/md";
import DeleteModal from "../../shared/DeleteModal";
import DeleteCollectionModal from "../../shared/DeleteCollection";

export default function HeightDropdown() {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [HeightData, setHeightData] = useState(null);
  const [editModelToggle, setEditModelToggle] = useState(false);
  const [selectedDeleteHeight, setSelectedDeleteHeight] = useState(false);
  const [showDeleteCollectionModal, setShowDeleteCollectionModal] = useState(false);

  useEffect(() => {
    getHeightData();
  }, []);

  const handleDelete = (id) => {
    setLoading(true);
    db.collection("height-dropdown")
      .doc(id)
      .delete()
      .then(() => {
        setLoading(false);
        toast.error("Height Item Deleted Successfully", {
          theme: "colored",
        });
        getHeightData();
      })
      .catch((error) => {
        toast.error("Error Deleting Height Item", {
          theme: "colored",
        });
      });
  };

  const deleteCollection = () => {
    setLoading(true);
    db.collection("height-dropdown")
      .get()
      .then((querySnapshot) => {
        const batch = db.batch();
        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
        return batch.commit();
      })
      .then(() => {
        setLoading(false);
        toast.success("Height Collection Deleted Successfully", {
          theme: "colored",
        });
        setHeightData([]);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error Deleting Height Collection", {
          theme: "colored",
        });
      });
  };

  const getHeightData = async () => {
    setLoading(true);
    try {
      const response = await db.collection("height-dropdown").get();
      const data = response.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setHeightData(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  const importData = (file) => {
    setLoading(true);
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileData = event.target.result;
      const lines = fileData.split(/\r\n|\n/);
      const headers = lines[0].split(",");
      const importedData = [];

      for (let i = 1; i < lines.length; i++) {
        const row = lines[i].split(",");
        if (row.length === headers.length) {
          const rowData = {};
          for (let j = 0; j < headers.length; j++) {
            rowData[headers[j]] = row[j];
          }
          importedData.push(rowData);
        }
      }

      try {
        const batch = db.batch();
        importedData.forEach((data) => {
          const docRef = db
            .collection("height-dropdown")
            .doc(data.id || db.collection("height-dropdown").doc().id);
          batch.set(docRef, data, { merge: true });
        });
        await batch.commit();
        setLoading(false);
        toast.success("Data Imported Successfully", {
          theme: "colored",
        });
        getHeightData();
      } catch (error) {
        setLoading(false);
        toast.error("Error Importing Data", {
          theme: "colored",
        });
      }
    };

    reader.readAsText(file);
  };

  const exportData = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      "id,name,value\n" +
      HeightData.map((item) => `${item.id || ""},${item.name},${item.value}`).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "height.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return <CustomSpinner />;
  }

  if (HeightData === null && !loading) {
    return <div>Error loading Height data</div>;
  }

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <div className={styles.title}>
          <h4>Height</h4>
          <p>This section is used to edit Height dropdown menu</p>
        </div>
        <hr />
        <div className={styles.table}>
          <div className={styles.tableHeader}>
            <div>
              <span>Total</span>
              <span>{HeightData ? HeightData.length : "0"}</span>
            </div>
            <div className={styles.importButton}>
              <input
                type="file"
                id="csvFile"
                accept=".csv"
                onChange={(e) => importData(e.target.files[0])}
                style={{ display: "none" }}
              />
              <label htmlFor="csvFile" className={styles.importButtonLabel}>
                Import CSV File
              </label>
            </div>
            <div>
              <button onClick={exportData} className={styles.exportButton}>
                Export Data
              </button>
            </div>
            <button
              onClick={() => {
                setShowDeleteCollectionModal(true);
              }}
              className={styles.deleteButton}
            >
              Delete Collection
            </button>
            <button
              onClick={() => {
                setOpenEditModal(true);
              }}
            >
              Add Item
            </button>
          </div>
          <div className={styles.row}>
            <div>
              Height<FaSortAmountDownAlt />
            </div>
            <div>Abbreviation</div>
            <div>Actions</div>
          </div>
          {HeightData.map((item) => (
            <div key={item.id} className={styles.row}>
              <div>{item.name}</div>
              <div>{item.value}</div>
              <div>
                <MdModeEdit
                  onClick={() => {
                    setEditModelToggle(item);
                  }}
                />
                <MdRestoreFromTrash
                  onClick={() => {
                    setSelectedDeleteHeight(item.id);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <DeleteCollectionModal
        handleClose={() => {
          setShowDeleteCollectionModal(false);
        }}
        show={showDeleteCollectionModal}
        handleConfirm={deleteCollection}
        collectionName="height-dropdown"
      />

      <DeleteModal
        handleClose={() => {
          setSelectedDeleteHeight(false);
        }}
        show={selectedDeleteHeight}
        handleConfirm={() => {
          handleDelete(selectedDeleteHeight);
        }}
      />

      {openEditModal && (
        <AddModal setOpen={setOpenEditModal} reloadData={getHeightData} />
      )}

      {editModelToggle && (
        <EditModal
          data={editModelToggle}
          setOpen={setEditModelToggle}
          reloadData={getHeightData}
        />
      )}
    </div>
  );
}
