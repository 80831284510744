import React, { useEffect, useState, useCallback, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import DeleteModal from "../../shared/DeleteModal";
import { MdOutlineCancel } from 'react-icons/md';
import { HiPlusSm } from 'react-icons/hi';

// styles
import styles from "../Form.module.scss";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function Recruitment({ years, setFieldValue, recruitment, handleChange, handleBlur }) {
    useEffect(()=>{
        recruitment.notableOffers.length === 0 && setFieldValue("recruitment.notableOffers", [""]);
        recruitment.notableInterests.length === 0 && setFieldValue("recruitment.notableInterests", [""]);
        recruitment.notableVisits.length === 0 && setFieldValue("recruitment.notableVisits", [""]);
    },[recruitment.notableOffers.length, recruitment.notableInterests.length, recruitment.notableVisits.length])
    
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const Add = (index, fieldName) => {
        console.log(index, fieldName);
        setFieldValue(`recruitment.${fieldName}`,
            [
                ...recruitment[fieldName].slice(0, index + 1),
                "",
                ...recruitment[fieldName].slice(index + 1),
            ]
        );
    }
    const ConfirmDelete = (index, fieldName) => {
        setFieldValue(`recruitment.${fieldName}`, [
            ...recruitment[fieldName].filter((_, ind) => ind !== index),
        ])
    }
    const Delete = (index, fieldName) => {
        if (recruitment[fieldName].length === 1) return;
        setDeleteConfirm(true);
        setDeleteIndex({ index: index, fieldName: fieldName });
    }
    return (
        <>
            <DeleteModal
                show={deleteConfirm}
                handleConfirm={() => { ConfirmDelete(deleteIndex.index, deleteIndex.fieldName) }}
                handleClose={() => setDeleteConfirm(false)}
            />
            <div className={styles.container}>
                <div className={styles.title}>
                    <div>
                        <h4>Recruitment</h4>
                        <subtitle>
                            This section is used to add recruitment information. Please fill as many sections as possible.
                        </subtitle>
                    </div>
                </div>
                <Row>
                    <Col md={4}>
                        <div className={styles.field}>
                            <p>Commitment (Team)</p>
                            <input type="text" name="recruitment.commitment" value={recruitment.commitment} onChange={handleChange} onBlur={handleBlur} />
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={styles.field}>
                            <p>Month</p>
                            <select name="recruitment.month" className={styles.yearDropdown} value={recruitment.month} onChange={handleChange} onBlur={handleBlur} >
                                <option value="">Select Month</option>
                                {months.map((m, ind) =>
                                    <option key={ind}>{m}</option>
                                )}
                            </select>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={styles.field}>
                            <p>Year</p>
                            <select name="recruitment.year" className={styles.yearDropdown} value={recruitment.year} onChange={handleChange} onBlur={handleBlur} >
                                <option  value="" >Select Year</option>
                                {years.map((y, ind) =>
                                    <option key={ind}>{y}</option>
                                )}
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className={styles.field}>
                            <p>Visits</p>
                            {recruitment.notableVisits.length > 0 &&
                                recruitment.notableVisits.map((nv, index) =>
                                    <div className={styles.btns} key={index}>
                                        <input type="text" name={`recruitment.notableVisits[${index}]`} value={nv} onChange={handleChange} onBlur={handleBlur} />
                                        <button className={`${styles.green} ${styles.small}`} onClick={() => { Add(index, "notableVisits") }} ><HiPlusSm /></button>
                                        <button className={`${styles.red} ${styles.small}`} onClick={() => { Delete(index, "notableVisits") }} ><MdOutlineCancel /></button>
                                    </div>
                                ) 
                            }
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={styles.field}>
                            <p>Offers</p>
                            {recruitment.notableOffers.length > 0 &&
                                recruitment.notableOffers.map((no, index) =>
                                    <div className={styles.btns} key={index} >
                                        <input type="text" name={`recruitment.notableOffers[${index}]`} value={no} onChange={handleChange} onBlur={handleBlur} />
                                        <button className={`${styles.green} ${styles.small}`} onClick={() => { Add(index, "notableOffers") }} ><HiPlusSm /></button>
                                        <button className={`${styles.red} ${styles.small}`} onClick={() => { Delete(index, "notableOffers") }} ><MdOutlineCancel /></button>
                                    </div>
                                ) 
                            }

                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={styles.field}>
                            <p>Interest</p>
                            {recruitment.notableInterests.length > 0 &&
                                recruitment.notableInterests.map((ni, index) =>
                                    <div className={styles.btns} key={index}>
                                        <input type="text" name={`recruitment.notableInterests[${index}]`} value={ni} onChange={handleChange} onBlur={handleBlur} />
                                        <button className={`${styles.green} ${styles.small}`} onClick={() => { Add(index, "notableInterests") }} ><HiPlusSm /></button>
                                        <button className={`${styles.red} ${styles.small}`} onClick={() => { Delete(index, "notableInterests") }} ><MdOutlineCancel /></button>
                                    </div>
                                )
                            }

                        </div>
                    </Col>
                </Row>
                <div className={styles.field}>
                    <p>Recruitment Notes</p>
                    <textarea name="recruitment.notes" value={recruitment.notes} onChange={handleChange} onBlur={handleBlur} rows={6}></textarea>
                </div>
            </div>
        </>
    )
}
