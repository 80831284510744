import { useEffect, useState } from "react";
import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { ListGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";

// firebase
import { db } from "../../firebase";

export const SelectCompareProfile = ({ id, handleClose }) => {
  const history = useHistory();
  const { isAdmin, isSuperAdmin, getUserInfoData } = useAuth();

  const [profileData, setProfileData] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const close = () => {
    // setOpen(false);
    handleClose();
  };

  useEffect(() => {
    handleGetPlayerProfiles();
  }, []);

  const handleGetPlayerProfiles = async () => {
    const response = await db.collection("player-profile").get();
    const data = response.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });

    let filteredData;
    console.log(isAdmin(), isSuperAdmin(), data, "admin");
    if (isAdmin() || isSuperAdmin()) {
      filteredData = data;
    } else {
      filteredData = data.filter((el) => {
        if (
          el["viewing_privileges"] &&
          el["viewing_privileges"].includes(getUserInfoData().memberType)
        ) {
          return el;
        }
      });
    }

    console.log(data, "response for profile data");
    setProfileData(filteredData);
  };

  const handleProfileSelect = (item) => {
    setSelectedProfile(item);
  };

  const handleConfirmCompare = () => {
    console.log("asdas", id, selectedProfile);
    handleClose();
    history.replace(`/player-profile/preview/${id}/${selectedProfile.id}`);
    window.location.reload();
  };

  return (
    <div className={styles.modalContainer}>
      <div onClick={close} className={styles.clickAway}></div>
      <div className={styles.modal}>
        <button onClick={close}>
          <MdClose />
        </button>

        <div className="w-100">
          <ListGroup>
            {profileData?.map((el) => (
              <ListGroup.Item
                variant={selectedProfile?.id === el.id ? "secondary" : ""}
                action
                onClick={() => handleProfileSelect(el)}
              >
                {el.FirstName} {el.LastName}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>

        <div className={styles.btns}>
          <button onClick={close}>Cancel</button>
          <button
            // type="submit"
            style={{
              background: !selectedProfile ? "grey" : "",
              cursor: !selectedProfile ? "crosshair" : "pointer",
            }}
            disabled={!selectedProfile}
            onClick={() => handleConfirmCompare()}
          >
            Compare
          </button>
        </div>
      </div>
    </div>
  );
};
