import Layout from "../../../components/layout/layout.js";

import YearDropdown from "../../../components/componentsPage/yearDropdown/index.js";

function YearDropdownPage({ component, ...rest }) {
  return (
    <Layout Component={YearDropdown} controlsDisabled={true} {...rest} />
  );
}

export default YearDropdownPage;
