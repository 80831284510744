import React, { useState, useEffect, useRef } from "react";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import Image from "react-bootstrap/Image";
import { CustomSpinner } from "../../shared/Spinner";
import Header from "../../header/header";
import styled from "styled-components";
import { Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import DropzonewithEdit from "../../article/dropzoneWithEdit";
import Viewer from "react-viewer";
import { saveAs } from "file-saver";

// firebase
import { db, storage } from "../../../firebase";
import DeleteModal from "../../shared/DeleteModal";

import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons

export default function MediaLibrary({ noheader, handleSelect }) {
  const [loading, setLoading] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const storageGet = getStorage();

  // Create a reference under which you want to list

  // if given "/" that will get all the images from the root
  // const storageRef = ref(storageGet, "/");

  useEffect(() => {
    getMediaData();
    return () => {};
  }, []);
  const [file, setFile] = useState("");
  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  };
  const ImageRef = useRef();

  useEffect(() => {
    return () => {};
  }, [mediaData]);

  // Find all the prefixes and items.
  const getMediaData = async () => {
    console.log("get run");
    setLoading(true);
    try {
      // listAll(storageRef).then((res) => {
      //   res.prefixes.forEach((folderRef) => {
      // let storageRefNew = ref(storageRef, folderRef.name);
      let storageRefNew = ref(storageGet, "/profile-headshots");
      let tempArray = [];
      listAll(storageRefNew).then(async (res) => {
        await Promise.all(
          res.items.map(async (itemRef) => {
            await getDownloadURL(itemRef).then((url) => {
              console.log("url", itemRef);

              tempArray.push({
                name: itemRef.name,
                image: url,
              });

              // setMediaData((prevState) => {
              //   return {
              //     ...prevState,
              //     [itemRef.name]: url,
              //   };
              // });
            });
          })
        );
        setMediaData(tempArray);
      });

      

      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
    }
  };

  const handleFireBaseUpload = (imageAsFile) => {
    console.log("start of upload");
    // async magic goes here...
    if (imageAsFile === "") {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    }
    const uploadTask = storage
      .ref(`/profile-headshots/${imageAsFile.name}`)
      .putString(imageAsFile.imageBase64, "data_url")
    //initiates the firebase side uploading
    // uploadTask.on(
    //   "state_changed",
    //   (snapShot) => {
    //     //takes a snap shot of the process as it is happening
    //     console.log(snapShot);
    //   },
    //   (err) => {
    //     //catches the errors
    //     console.log(err);
    //   },
    //   () => {
    //     // gets the functions from storage refences the image storage in firebase by the children
    //     // gets the download url then sets the image from firebase as the value for the imgUrl key:
    //     storage
    //       .ref("profile-headshots")
    //       .child(imageAsFile.name)
    //       .getDownloadURL()
          .then((fireBaseUrl) => {
            console.log("image uploaded and saved to firebase", fireBaseUrl);
            toast.info("Image Uploaded Successfully", {
              theme: "colored",
            });
            getMediaData();
            setFile(false);

            // db.collection("player-profile")
            //   .doc(docID)
            //   .update({
            //     ID: docID,
            //     Headshot: fireBaseUrl,
            //   })
            //   .then(() => {
            //     console.log("image uploaded and saved to firebase");
            //     toast.info("Player Profile Created Successfully", {
            //       theme: "colored",
            //     });
            //   })
            //   .catch((err) => {
            //     toast.error("Error Creating Player Profile", {
            //       theme: "colored",
            //     });

            //     console.log(err);
            //   });
          })
          .catch((err) => {
            toast.error("Error Uploading Image", {
              theme: "colored",
            });
            setFile(false);
            console.log(err);
          });
    //   }
    // );
    return uploadTask;
  };

  // if (!mediaData) {
  //   return <CustomSpinner />;
  // }

  const handleDeleteImage = (imageName) => {
    console.log(imageName);
    let storageRefNew = ref(storageGet, "/profile-headshots/" + imageName);
    deleteObject(storageRefNew)
      .then(() => {
        toast.info("Image Deleted Successfully", {
          theme: "colored",
        });
        getMediaData();
      })
      .catch((err) => {
        toast.error("Error Deleting Image", {
          theme: "colored",
        });
        console.log(err);
      });
    setDeleteConfirm(true);
  };

  return (
    <div>
      {!noheader && <Header />}
      {!mediaData ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <span>Loading...</span>
          <Spinner
            style={{
              marginLeft: "10px",
            }}
            animation="border"
          />
        </div>
      ) : null}
      {mediaData ? (
        <>
          <div className="d-flex justify-content-end mt-4">
            <input
              id="files"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={ImageRef}
              onChange={handleImageChange}
            />
            <Button variant="dark" onClick={() => ImageRef.current.click()}>
              Add Image
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
              alignContent: "center",
              flexDirection: "row",
              margin: "10px",
            }}
          >
            {mediaData.map((el, index) => {
              console.log(el, "el");
              return (
                <ImageCard
                  key={index.toString()}
                  name={el.name}
                  handleDeleteConfirm={() => setDeleteConfirm(el.name)}
                  image={el.image}
                  {...(handleSelect && { handleSelect: handleSelect })}
                />
              );
            })}
          </div>
        </>
      ) : null}
      <DeleteModal
        handleClose={() => {
          setDeleteConfirm(false);
        }}
        show={deleteConfirm}
        handleConfirm={() => {
          handleDeleteImage(deleteConfirm);
        }}
      />
      <DropzonewithEdit
        // isImgEditorShown={imageToggle}
        isImgEditorShown={file}
        closeImgEditor={() => {
          setFile(false);
        }}
        onSave={handleFireBaseUpload}
      />
    </div>
  );
}

const ImageCard = ({ name, image, handleSelect, handleDeleteConfirm }) => {
  const [visible, setVisible] = useState(false);
  return (
    <ImageCover>
      <div className="image-overlay">
        {handleSelect && (
          <Button variant="light" onClick={() => handleSelect(image)}>
            Select
          </Button>
        )}
        <Button variant="dark" onClick={() => setVisible(true)}>
          <i className="pi pi-eye"></i>
        </Button>
        <Button
          variant="dark"
          onClick={() => {
            saveAs(image, name + ".png");
          }}
        >
          <i className="pi pi-download"></i>
        </Button>
        <Button variant="dark" onClick={handleDeleteConfirm}>
          <i className="pi pi-trash"></i>
        </Button>
      </div>
      <Viewer
        attributes={false}
        noImgDetails={true}
        visible={visible}
        downloadable={false}
        changeable={false}
        drag={false}
        onClose={() => {
          setVisible(false);
        }}
        images={[{ src: image, alt: name }]}
      />
      <Image
        src={image}
        thumbnail
        style={{
          width: "200px",
          height: "200px",
          objectFit: "contain",
          objectPosition: "center",
        }}
      />
    </ImageCover>
  );
};

const ImageCover = styled.div`
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 200px;
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
    .image-overlay {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .image-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* display: flex; */
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    transition: all 0.3s ease-in-out;
  }
`;
