import styles from "./Modal.module.scss";
import { MdClose } from "react-icons/md";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Row, Col, Modal } from 'react-bootstrap';
import * as Yup from "yup";
// firebase
import { db } from "../../../firebase";
import { useState } from "react";

// Initialize Formik outside the component
const initialValues = {
  identifier: "",
  name: "",
  value: "",
  teamName: "",
  nbaAffil: "",
  state: "",
  coachFN: "",
  coachLN: "",
  phone: "",
  email: ""
};

const validationSchema = Yup.object().shape({
  identifier: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  value: Yup.string().required("Required"),
  state: Yup.string().when('identifier', {
    is: (val) => val === 'HS/Prep',
    then: Yup.string().required("Required"),
    otherwise: Yup.string()
  }),
  teamName: Yup.string().when('identifier', {
    is: (val) => val === 'NBA' || val === 'G League',
    then: Yup.string().required("Required"),
    otherwise: Yup.string()
  }),
  nbaAffil: Yup.string().when('identifier', {
    is: (val) => val === 'G League',
    then: Yup.string().required("Required"),
    otherwise: Yup.string()
  }),
  coachFN: Yup.string(),
  coachLN: Yup.string(),
  phone: Yup.string(),
  email: Yup.string().email("Invalid email"),
});

export default function AddSuggestionModal({ open, setOpen, reloadData }) {
  const [identifier, setIdentifier] = useState("");

  const close = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log("Form values submitted:", values);
      db.collection("team-suggestion-dropdown")
        .add({ ...values })
        .then((docRef) => {
          close();
          resetForm();
          toast.info("Team Request Sent Successfully", { theme: "colored" });
          reloadData();
        })
        .catch((error) => {
          toast.error("Error Sending Team Requst", { theme: "colored" });
          console.error("Error adding document: ", error); // Add this line
        });
    }
  });

  const handleIdentifierChange = (event) => {
    const newIdentifier = event.target.value;
    console.log("Selected Identifier Name:", newIdentifier);
    if (formik.dirty) {
      const confirmChange = window.confirm("Selecting a new identifier will clear any previous data entered. Are you sure you'd like to proceed?");
      if (confirmChange) {
        formik.resetForm(); // Reset formik values
        formik.setFieldValue('identifier', newIdentifier);
      } else {
        // No need to update state if user cancels
      }
    } else {
      formik.setFieldValue('identifier', newIdentifier);
    }
    setIdentifier(newIdentifier);
  };

  return (
    <Modal
      show={open}
      onHide={close}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={close}>
          <MdClose />
        </button>
        <form onSubmit={formik.handleSubmit} className="w-100">
          <div className={styles.content}>
            <h3> Schools and Teams Request</h3>
            <span>
              This section is used to request a new school and team for teams the dropdown.
            </span>
            <hr />
            <div className={styles.inputs}>
              <Row className="w-100 py-2">
                <Col md={6}>
                  <span>Identifier</span>
                  <select
                    name="identifier"
                    onChange={handleIdentifierChange}
                    value={identifier}
                    onBlur={formik.handleBlur}
                    className={styles.dropdownStyle}
                  >
                    <option value="">Choose Identifier</option>
                    <option value="HS/Prep">HS/Prep</option>
                    <option value="INTL">INT'L</option>
                    <option value="CBB">CBB</option>
                    <option value="NBA">NBA</option>
                    <option value="G League">G League</option>
                    <option value="Other">Other</option>
                  </select>
                  <span className={styles.error}>
                    {formik.errors.identifier && formik.touched.identifier && formik.errors.identifier}
                  </span>
                </Col>
              </Row>
              <Row className="w-100 py-2">
                <Col md={6}>
                  <span>
                    {identifier === "INTL"
                      ? "Team Name (Country)"
                      : identifier === "CBB" || identifier === "NBA" || identifier === "G League" || identifier === "Other"
                      ? "Team Name"
                      : "Team Name (State)"}
                  </span>
                  <input
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                  />
                  <span className={styles.error}>
                    {formik.errors.name && formik.touched.name && formik.errors.name}
                  </span>
                </Col>
                <Col md={6}>
                  <span>Team Name</span>
                  <input
                    className={styles.abbInput}
                    type="text"
                    name="value"
                    onChange={formik.handleChange}
                    value={formik.values.value}
                    onBlur={formik.handleBlur}
                  />
                  <span className={styles.error}>
                    {formik.errors.value && formik.touched.value && formik.errors.value}
                  </span>
                </Col>
              </Row>
              {(identifier === "NBA" || identifier === "G League") && (
                <Row className="w-100 py-2">
                  <Col md={6}>
                    <span>Full Team Name</span>
                    <input
                      type="text"
                      name="teamName"
                      onChange={formik.handleChange}
                      value={formik.values.teamName}
                      onBlur={formik.handleBlur}
                    />
                    <span className={styles.error}>
                      {formik.errors.teamName && formik.touched.teamName && formik.errors.teamName}
                    </span>
                  </Col>
                  {identifier === "G League" && (
                    <Col md={6}>
                      <span>NBA Affiliation</span>
                      <input
                        className={styles.abbInput}
                        type="text"
                        name="nbaAffil"
                        onChange={formik.handleChange}
                        value={formik.values.nbaAffil}
                        onBlur={formik.handleBlur}
                      />
                      <span className={styles.error}>
                        {formik.errors.nbaAffil && formik.touched.nbaAffil && formik.errors.nbaAffil}
                      </span>
                    </Col>
                  )}
                </Row>
              )}
              {(identifier !== "CBB" && identifier !== "NBA" && identifier !== "G League") && (
                <Row className="w-100 py-2">
                  <Col md={6}>
                    <span>
                      {identifier === "INTL"
                        ? "Country"
                        : identifier === "Other"
                        ? "State/Country"
                        : "State"}
                    </span>
                    <input
                      type="text"
                      name="state"
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      onBlur={formik.handleBlur}
                    />
                    <span className={styles.error}>
                      {formik.errors.state && formik.touched.state && formik.errors.state}
                    </span>
                  </Col>
                </Row>
              )}
              <Row className="w-100 py-2">
                <Col md={6}>
                  <span>First Name</span>
                  <input
                    type="text"
                    name="coachFN"
                    onChange={formik.handleChange}
                    value={formik.values.coachFN}
                    onBlur={formik.handleBlur}
                  />
                  <span className={styles.error}>
                    {formik.errors.coachFN && formik.touched.coachFN && formik.errors.coachFN}
                  </span>
                </Col>
                <Col md={6}>
                  <span>Last Name</span>
                  <input
                    type="text"
                    name="coachLN"
                    onChange={formik.handleChange}
                    value={formik.values.coachLN}
                    onBlur={formik.handleBlur}
                  />
                  <span className={styles.error}>
                    {formik.errors.coachLN && formik.touched.coachLN && formik.errors.coachLN}
                  </span>
                </Col>
              </Row>
              <Row className="w-100 py-2">
                <Col md={6}>
                  <span>Phone</span>
                  <input
                    type="tel"
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    onBlur={formik.handleBlur}
                  />
                  <span className={styles.error}>
                    {formik.errors.phone && formik.touched.phone && formik.errors.phone}
                  </span>
                </Col>
                <Col md={6}>
                  <span>Email</span>
                  <input
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                  />
                  <span className={styles.error}>
                    {formik.errors.email && formik.touched.email && formik.errors.email}
                  </span>
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.btns}>
            <button type="button" onClick={close}>Cancel</button>
            <button type="submit">Submit Request</button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
