import "react-big-calendar/lib/css/react-big-calendar.css";
import styles from "./Calendar.module.scss";
import Header from "../header/header";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useMemo, useState } from "react";
import EditModal from "./editModal";
import { eventsList } from "./dummyData";
import "./custom.css";

const localizer = momentLocalizer(moment);

export default function CalendarHome() {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState({});

  function MyEvent({ event }) {
    return (
      <>
        <span
          className={`${styles.eventBadge} ${
            event.type === "error"
              ? styles.error
              : event.type === "warning"
              ? styles.warning
              : styles.success
          }`}
        ></span>
        <span>{event.title}</span>
      </>
    );
  }
  const components = useMemo(() => ({
    event: MyEvent,
  }));
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.calendarContainer}>
        <div className={styles.title}>
          <h4>Calendar</h4>
        </div>
        <hr />
        <div className={styles.calendar}>
          <Calendar
            localizer={localizer}
            events={eventsList}
            startAccessor="start"
            endAccessor="end"
            views={["month", "week"]}
            components={components}
            onSelectEvent={(event) => {
              setOpenEditModal(true);
              setSelectedEdit(event);
            }}
          />
        </div>
      </div>
      {openEditModal && (
        <EditModal setOpen={setOpenEditModal} item={selectedEdit} />
      )}
    </div>
  );
}
