import React, { useMemo } from "react";

import { useDropzone } from "react-dropzone";

//icons
import { MdCloudUpload, MdVideocam } from "react-icons/md";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  marginTop: "15px",
  width: "100%",
  height: "100%",
  justifyContent: "center",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export function Dropzone(props) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone();

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {props.video ? (
          <MdVideocam style={{ fontSize: "24px" }} />
        ) : (
          <MdCloudUpload style={{ fontSize: "24px" }} />
        )}
        <p>Upload file smaller than 3MB</p>
      </div>
      {acceptedFiles.length > 0 && (
        <aside>
          <h6 className="mt-2">Files</h6>
          <ul>{files}</ul>
        </aside>
      )}{" "}
    </>
  );
}
