import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function DeleteModal({ show, handleClose, handleConfirm, label, variant, title}) {
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title === undefined?"Confirmation Delete":title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{label === undefined?"Are you sure you want to delete selected item, this action cannot be undone.":label}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={variant===undefined?"danger":variant}
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
