import Layout from "../../../components/layout/layout.js";


import ScoutingOverviewFeed from "../../../components/componentsPage/scoutingOverviewFeed/index.js";

function ScoutingOverviewPage({ component, ...rest }) {
  return <Layout Component={ScoutingOverviewFeed} {...rest} controlsDisabled={true}   />;
}

export default ScoutingOverviewPage;
