import React, { useEffect } from "react";
import moment from "moment";
import { Row, Col, Modal } from "react-bootstrap";
import styles from "./preview.module.scss";

export function ViewReportModal({ show, handleClose, reportData,firstName, lastName  }) {
  useEffect(() => {
    console.log("yes", reportData);
  });

  useEffect(() => {
    if (reportData.reportContent)
      document.getElementById("content").innerHTML = reportData.reportContent;
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div>
        {/* ------------- REPORT FORM BEGINS -------------------*/}
        <Modal.Body style={{ padding: "2rem" }}>
          <div className={styles.container} style={{ marginTop: "0px" }}>
            <div className={styles.title}>
              <div>
                <h4>{`${firstName} ${lastName} Report`}</h4>
              </div>
            </div>

            <Row>
              <Col md={12}>
                
                  <p></p>
                  <p><b>Type: </b>{reportData.type}</p>
                  <p><b>Date: </b>{moment(reportData.reportDate).format('L')}</p>
                  <p><b>Title: </b>{reportData.reportPreview} </p>
                  {reportData.location && <p><b>Location: </b>{reportData.location} </p>}
                
              </Col>
              
              {Array.isArray(reportData.typeSpecificFields.roles) && reportData.typeSpecificFields.roles.some(role => role.trim().length > 0) && (
              <Col md={12}>
                <p><b>Roles: </b>{reportData.typeSpecificFields.roles.join(", ")}</p>
              </Col>
            )}


            {(reportData.typeSpecificFields.projectionRange && 
              (reportData.typeSpecificFields.projectionRange.low || reportData.typeSpecificFields.projectionRange.high)) && (
              <Col md={12}>
                <p>
                  <b>Projection Range: </b>{' '}
                  {`${reportData.typeSpecificFields.projectionRange.low !== reportData.typeSpecificFields.projectionRange.high ?
                    `${reportData.typeSpecificFields.projectionRange.low || ""} to ` : ""}
                    ${reportData.typeSpecificFields.projectionRange.high || ""}`}
                  {reportData.typeSpecificFields.projectionRange.confidence &&
                    ` (confidence level: ${reportData.typeSpecificFields.projectionRange.confidence})`}
                </p>
              </Col>
            )}


              
              {reportData.reportContent && (
                <Col md={12}>
                  <div id="content"></div>
                </Col>
              )}

              {/*reportData.typeSpecificFields.bottomLine && (
                <Col md={12}>
                  <p><b>Bottom Line: </b>{reportData.typeSpecificFields.bottomLine}</p>
                </Col>
              )*/}

              {(reportData.type === "General" ||
                reportData.type === "Medical" ||
                reportData.type === "Intel") && (
                <>
                  {/* Commented out URL Reference, Source, and Attachment */}
                  {/* {reportData.typeSpecificFields.urlReference && (
                    <p>
                      <b>URL Reference: </b>{" "}
                      {reportData.typeSpecificFields.urlReference}{" "}
                    </p>
                  )}
                  {reportData.typeSpecificFields.source && (
                    <p>
                      <b>Source: </b> {reportData.typeSpecificFields.source}{" "}
                    </p>
                  )}
                  {reportData.attachment && (
                    <>
                      <p>Attachment: </p>
                      <a href={reportData.attachment} download>
                        <img
                          src="/other.png"
                          width="100"
                          height="100"
                          alt="attachment"
                        />
                      </a>
                    </>
                  )} */}
                </>
              )}
            </Row>
          </div>
        </Modal.Body>
        {/* ------------- REPORT ENDS ENDS -------------------*/}
      </div>
    </Modal>
  );
}
