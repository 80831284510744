//
// isAdmin.js
// import IsAdmin from "../../../utils/isAdmin.js";
//

import { useAuth } from "../Context/AuthContext";

const IsAdmin = () => {
  // get user info from context
  const context = useAuth();
  const { userInfo } = context;

  if (
    userInfo &&
    userInfo.memberType === "admin" &&
    userInfo.uid === "bkEbbOJeEfgRMsoVW0AMMDupz0y2"
  ) {
    return true;
  } else {
    return false;
  }
};

export default IsAdmin;
