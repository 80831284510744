import React from "react";
import AdminIndexPage from "./pages/dashboard/admin/index";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import PlayerProfilePageCreate from "./pages/dashboard/admin/player-profile.page";
import ReportPage from "./pages/dashboard/admin/report.page";
import BackgroundOverviewPage from "./pages/dashboard/admin/background-overview.page";
import BackgroundOverviewFeedPage from "./pages/dashboard/admin/background-overview-feed";
//import BackgroundOverviewFeedPage from "./pages/dashboard/admin/background-overview-feed";
//import BackgroundOverviewFeedPage from "./components/componentsPage/overviewFeed/backgroundOverviewFeed";
import ScoutingOverviewFeedPage from "./pages/dashboard/admin/scouting-overview-feed";
import ScoutingOverviewPage from "./pages/dashboard/admin/scouting-overview.page";
import TicketPage from "./pages/dashboard/admin/ticket";
import CalendarPage from "./pages/dashboard/admin/calendar";
import ChatPage from "./pages/dashboard/admin/chat";
import PlayerProfileTable from "./pages/dashboard/admin/player-profile-table";
import LoginPage from "./pages/login";
import ProfileDetail from "./pages/dashboard/admin/profile-detail";
import TicketsPage from "./pages/dashboard/admin/tickets";
import PlayerPreview from "./pages/dashboard/admin/player-preview";
import CalendarHomepage from "./pages/dashboard/admin/calendarHoime";
import ArticleAdd from "./pages/dashboard/admin/article-add";
import ArticlePublished from "./pages/dashboard/admin/article-published";
import SubscriptionMembers from "./pages/dashboard/admin/subscription-members";
import ContactPage from "./pages/dashboard/admin/contact-page";
import SubscriptionPlans from "./pages/dashboard/admin/subscription-plans";
import StatsImportPage from "./pages/dashboard/admin/stats-import";
import StatsAddPage from "./pages/dashboard/admin/stats-add";
import NationalityDropdownPage from "./pages/dashboard/admin/components_nationality";
import RoleDropdownPage from "./pages/dashboard/admin/components_role";
import YearDropdownPage from "./pages/dashboard/admin/components_year";
import YearRangeDropdownPage from "./pages/dashboard/admin/components_year_range";
import SchoolDropdownPage from "./pages/dashboard/admin/components_school";
import ArticleDrafted from "./pages/dashboard/admin/article-drafted";
import MediaLibrary from "./pages/dashboard/admin/media-library";
import CollegeLevelPage from "./pages/dashboard/admin/components_college_level";
import { AuthProvider } from "./Context/AuthContext";
import { useAuth } from "./Context/AuthContext";
import HeightDropdownPage from "./pages/dashboard/admin/components_height";
import WingspanDropdownPage from "./pages/dashboard/admin/components_wingspan";
import WeightDropdownPage from "./pages/dashboard/admin/components_weight";
import aauTeamsDropdownPage from "./pages/dashboard/admin/components_aau_teams";
import PacketsPage from "./pages/dashboard/admin/components_packets";
import ProPacketPage from "./pages/dashboard/admin/packets";
import ResourcesPage from "./pages/dashboard/admin/components_resources";

function App() {
  return (
    <Router>
      <AuthProvider>
        <PrivateRoute />
      </AuthProvider>
    </Router>
  );
}

export default App;

function PrivateRoute() {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return (
      <Switch>
        <Redirect from="/" to="/login" exact />
        <Route path="/login" component={LoginPage} />
      </Switch>
    );
  }
  return (
    <Switch>
      {/* player-profile-pages */}
      <Route
        exact
        path="/player-profile/preview/:id/:compareId?"
        component={PlayerPreview}
      />
      <Route exact path="/player-profile" component={PlayerProfileTable} />

      <Route
        exact
        path="/player-profile/detail/:id"
        component={ProfileDetail}
      />

      <Route exact path="/" component={AdminIndexPage}></Route>

      <Route exact path="/chat" component={ChatPage}></Route>
      <Route exact path="/tickets" component={TicketsPage}></Route>
      <Route exact path="/calendar" component={CalendarHomepage}></Route>
      <Route
        exact
        path="/player-profile/create"
        component={PlayerProfilePageCreate}
      />
      <Route exact path="/article/add" component={ArticleAdd} />
      <Route exact path="/article/published" component={ArticlePublished} />
      <Route exact path="/article/drafted" component={ArticleDrafted} />

      {/* stats */}
      <Route exact path="/stats/import" component={StatsImportPage} />
      <Route exact path="/stats/add" component={StatsAddPage} />

      {/* subscription-pages */}
      <Route
        exact
        path="/subscription/members"
        component={SubscriptionMembers}
      />
      <Route exact path="/subscription/plans" component={SubscriptionPlans} />

      {/* contact page */}
      <Route exact path="/contacts" component={ContactPage} />

      <Route exact path="/report/create" component={ReportPage} />
      <Route
        exact
        path="/background-overview/create"
        component={BackgroundOverviewPage}
      />


      <Route
        exact
        path="/background-overview-feed"
        component={BackgroundOverviewFeedPage}
      />

      <Route
        exact
        path="/scouting-overview-feed"
        component={ScoutingOverviewFeedPage}
      />
      <Route exact path="/ticket/create" component={TicketPage} />
      <Route exact path="/calendar/create" component={CalendarPage} />
      {/* components pages */}
      {/* natinality dropdown */}
      <Route
        exact
        path="/components/nationality"
        component={NationalityDropdownPage}
      />
      {/* role dropdown */}
      <Route exact path="/components/role" component={RoleDropdownPage}/>
      {/* year dropdown */}
      <Route exact path="/components/year" component={YearDropdownPage}/>
      {/* year dropdown */}
      <Route exact path="/components/year-range" component={YearRangeDropdownPage}/>
      {/* Height dropdown */}
      <Route exact path="/components/height" component={HeightDropdownPage}/>
       {/* Wingspan dropdown */}
       <Route exact path="/components/wingspan" component={WingspanDropdownPage}/>
      {/* Weight dropdown */}
      <Route exact path="/components/weight" component={WeightDropdownPage}/>
      {/* AAU Team dropdown */}
      <Route exact path="/components/aauTeams" component={aauTeamsDropdownPage}/>
      {/* school dropdown */}
      <Route exact path="/components/school" component={SchoolDropdownPage}/>
      {/* college level dropdown */}
      <Route exact path="/components/college-level" component={CollegeLevelPage}/>
      {/* Packet Page */}
      <Route exact path="/components/packets" component={PacketsPage}/>
      {/* Packet Page */}
      <Route exact path="/components/PIPackets" component={ProPacketPage}/>
      {/* Media Library  */}
      <Route exact path="/components/media-library" component={MediaLibrary}/>
      {/* Resources Library  */}
      <Route exact path="/components/resources" component={ResourcesPage}/>
    </Switch>
  );
}
