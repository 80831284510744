import React, { useEffect, useState, useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import DeleteModal from '../../shared/DeleteModal'
import { db } from '../../../firebase'
import styles from "../Form.module.scss";

const teamHistoryFields = { year: "", school: "", state: "", coachFirstName: "", coachLastName: "", phone: "", email: "" };

const renderMenuWithGrouping = (results, { newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps }, state) => {
  let index = 0;
  const regions = groupBy(results, 'identifier');
  
  if (Object.keys(regions).length === 0) {
    return <Menu {...menuProps}><MenuItem>{'No match found. Contact admin to add new team.'}</MenuItem></Menu>;
  }
  
  const items = Object.keys(regions)
      .sort()
      .map((region) => (
          <React.Fragment key={region}>
              {index !== 0 && <Menu.Divider />}
              <Menu.Header>{region}</Menu.Header>
              {regions[region].map((i) => {
                  const item = (
                      <MenuItem key={index} option={i} position={index}>
                          {i.name}
                          {(i.identifier === 'NBA' || i.identifier === 'G League') && <div><small>{i.teamName}</small></div>}
                      </MenuItem>
                  );
                  index += 1;
                  return item;
              })}
          </React.Fragment>
      ));

  return <Menu {...menuProps}>{items}</Menu>;
};

function groupBy(array, key) {
  return array.reduce((result, currentValue) => {
      const identifier = currentValue[key];
      if (identifier !== undefined) {
          (result[identifier] = result[identifier] || []).push(currentValue);
      }
      return result;
  }, {});
}

export function IBOTeamHistories({ years, setFieldValue, formikTeamHistory, handleChange, handleBlur }) {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    const getSchoolData = async () => {
      try {
        const res = await db.collection("school-dropdown").get();
        const sortedData = res.docs.map(doc => ({ id: doc.id, ...doc.data() })).sort((a, b) => a.name.localeCompare(b.name));
        setSchools(sortedData);
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
    };
    getSchoolData();
  }, []);

  const AddTeamHistory = (index) => {
    setFieldValue("teamHistory", [
      ...formikTeamHistory.slice(0, index + 1),
      { ...teamHistoryFields },
      ...formikTeamHistory.slice(index + 1),
    ])
  }

  const DeleteTeamHistory = (index) => {
    setFieldValue("teamHistory", formikTeamHistory.filter((teamHistory, ind) => ind !== index));
    setDeleteConfirm(false);
  }

  return (
    <>
      <DeleteModal
        handleClose={() => {
          setDeleteConfirm(false);
        }}
        show={deleteConfirm}
        handleConfirm={() => {
          DeleteTeamHistory(deleteConfirm);
        }}
      />
      {formikTeamHistory.map((teamHistory, index) => (
        <TeamHistory
          key={index}
          setFieldValue={setFieldValue}
          schools={schools}
          years={years}
          setDeleteConfirm={setDeleteConfirm}
          AddTeamHistory={AddTeamHistory}
          teamHistory={teamHistory}
          handleChange={handleChange}
          index={index}
          handleBlur={handleBlur}
        />
      ))}
    </>
  )
}

const TeamHistory = ({ setFieldValue, schools, years, AddTeamHistory, setDeleteConfirm, teamHistory = {}, handleChange, handleBlur, index }) => {
  const handleSchoolSelection = (selected) => {
    if (selected.length > 0) {
      const selectedSchool = selected[0];
      const isExactMatch = schools.some((school) => school.name === selectedSchool.name);
  
      if (!isExactMatch) {
        const updatedTeamHistory = { ...teamHistory, school: "" };
        setFieldValue(`teamHistory[${index}]`, updatedTeamHistory);
      } else {
        const confirmChange = window.confirm("Updating the team will delete previous contact information. Are you sure you want to proceed?");
        if (confirmChange) {
          const updatedTeamHistory = {
            ...teamHistory,
            school: selectedSchool.value,
            state: selectedSchool.state || "",
            coachFirstName: selectedSchool.coachFN || "",
            coachLastName: selectedSchool.coachLN || "",
            phone: selectedSchool.phone || "",
            email: selectedSchool.email || ""
          };
          setFieldValue(`teamHistory[${index}]`, updatedTeamHistory);
        }
      }
    } else {
      const updatedTeamHistory = { ...teamHistory, school: "" };
      setFieldValue(`teamHistory[${index}]`, updatedTeamHistory);
    }
  };
  
  return useMemo(() => (
    <div className={styles.container}>
      <div className={styles.title}>
        <div>
          <h4>Team History</h4>
          <subtitle>
            This section is used to add team history. Please fill as many sections as possible.
          </subtitle>
        </div>
      </div>
      <Row>
        <Col md={4}>
          <div className={styles.field}>
            <p>Year</p>
            <select className={styles.yearDropdown} name={`teamHistory[${index}].year`} value={teamHistory.year} onChange={handleChange} onBlur={handleBlur} >
              <option value="">Select Year</option>
              {years.map((y, ind) => <option key={ind}>{y}</option>)}
            </select>
          </div>
        </Col>
        <Col md={4}>
          <div className={styles.field}>
            <p>Team</p>
            <Typeahead
              id={`team-typeahead-${index}`}
              labelKey="name"
              options={schools}
              selected={teamHistory?.school ? [{ name: teamHistory.school }] : []}
              onChange={handleSchoolSelection}
              placeholder="Select Team..."
              renderMenu={renderMenuWithGrouping}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className={styles.field}>
            <p>Country</p>
            <input type="text" name={`teamHistory[${index}].state`} value={teamHistory?.state || ''} onChange={handleChange} onBlur={handleBlur} />
          </div>
        </Col>
      </Row>
      <h4 className={styles.subHead}>Contact Information</h4>
      <Row>
        <Col md={3}>
          <div className={styles.field}>
            <p>Coach First Name</p>
            <input type="text" name={`teamHistory[${index}].coachFirstName`} value={teamHistory.coachFirstName} onChange={handleChange} onBlur={handleBlur} />
          </div>
        </Col>
        <Col md={3}>
          <div className={styles.field}>
            <p>Coach Last Name</p>
            <input type="text" name={`teamHistory[${index}].coachLastName`} value={teamHistory.coachLastName} onChange={handleChange} onBlur={handleBlur} />
          </div>
        </Col>
        <Col md={3}>
          <div className={styles.field}>
            <p>Phone</p>
            <input type="tel" name={`teamHistory[${index}].phone`} value={teamHistory.phone} onChange={handleChange} onBlur={handleBlur} />
          </div>
        </Col>
        <Col md={3}>
          <div className={styles.field}>
            <p>Email</p>
            <input type="email" name={`teamHistory[${index}].email`} value={teamHistory.email} onChange={handleChange} onBlur={handleBlur} />
          </div>
        </Col>
      </Row>
      <div className={styles.btns}>
        {index !== 0 && <button className={styles.red} onClick={() => { setDeleteConfirm(index) }}>Delete</button>}
        <button className={styles.green} onClick={() => { AddTeamHistory(index) }}>Add More</button>
      </div>
    </div>
  ), [index, teamHistory, schools, years, handleChange, handleBlur, AddTeamHistory, setDeleteConfirm, handleSchoolSelection]);
}

export default IBOTeamHistories;
