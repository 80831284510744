import Layout from "../../../components/layout/layout.js";

import SchoolDropdown from "../../../components/componentsPage/schoolDropdown/index.js";

function SchoolDropdownPage({ component, ...rest }) {
  return (
    <Layout Component={SchoolDropdown} controlsDisabled={true} {...rest} />
  );
}

export default SchoolDropdownPage;
