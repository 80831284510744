import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { EditReportModal } from './EditReportModal'
import DeleteModal from "../../shared/DeleteModal";
import { CreateReportModal } from './CreateReportModal';

import moment from 'moment';
// firebase
import { db } from "../../../firebase";

// styles
import styles from "../detail.module.scss";

import { Link } from "react-router-dom";

// icons
import { FaSearch } from "react-icons/fa";
import {
    MdModeEdit,
    MdRemoveRedEye,
    MdRestoreFromTrash,
} from "react-icons/md";
import log_activity from "../../activity/logger";

export function PlayerReports({ playerID, playerProfileData, userInfo }) {
    const [playerReports, setPlayerReports] = useState(null);
    const [selectedEditReport, setSelectedEditReport] = useState(false);
    const [selectedDeleteReport, setSelectedDeleteReport] = useState(null);
    // create new report modal
    const [showCreateReportModal, setShowCreateReportModal] = useState(false); 

    const getPlayerReports = async () => {
        try {
            const response = await db.collection("reports").where("playerProfile", "==", playerID).orderBy("reportDate", "desc").get();
            let data = {};
            response.forEach(report => data[report.id] = { ...report.data() })
            setPlayerReports(data);
        } catch (error) {
            console.log(error, "error");
        }
    }
    

    const handleDelete = (id) => {
        db.collection("reports")
            .doc(id)
            .delete()
            .then(() => {
                toast.error("Report Item Deleted Successfully", {
                    theme: "colored",
                });
                getPlayerReports();
                log_activity(`${playerProfileData.FirstName} ${playerProfileData.LastName}`, "Report Was Deleted")

            })
            .catch((error) => {
                console.log("Error deleting report:", error);
                toast.error("Error Deleting Report Item", {
                    theme: "colored",
                });
            });
    };

    useEffect(() => {
        getPlayerReports();
    }, [])
    
    
    return (
        <>
            <DeleteModal
                handleClose={() => setSelectedDeleteReport(false)}
                show={selectedDeleteReport}
                handleConfirm={() => handleDelete(selectedDeleteReport)}
            />

            {selectedEditReport && (
                <EditReportModal
                    show={selectedEditReport}
                    getPlayerReports={getPlayerReports}
                    reportID={selectedEditReport}
                    handleClose={() => setSelectedEditReport(false)}
                    reportData={{
                        ...playerReports[selectedEditReport],
                        playerProfile: {
                            fullName: `${playerProfileData.FirstName} ${playerProfileData.LastName}`,
                            id: playerID,
                            dob: playerProfileData.DOB,
                        },
                    }}
                />
            )}
           

            {showCreateReportModal && (
                <CreateReportModal
                    show={showCreateReportModal}
                    getPlayerReports={getPlayerReports}
                    handleClose={() => setShowCreateReportModal(false)}
                    
                    //toggleModal={() => setShowCreateReportModal(false)}
                />
            )}



                {playerReports && Object.keys(playerReports).length > 0 ? (
                <div className={styles.container}>
                <div className={styles.feed}>
                    <div className={styles.header}>
                        <div className={styles.headerLeft}>
                            <h2>Reports</h2>
                        </div>
                        <div className={styles.headerRight}>
                            <button className={`btn btn-dark ${styles.addReportButton}`} onClick={() => setShowCreateReportModal(true)}>
                                Add New Report
                            </button>
                        </div>
                    </div>
                    
                    <div className={styles.tableContainer}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Report Date</th>
                                    <th>Report Type</th>
                                    <th>Preview</th>
                                    <th>Author</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(playerReports).map(([reportID, item], index) => (
                                    <tr key={index}>
                                        <td>{moment(item.reportDate).format("MM/DD/YYYY")}</td>
                                        <td>{item.type}</td>
                                        <td>
                                            {item.reportPreview}
                                            {item.location && ` (${item.location})`}
                                        </td>
                                        <td>{item.creator}</td>
                                        <td>
                                            <div className={styles.actionIcon}>
                                                <MdModeEdit
                                                    onClick={() => setSelectedEditReport(reportID)}
                                                    style={{ color: "green" }}
                                                />
                                                <MdRestoreFromTrash
                                                    onClick={() => setSelectedDeleteReport(reportID)}
                                                    style={{ color: "red" }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
                ) : (
                    <div className={styles.singleSection}>
                    <h2>Reports</h2>
                    <div className={styles.reportsNotAvailable}>
                        <Link 
                            to={{
                                pathname: "/report/create",
                                state: { userInfo }
                            }}
                        >
                            Reports not available, please create one using this link
                        </Link>
                    </div>
                    </div>
                )}
            
        </>
    );
}