import Layout from "../../../components/layout/layout.js";

import HeightDropdown from "../../../components/componentsPage/height/index.js";

function HeightDropdownPage({ component, ...rest }) {
  return (
    <Layout Component={HeightDropdown} controlsDisabled={true} {...rest} />
  );
}

export default HeightDropdownPage;
