import { useState } from "react";

import { Row, Col, Button } from "react-bootstrap";

import Sidebar from "../sidebar";
import { TiThMenu } from "react-icons/ti";
import styles from "../../App.module.scss";

function Layout({ Component, controlsDisabled, ...rest }) {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className={styles.container}>
      <button
        className={styles.menuBtn}
        onClick={() => handleToggleSidebar(true)}
      >
        <TiThMenu />
      </button>
      <Sidebar
        handleToggleSidebar={handleToggleSidebar}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        toggled={toggled}
      />
      <div className={styles.content}>
        {!controlsDisabled && (
          <div className={styles.title}>
            {/* Replace with page title or menu path*/}
            <h2>{""}</h2>
          </div>
        )}
        <Component />
        {!controlsDisabled && (
          <div className={styles.buttonContainer}>
            <Row>
              <Col md={4}>
                <Button className={styles.secondary}>Preview</Button>
              </Col>
              <Col md={4}>
                <Button className={styles.secondary}>Save Draft</Button>
              </Col>
              <Col md={4}>
                <Button>Publish</Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
}

export default Layout;
